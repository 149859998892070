<template>
    <div>
        <div class="container">
            <div @click="handleback()" class="btns mt-4 ms-2 d-flex justify-content-between">
                <div class="d-flex">
                    <img src="@/assets/img/back.svg" />
                    <h6 class="withdraw ms-2 pt-2">ဆုမဲ ပေါက်စဥ်တိုက်ရန်</h6>
                    <!-- <img src="/"/> -->
                </div>
            </div>


            <div class="row mt-3">
                <div class="col-12">
                    <button  @click="showModaltoggle()" class="btn btn-primary w-100 text-start p-2 py-3 ps-3 d-flex" style="border-radius: 3px">
                        <h6 class="mb-0" style="font-size:14px">
                            လက်မှတ်စစ်ဆေးရန်
                        </h6>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                            class="bi bi-search " style="margin-left:170px" viewBox="0 0 16 16">
                            <path
                                d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                        </svg>
                    </button>
                </div>
                <div class="col-12 mt-2">
                    <select @change="handleChangeItem()" v-model="selectedItem"  class="form-control p-2 py-3 ps-3" style="border-radius: 3px;font-size:14px">
                        <option>နှစ်အလိုက်ကြည့်ရန်</option>
                        <option  :value="index" v-for="(item,index) in data" :key="index">{{index }}</option>
                    </select>
                </div>
                <div class="col-12 mt-2">
                    <select  @change="handlechangeMonth()"  v-model="selectMonth"  class="form-control p-2 py-3 ps-3" style="border-radius: 3px;font-size:14px">
                        <option>လအလိုက်ကြည့်ရန်</option>
                         <option  :value="index" v-for="(item, index) in monthItem" :key="index">{{ index }}</option>
                    </select>
                </div>
            </div>

            <div class="row">
                <div  v-for="(item, index) in number" :key="index" class="col-12 mt-5">
                    <div class="check">
                        <h6 class="text-white fs-13 p-2">{{ item.date }}</h6>
                    </div>
                   <div >
                     <div class="col-12 mt-3">
                            <h6 class="text-center fw-light fs-13">{{ item.data['1-st Prize'][0].thaidprice.price}}  {{ item.data['1-st Prize'][0].thaidprice.amount }} ฿</h6>
                            <div class="d-flex justify-content-center">
                                <h6 class="text-center w-50 fs-1 fw-bold p-2"
                                    style="background-color:#faee1c;letter-spacing:5px">{{ item.data['1-st Prize'][0].winning_number }}</h6>
                            </div>
                            <div class="line-ve"></div>
                            <div class="line-ho"></div>
                            <div class="d-flex">
                                <div class="col-6">
                                    <div>
                                        <div class="line-ve1"></div>
                                        <h6 class="fw-light" style="font-size:12px">Up/Down Prize {{ item.data['Side Prizes'][0].thaidprice.amount }}฿</h6>
                                        <div style="margin-left:-9px;">
                                          <span class="ball" v-for="digit in splitNumber(item.data['Side Prizes'][0].winning_number)" :key="digit">{{ digit }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div>
                                        <div class="line-ve2"></div>
                                        <h6 class="fw-light" style="font-size:12px;margin-left:27px">Up/Down Prize {{ item.data['Side Prizes'][0].thaidprice.amount }}฿</h6>
                                        <div class="ms-3">
                                             <span class="ball" v-for="digit in splitNumber(item.data['Side Prizes'][1].winning_number)" :key="digit">{{ digit }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-12">
                                <div class="accordion" id="accordionExample">

                                   <div class="accordion-item border-0 rounded-0">
                                    <h2 class="accordion-header">
                                        <button class="accordion-button" style="background:#fccde2" type="button"
                                            :data-bs-toggle="getToggle(item.data['First 3 Digits'][0].winning_number)"
                                            :data-bs-target="getTarget(item.data['First 3 Digits'][0].winning_number)"
                                            :aria-expanded="isActive(item.data['First 3 Digits'][0].winning_number) ? 'true' : 'false'"
                                            aria-controls="collapse">
                                            <h6 class="mb-0 text-center" style="font-size:14px">{{ item.data['First 3 Digits'][0].thaidprice.price }} {{ item.data['First 3 Digits'][0].thaidprice.amount }}฿ Each
                                            </h6>
                                        </button>
                                    </h2>
                                    <div :id="'sithu' + item.data['First 3 Digits'][0].winning_number" class="accordion-collapse collapse"
                                       :class="{ show: isActive(item.data['First 3 Digits'][0].winning_number) }"
                                        data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <div class="row">
                                                <div v-for="(n, i) in item.data['First 3 Digits']" :key="i" class="col-12 mt-4">
                                                    <span class="ball2" v-for="digit in splitNumber(n.winning_number)" :key="digit">{{ digit }}</span>
                                                    <span class="ballnocolor">X</span>
                                                    <span class="ballnocolor">X</span>
                                                    <span class="ballnocolor">X</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                    <div class="accordion-item border-0 rounded-0">
                                        <h2 class="accordion-header">
                                            <button class="accordion-button collapsed" style="background:#a6ff839b" type="button"
                                            :data-bs-toggle="getToggle(item.data['Last 3 Digits'][0].winning_number)"
                                            :data-bs-target="getTarget(item.data['Last 3 Digits'][0].winning_number)"
                                            :aria-expanded="isActive(item.data['Last 3 Digits'][0].winning_number) ? 'true' : 'false'"
                                            aria-controls="collapse">
                                            <h6 class="mb-0 text-center" style="font-size:14px">{{ item.data['Last 3 Digits'][0].thaidprice.price }} {{ item.data['Last 3 Digits'][0].thaidprice.amount }}฿ Each</h6>
                                            </button>
                                        </h2>
                                       <div :id="'sithu' + item.data['Last 3 Digits'][0].winning_number" class="accordion-collapse collapse"
                                           :class="{ show: isActive(item.data['Last 3 Digits'][0].winning_number) }">
                                            <div class="accordion-body">
                                              <div class="row">
                                                 <div v-for="(n, i) in item.data['Last 3 Digits']"  :key="i" class="col-12 mt-4">
                                                     <span class="ballnocolor">X</span>
                                                     <span class="ballnocolor">X</span>
                                                     <span class="ballnocolor">X</span>
                                                     <span class="ball2" style="background:#a6ff839b" v-for="digit in splitNumber(n.winning_number)" :key="digit">{{ digit }}</span>                                                        
                                                  </div>
                                              </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item border-0 rounded-0">
                                        <h2 class="accordion-header">
                                            <button class="accordion-button collapsed" style="background:#faee1c" type="button"
                                            :data-bs-toggle="getToggle(item.data['Last 2 Digits'][0].winning_number)"
                                            :data-bs-target="getTarget(item.data['Last 2 Digits'][0].winning_number)"
                                            :aria-expanded="isActive(item.data['Last 2 Digits'][0].winning_number) ? 'true' : 'false'"
                                            aria-controls="collapse">
                                           <h6 class="mb-0 text-center" style="font-size:14px">{{ item.data['Last 2 Digits'][0].thaidprice.price }} {{ item.data['Last 2 Digits'][0].thaidprice.amount }}฿ Each</h6>
                                            </button>
                                        </h2>
                                       <div :id="'sithu' + item.data['Last 2 Digits'][0].winning_number" class="accordion-collapse collapse"
                                         :class="{ show: isActive(item.data['Last 2 Digits'][0].winning_number) }">
                                            <div class="accordion-body">
                                              <div class="row">
                                                    <div v-for="(n, i) in item.data['Last 2 Digits']" :key="i" class="col-12 mt-4">
                                                            <span class="ballnocolor">X</span>
                                                            <span class="ballnocolor">X</span>
                                                            <span class="ballnocolor">X</span>
                                                            <span class="ballnocolor">X</span>
                                                            <span class="ball2" style="background:#faee1c" v-for="digit in splitNumber(n.winning_number)" :key="digit">{{ digit }}</span>
                                                     </div>
                                                 </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item border-0 rounded-0">
                                        <h2 class="accordion-header">
                                            <button class="accordion-button collapsed" style="background:#c5e3f6" type="button"
                                                :data-bs-toggle="getToggle(item.data['2-nd Prizes'][0].id)"
                                                :data-bs-target="getTarget(item.data['2-nd Prizes'][0].id)"
                                                :aria-expanded="isActive(item.data['2-nd Prizes'][0].id) ? 'true' : 'false'"
                                                aria-controls="collapse">
                                                <h6 class="mb-0 text-center" style="font-size:14px">{{ item.data['2-nd Prizes'][0].thaidprice.price }} {{ item.data['2-nd Prizes'][0].thaidprice.amount }}฿ Each</h6>
                                            </button>
                                        </h2>
                                       <div :id="'sithu' + item.data['2-nd Prizes'][0].id" class="accordion-collapse collapse"
                                         :class="{ show: isActive(item.data['2-nd Prizes'][0].id) }">
                                            <div class="accordion-body">
                                              <div class="row">
                                                <div v-for="(n, i) in item.data['2-nd Prizes']" :key="i" class="col-12 mt-4">
                                                    <span class="ball2" style="background:#c5e3f6" v-for="digit in splitNumber(n.winning_number)" :key="digit">{{ digit }}</span>
                                                 </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item border-0 rounded-0">
                                        <h2 class="accordion-header">
                                            <button class="accordion-button collapsed" style="background:#ffcef3" type="button"
                                                :data-bs-toggle="getToggle(item.data['3-rd Prizes'][0].id)"
                                                :data-bs-target="getTarget(item.data['3-rd Prizes'][0].id)"
                                                :aria-expanded="isActive(item.data['3-rd Prizes'][0].id) ? 'true' : 'false'"
                                                aria-controls="collapse">
                                                <h6 class="mb-0 text-center" style="font-size:14px">{{ item.data['3-rd Prizes'][0].thaidprice.price }} {{ item.data['3-rd Prizes'][0].thaidprice.amount }}฿ Each</h6>
                                            </button>
                                        </h2>
                                         <div :id="'sithu' + item.data['3-rd Prizes'][0].id" class="accordion-collapse collapse"
                                            :class="{ show: isActive(item.data['3-rd Prizes'][0].id) }">
                                            <div class="accordion-body">
                                               <div class="row">
                                                    <div v-for="(n, i) in item.data['3-rd Prizes']" :key="i" class="col-12 mt-4">
                                                        <span class="ball2" style="background:#ffcef3" v-for="digit in splitNumber(n.winning_number)" :key="digit">{{ digit }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item border-0 rounded-0">
                                        <h2 class="accordion-header">
                                            <button class="accordion-button collapsed" style="background:#82e85a57" type="button"
                                                :data-bs-toggle="getToggle(item.data['4-th Prizes'][0].id)"
                                                :data-bs-target="getTarget(item.data['4-th Prizes'][0].id)"
                                                :aria-expanded="isActive(item.data['4-th Prizes'][0].id) ? 'true' : 'false'"
                                                aria-controls="collapse">
                                                <h6 class="mb-0 text-center" style="font-size:14px">{{ item.data['4-th Prizes'][0].thaidprice.price }} {{ item.data['4-th Prizes'][0].thaidprice.amount }}฿ Each</h6>
                                            </button>
                                        </h2>
                                       <div :id="'sithu' + item.data['4-th Prizes'][0].id" class="accordion-collapse collapse"
                                         :class="{ show: isActive(item.data['4-th Prizes'][0].id) }">
                                            <div class="accordion-body">
                                              <div class="row">
                                                 <div v-for="(n, i) in item.data['4-th Prizes']" :key="i" class="col-12 mt-4">
                                                     <span class="ball2" style="background:#82e85a57" v-for="digit in splitNumber(n.winning_number)" :key="digit">{{ digit }}</span>
                                                 </div>
                                             </div>
                                            </div>
                                        </div>
                                    </div>
                                     <div class="accordion-item border-0 rounded-0">
                                            <h2 class="accordion-header">
                                                <button class="accordion-button collapsed" style="background:#fff591" type="button"
                                                     :data-bs-toggle="getToggle(item.data['5-th Prizes'][0].id)"
                                                    :data-bs-target="getTarget(item.data['5-th Prizes'][0].id)"
                                                    :aria-expanded="isActive(item.data['5-th Prizes'][0].id) ? 'true' : 'false'"
                                                    aria-controls="collapse">
                                                    <h6 class="mb-0 text-center" style="font-size:14px">{{ item.data['5-th Prizes'][0].thaidprice.price }} {{ item.data['5-th Prizes'][0].thaidprice.amount }}฿ Each</h6>
                                                </button>
                                            </h2>
                                            <div :id="'sithu' + item.data['5-th Prizes'][0].id" class="accordion-collapse collapse"
                                             :class="{ show: isActive(item.data['5-th Prizes'][0].id) }">
                                                <div class="accordion-body">
                                                     <div class="row">
                                                        <div v-for="(n, i) in item.data['5-th Prizes']" :key="i" class="col-12 mt-4">
                                                           <span class="ball2" style="background:#fff591" v-for="digit in splitNumber(n.winning_number)" :key="digit">{{ digit }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                   </div>
                </div>
            </div>
           <div class="model-box-thaid" v-if="showModal">
                <div class="d-flex justify-content-center">
                    <h6 class="text-center w-100 ms-4 py-5" >လက်မှတ်စစ်ဆေးရန်</h6>
                    <svg @click="showModaltoggle()" xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-x" style="margin-right:20px;margin-top:40px" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                    </svg>
                </div>
                <div class="row justify-content-center p-2 pb-5">
                    <div class="col-7">
                        <input v-model="userNumber" type="number" class="form-control w-100 rounded-1 pl-text" placeholder="လက်မှတ်နံပတ် ထည့်သွင်းပါ" />
                    </div>
                    <div class="col-5">
                          <button @click="handleWinningNumber()" class="btn btn-primary rounded-1 w-100 ">စစ်ဆေးရန်</button>
                     </div>
                </div>
           </div>
        </div>
    </div>
</template>
<script>
import Swal from 'sweetalert2'
// import VModal from 'vue-js-modal/dist/index.nocss.js';
import 'vue-js-modal/dist/styles.css';
import { ThaidWinningNumberList, ThaiDSectionList, ThaidWinningNumberListwithId, CheckWinningNumber, getYear, sectionFilter, monthFilter, monthFilterResult } from '../services/Auth-Services'
export default {
    name: "depositComponent",
    data() {
        return {
            isStatus: false,
            number: null,
            section: null,
            ThaidSection: null,
            userNumber: null,
            activeIndex: 0,
            activeNumber: null,
            data: [],
            selectedItem: null,
            selectMonth:null,
            monthItem: null,
            showModal: false,
            thaiDSection:null,
        }
    },
    created() {
        this.handlegetNumber();
        this.handlegetListThaidSection();
        this.handlegetYear();
        this.thaidSection()
    },
    methods: {
         thaidSection() {
            ThaiDSectionList().then((response) => {
                this.thaiDSection = response.data.id;
            }).then((error) => {
                console.log(error)
            })
        },
        handlegetYear() {
            getYear().then((response) => {
                this.data = response.data
            }).catch((error) => {
                console.log(error);
            })
        },
        showModaltoggle() {
            this.showModal = !this.showModal
        },
        handlechangeMonth() {
            const yearData = {
                'year': this.selectedItem,
                'month': this.selectMonth
            };
            monthFilterResult(yearData).then((response) => {
            this.number = response.data;
            }).catch((error) => {
                console.log(error);
           })
        },
        handlegetMonth() {
             const yearData = {
                'year': this.selectedItem
            };
            monthFilter(yearData).then((response) => {
                this.monthItem = response.data;
            }).catch((error) => {
            console.log(error)
          })  
        },
        handleback() {
            history.back();
        },
        getToggle() {
            return "collapse"; 
        },
        getTarget(id) {
            return "#sithu" + id;
        },
         isActive(winningNumber) {
            return this.activeNumber === winningNumber;
        },
        handleChangeItem() {
            const yearData = {
                'year': this.selectedItem
            };
            sectionFilter(yearData).then((response) => {
                this.handlegetMonth();
                this.number = response.data;
            }).catch((error) => {
                console.log(error);
            })
        },
        handlegetNumber() {
            ThaidWinningNumberList().then((response) => {
                this.number = response.data;
            }).catch((error) => {
                console.log(error)
            })
        },
        handlegetListThaidSection() {
            ThaiDSectionList().then((response) => {
                this.ThaidSection = response.data.pop();
            }).catch((error) => {
                console.log(error);
            })
        },
        handleSectionChange() {
            ThaidWinningNumberListwithId(this.section).then((response) => {
                this.number = response.data;
                console.log(this.number);
            }).catch((error) => {
                console.log(error)
            })
        },
        handleChange() {
            this.isStatus = !this.isStatus
        },
        splitNumber(number) {
            const numStr = number.toString();
            return numStr.split('');
        },
        handleWinningNumber() {
            CheckWinningNumber(this.thaiDSection, this.userNumber)
                .then((response) => {
                    console.log(response.data.length)
                    if (response.data.length === 0) {
                        Swal.fire({
                            html: `
                            <div>
                                <img class="w-100" src="https://i.scdn.co/image/ab6761610000e5eb006ff3c0136a71bfb9928d34"/>
                            </div>
                            <div>
                            <h6 class="won1 mt-3"> Good luck next time!</h6>
                            </div>
                        `,
                            width: "89%",
                            customClass: {
                                icon: 'my-icon-class',
                                popup: 'my-popup-class',
                                confirmButton: 'my-button-class2',
                            },
                            confirmButtonText: 'Okay',
                        });
                    } else {
                        Swal.fire({
                            html: `
                            <div>
                                <img class="w-100" src="https://i.scdn.co/image/ab6761610000e5eb006ff3c0136a71bfb9928d34"/>
                            </div>
                            <div class="congrat2">
                            You Are Lucky
                            </div>
                            <div>
                            <h6 class="won mt-3"> Won ${response.data[0].thaidprice.price} </br>
                            Baht ${response.data[0].thaidprice.amount}</h6>
                            </div>
                        `,
                            width: "89%",
                            customClass: {
                                icon: 'my-icon-class',
                                popup: 'my-popup-class',
                                confirmButton: 'my-button-class2',
                            },
                            confirmButtonText: 'Okay',
                        });
                    }

                }).catch((error) => {
                    console.log(error);
                })

        },
    }
}
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Secular+One&display=swap');
.pl-text::placeholder{
    font-size: 12px !important;
}
.thai-header {
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
}
.model-box-thaid{
    background-color:white;
    position: absolute;
    width: 92%;
    display: block;
    margin: auto;
    top: 30%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    border-radius: 5px;

}
.thai-box {
    width: 50%;
}

.thai-text {
    color: white;
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
    padding-top: 5px;
}

.thai-search {
    border: 1px solid #8530FF;
    padding: 6px;
    width: 80%;
    border-radius: 25px;
    display: block;
    margin: auto;
}

.thai-search::placeholder {
    padding: 10px;
    font-family: 'Roboto';
    font-size: 13px;
    font-weight: 400;
    color: black;
}

.first-text {
    font-family: 'Roboto';
    font-weight: 400;
}

.first-header {
    background-image: linear-gradient(to bottom, #FE0180 0%, #EB81C1 100%);
    border-radius: 10px 10px 0px 0px;
}

.first-number-box {
    border-radius: 0px 0px 10px 10px;
    border-top: 0px !important;
    border: 1px solid #2AABEE;
}

.first-text-number {
    font-family: 'Secular One', sans-serif;
    font-weight: 500;
    font-size: 23px;
    color: #FD4895;
    padding-top: 3px;
}

.first-text-num {
    font-size: 12px;
    font-weight: 400;
    font-family: 'Secular One', sans-serif;
    color: #7210FF;
}

.congrat2 {
    font-family: 'Secular One', sans-serif;
    font-size: 13px;
    font-style: bold;
    color: red;
    font-weight: 400;
}

.won {
    font-family: 'Secular One', sans-serif;
    font-size: 25px;
    font-weight: 400;
    text-align: center;
}

.won1 {
    font-family: 'Secular One', sans-serif;
    font-size: 21px;
    font-weight: 400;
    text-align: center;
    color: #FF5722;
}

.swal2-confirm.my-button-class2 {
    font-family: 'Secular One', sans-serif;
    font-size: 17px;
    font-weight: bold;
    color: white;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #7210FF;
    width: 130px;
    display: block;
    margin: auto;
    border-radius: 25px;
    margin-bottom: 20px;
}

.check {
    background: #0D6DFD;
    width: 110px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}

.line-ve {
    height: 25px;
    width: 1px;
    background: #00000032;
    display: block;
    margin: auto;
}

.line-ho {
    width: '100%';
    height: 1px;
    background: #00000032;
}

.line-ve1 {
    height: 25px;
    width: 1px;
    background: #00000032;
}

.line-ve2 {
    height: 25px;
    width: 1px;
    background: #00000032;
    display: block;
    margin-left: auto;
}

.ball {
    padding: 1px 8px;
    border-radius: 50%;
    font-size: 13px;
    font-weight: 'bold';
    background: #faee1c;
    margin-left: 5px;
}

.accordion-item {
    margin-top: 0px;
    border: 1px solid #ccc;
    /* You can customize the border color and style */
    border-radius: 5px;
    /* Optional: If you want rounded corners */
    padding: 5px;
}

.ball2 {
    padding: 10px 18px !important;
    background: #fccde2;
    border-radius: 50%;
    margin-left: -13px;
}

.ballnocolor {
    padding: 10px 18px !important;
    background: white;
    border-radius: 50%;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    font-weight: 600;
    margin-right: 5px !important;
}

.ball2:not(:first-child) {
    padding: 10px 18px !important;
    background: #fccde2;
    border-radius: 50%;
    margin-left: 10px;
}</style>
