<template>
    <div class="edit-profile-page">
        <div class="py-3 px-3 mb-3">
            <div @click="handleback()" class="d-flex gap-2 align-items-center">
                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M11.7812 20.9084L4.63125 13.7584C4.52291 13.65 4.446 13.5327 4.4005 13.4063C4.355 13.2799 4.33261 13.1445 4.33333 13C4.33333 12.8556 4.35608 12.7202 4.40158 12.5938C4.44708 12.4674 4.52364 12.35 4.63125 12.2417L11.7812 5.09169C11.9799 4.89308 12.2283 4.78944 12.5266 4.78078C12.8249 4.77211 13.082 4.87575 13.2979 5.09169C13.5146 5.2903 13.6276 5.53875 13.637 5.83702C13.6464 6.1353 13.5424 6.39241 13.325 6.60836L8.01666 11.9167H20.1229C20.4299 11.9167 20.6873 12.0207 20.8953 12.2287C21.1033 12.4367 21.207 12.6938 21.2062 13C21.2062 13.307 21.1026 13.5644 20.8953 13.7724C20.6881 13.9804 20.4306 14.0841 20.1229 14.0834H8.01666L13.325 19.3917C13.5236 19.5903 13.6276 19.8431 13.637 20.15C13.6464 20.457 13.5424 20.7097 13.325 20.9084C13.1264 21.125 12.8736 21.2334 12.5667 21.2334C12.2597 21.2334 11.9979 21.125 11.7812 20.9084Z"
                        fill="black" />
                </svg>
                <h6 class="fs-18 fw-normal mb-0 change-password">Change Password Pin</h6>
            </div>
        </div>
        <form @submit="handleRegister()" class="d-flex flex-column h-100 px-3 mt-5">
            <template v-if="step == 1">
                <div>
                    <div class="mb-5">
                        <h6 class=" change-password">Old Password</h6>
                        <div class="d-flex gap-2 align-items-center px-2 py-2"
                            style="border-radius: 10px;border: 1px solid #5856D6;background-color: #ECECF2;height:50px">
                            <svg width="30" height="30" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M16.875 10.9125L13.8 7.8C14.4878 6.05317 15.6854 4.55382 17.2371 3.49706C18.7888 2.44031 20.6226 1.87511 22.5 1.875C27.675 1.875 31.875 6.075 31.875 11.25V15H33.75C34.7445 15 35.6984 15.3951 36.4016 16.0983C37.1049 16.8016 37.5 17.7554 37.5 18.75V31.5L33.75 27.75V18.75H24.75L21 15H28.125V11.25C28.125 8.1375 25.6125 5.625 22.5 5.625C19.5187 5.625 17.0812 7.96875 16.875 10.9125ZM41.4562 40.2375L39.075 42.6187L36.4875 40.0312C35.8125 40.7812 34.8375 41.25 33.75 41.25H11.25C10.2554 41.25 9.3016 40.8549 8.59834 40.1516C7.89508 39.4484 7.49999 38.4946 7.49999 37.5V18.75C7.49999 16.6687 9.16874 15 11.25 15H11.4562L2.08124 5.625L4.48124 3.24375L41.4562 40.2375ZM33.75 37.2937L25.9687 29.5125C25.425 30.9375 24.075 31.875 22.5 31.875C21.5054 31.875 20.5516 31.4799 19.8483 30.7766C19.1451 30.0734 18.75 29.1196 18.75 28.125C18.75 26.5312 19.6875 25.2 21.1125 24.6562L15.2062 18.75H11.25V37.5H33.75V37.2937Z"
                                    fill="#F24E1E" />
                            </svg>
                            <input type="text" name="old_password" class="form-text-input" value="------"
                                style="border: 1px solid transparent">
                        </div>
                    </div>
                    <div class="mb-4">
                        <h6 class=" change-password">New Password</h6>
                        <div class="d-flex gap-2 align-items-center px-2 py-2"
                            style="border-radius: 10px;border: 1px solid #5856D6;background-color: #ECECF2;height:50px">
                             <svg width="30" height="30" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M28.125 18.75V9.375H30V7.5C30 6.46875 30.3675 5.58563 31.1025 4.85063C31.8375 4.11563 32.72 3.74875 33.75 3.75C34.7813 3.75 35.6644 4.1175 36.3994 4.8525C37.1344 5.5875 37.5012 6.47 37.5 7.5V9.375H39.375V18.75H28.125ZM31.875 9.375H35.625V7.5C35.625 6.96875 35.445 6.52313 35.085 6.16313C34.725 5.80313 34.28 5.62375 33.75 5.625C33.2188 5.625 32.7731 5.805 32.4131 6.165C32.0531 6.525 31.8738 6.97 31.875 7.5V9.375ZM37.4063 39.375C33.375 39.375 29.445 38.4844 25.6163 36.7031C21.7875 34.9219 18.3969 32.5469 15.4444 29.5781C12.4919 26.6094 10.1169 23.2106 8.31938 19.3819C6.52188 15.5531 5.62375 11.6238 5.625 7.59375V6.60938C5.625 6.26563 5.65625 5.9375 5.71875 5.625H16.6875L18.4219 15.0469L13.0781 20.4375C14.3906 22.6875 16.0394 24.7969 18.0244 26.7656C20.0094 28.7344 22.1888 30.4375 24.5625 31.875L30 26.4375L39.375 28.3125V39.2813C39.0625 39.3125 38.7344 39.3363 38.3906 39.3525C38.0469 39.3688 37.7188 39.3763 37.4063 39.375Z"
                                        fill="#F24E1E" />
                                </svg>

                            <input type="text" name="new_password" class="form-text-input" value="------"
                                style="border: 1px solid transparent">
                        </div>
                    </div>
                    <div class="mb-3">
                        <h6 class=" change-password">Confirm Password</h6>
                        <div class="d-flex gap-2 align-items-center px-2 py-2"
                            style="border-radius: 10px;border: 1px solid #5856D6;background-color: #ECECF2;height:50px">
                            <svg width="30" height="30" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M28.125 18.75V9.375H30V7.5C30 6.46875 30.3675 5.58563 31.1025 4.85063C31.8375 4.11563 32.72 3.74875 33.75 3.75C34.7813 3.75 35.6644 4.1175 36.3994 4.8525C37.1344 5.5875 37.5012 6.47 37.5 7.5V9.375H39.375V18.75H28.125ZM31.875 9.375H35.625V7.5C35.625 6.96875 35.445 6.52313 35.085 6.16313C34.725 5.80313 34.28 5.62375 33.75 5.625C33.2188 5.625 32.7731 5.805 32.4131 6.165C32.0531 6.525 31.8738 6.97 31.875 7.5V9.375ZM37.4063 39.375C33.375 39.375 29.445 38.4844 25.6163 36.7031C21.7875 34.9219 18.3969 32.5469 15.4444 29.5781C12.4919 26.6094 10.1169 23.2106 8.31938 19.3819C6.52188 15.5531 5.62375 11.6238 5.625 7.59375V6.60938C5.625 6.26563 5.65625 5.9375 5.71875 5.625H16.6875L18.4219 15.0469L13.0781 20.4375C14.3906 22.6875 16.0394 24.7969 18.0244 26.7656C20.0094 28.7344 22.1888 30.4375 24.5625 31.875L30 26.4375L39.375 28.3125V39.2813C39.0625 39.3125 38.7344 39.3363 38.3906 39.3525C38.0469 39.3688 37.7188 39.3763 37.4063 39.375Z"
                                    fill="#F24E1E" />
                            </svg>


                            <input type="text" name="new_password_confirmation" class="form-text-input" value="------"
                                style="border: 1px solid transparent">
                        </div>
                    </div>
                    <div class="d-flex justify-content-center align-items-center py-5">
                        <button class="btn_primary">Confirm </button>
                    </div>
                </div>
            </template>
        </form>
    </div>
</template>
<script>
import userService from '@/services/user.service';
export default {
    data() {
        return {
            step: 1,
            OTPSeconds: 60,
            OTPLabel: "Get OTP",
            previewImage: null,
            user: this.$store.state.auth.user
        };
    },
    methods: {
        handleback() {
            history.back();
        },
        next() {
            this.$router.push({
                name: 'new-pin',
                params: {
                    phone: this.phone,
                    name: this.name
                }
            })
        },
        getOTP() {
            console.log(this.OTPSeconds)
            var otpInterval = setInterval(() => {
                this.OTPSeconds--;

                if (this.OTPSeconds <= 0) {
                    clearInterval(otpInterval)
                    this.OTPSeconds = 60;
                    this.OTPLabel = "Get OTP"
                }

                this.OTPLabel = this.OTPSeconds + "s"
            }, 1000)
        },
        chooseImage() {
            this.$refs.chooseImage.click()
        },
        imageChoosed(e) {
            const image = e.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = e => {
                this.previewImage = e.target.result;
            };
            this.image = e.target.files[0]

        },
        handleRegister() {
            event.preventDefault();
            let data = new FormData();
            data.append('image', this.image);
            data.append('phone', this.phone);
            data.append('name', this.name);
            data.append('password', this.password);
            this.$store.dispatch('loading')
            this.$store.dispatch('auth/register', data).then((data) => {
                this.$store.dispatch('loadingComplete')
                if (data.success) {
                    this.$router.push({ name: 'get-started' })
                }
            }).catch(({ response }) => {
                if (response.status == 422) {
                    this.errors = response.data.errors
                    if (this.errors.phone && this.errors.phone.length > 0) {
                        this.step = 1;
                    }
                }
            })
        },
        updatePassword(){
            userService.updatePasswrord({
                password: this.new_password,
                password_confirmation: this.password_confirmation,
                new_password: this.new_password
            }).then(({ data }) => {
                this.userInfo = data.user;
                this.name = data.user.name;
            });
        }
    },
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Secular+One&display=swap');

.profile-img-container {
    width: 111px;
    height: 111px;
}

.form-text-input {
    display: block;
    width: 100%;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1;
    color: #212529;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    background-color: #ECECF2;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.form-text-input:hover,
.form-text-input:focus {
    outline: none;
}

.btn_primary {
    display: inline-block;
    font-weight: bold;
    color: #fff;
    background-color: #7210FF;
    width: 190px;
    height: 45px;
    border-radius: 25px;
    outline: none;
    border: none;
    box-shadow: none;
    text-decoration: none;
    user-select: none;
    transition: .3s linear;
}

.change-password {
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    font-weight: 500;
}</style>
