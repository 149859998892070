<template>
    <div class="container">
        <div @click="handleback()" class="btns mt-4 ms-2 d-flex">
            <img src="@/assets/img/back.svg" />
            <h6 class="withdraw ms-2 pt-2">Deposit</h6>
        </div>

        <div class="row">
            <div class="col">
                <div class="line1"></div>
            </div>
        </div>

        <div class="row justify-content-center ms-1 mt-3">
            <div class="col-4 action-one">
                <h6 class="mt-1">Balance</h6>
            </div>
            <div class="col-5 action-two">
                <h6 class="mt-2 ps-4">315,042 Ks</h6>
            </div>
        </div>

        <div class="row justify-content-center mt-4">
            <div class="col-3">
                <img src="@/assets/img/pay1.svg" class="w-75 d-block mx-auto" />
            </div>
        </div>

        <div class="row justify-content-center">
            <div class="col-12">
                <div class="p-4 ">
                    <div class="p-2 px-3 pt-3 pay-box">
                        <label class="labeltext3" for="">Amount</label>
                        <input  v-model="amount" type="number" class="form-control mt-1 inputbox" />

                        <label class="labeltext3 mt-3" for="">Kpay Phone Number</label>
                        <input v-model="phone" type="number" class="form-control mt-1 inputbox" />

                        <label class="labeltext3 mt-3" for="">Kpay Owner Name</label>
                        <input v-model="name" type="text" class="form-control mt-1 inputbox mb-2" />
                    </div>
                </div>
                <div>
                    <button @click="handleComfirm(e)" class="loginbtn1 mt-2">
                        Comfirm
                    </button>
                </div>
            </div>
        </div>

        <div class="row mt-4 pt-2 ">
            <div class="col mt-3">
                <h6 class="ft ms-3 ">if you have any problem, don't hesitate contact use</h6>
            </div>
            <div class="pt-1">
                <div class="d-flex justify-content-between box mt-2">
                    <div class="d-flex">
                        <img src="@/assets/img/viber.svg" />
                        <h6 class="ph ms-2 mt-2">+95998745612</h6>
                    </div>
                    <div>
                        <h6 class="condition mt-2">24/7 available</h6>
                    </div>
                </div>
            </div>
            <div class="pt-1 mb-5">
                <div class="d-flex justify-content-between box mt-3">
                    <div class="d-flex">
                        <img src="@/assets/img/tele.svg" />
                        <h6 class="ph ms-2 mt-2">+95998745612</h6>
                    </div>
                    <div>
                        <h6 class="condition mt-2">24/7 available</h6>
                    </div>
                </div>
            </div>
        </div>



    </div>
</template>
<script>
import Swal from 'sweetalert2'
import { storeWithdraw } from '@/services/Auth-Services';
export default {
    data(){
        return{
            amount:null,
            phone:null,
            name:null,
            currentDate:null,
            payment:null,
        }
    },
    created(){
        const payment_id = this.$route.params.id;
        this.payment = payment_id; 
    },
    methods: {
        handleback() {
            history.back();
        },
        handleComfirm() {
            const dateObj = new Date();
            const year = dateObj.getFullYear();
            const month = String(dateObj.getMonth() + 1).padStart(2, '0');
            const day = String(dateObj.getDate()).padStart(2, '0');
            this.currentDate = `${year}-${month}-${day}`;
            let data ={
                'payment_id' : this.payment,
                'customer_amount' : this.amount,
                'customer_phone' : this.phone,
                'customer_name':this.name,
                'withdraw_date' : this.currentDate,
            }
            storeWithdraw(data).then((response)=>{
                console.log(response)
            }).catch((error)=>{
                console.log(error);
            })
            Swal.fire({
                icon: 'success',
                html: `
            <div class="congrat1">
               Request Successful!
            </div>
             <div class="ready1">
               Your payment request is pending. We will check
              your payment and confirm you ASAP.
            </div>
        `,
                width: "89%",
                customClass: {
                    icon: 'my-icon-class',
                    popup: 'my-popup-class',
                    confirmButton: 'my-button-class1',
                },
                confirmButtonText: 'Okay',
            });
        }
    }
}
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Secular+One&display=swap');

.line1 {
    width: 89% !important;
    height: 1px;
    display: block;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.609);
}

.labeltext3 {
    font-size: 11px;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
    color: black;
}


.pay-box {
    background: #F4ECFF;
    border-radius: 3px;
}

.loginbtn1 {
    font-family: 'Roboto', sans-serif;
    font-size: 17px;
    font-weight: bold;
    color: white;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 50px;
    padding-right: 50px;
    background-color: #7210FF;
    border: 0;
    display: block;
    margin: auto;
    border-radius: 25px;
}

.congrat1 {
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-style: bold;
    color: #964DFF;
    font-weight: 700;
}

.ready1 {
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    font-style: bold;
    color: #964DFF;
    margin-top: 25px;
    font-weight: 700;
}

.swal2-confirm.my-button-class1 {
    font-family: 'Roboto', sans-serif;
    font-size: 17px;
    font-weight: bold;
    color: white;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #7210FF;
    border: 0;
    width: 160px;
    display: block;
    margin: auto;
    border-radius: 25px;
    margin-bottom: 20px;
    margin-top: 10px;
}
</style>
