<template>
    <!-- Loading Status -->
    <div v-if="getLoadingStatus">
        <LoadingComponent :loading="true" />
    </div>
    <div class="container">
        <!-- Header -->
        <div class="btns mt-2 mt-md-3 ms-2 d-flex justify-content-between">
            <div class="d-flex">
                <img src="@/assets/img/back.svg" />
                <h6 class="withdraw ms-2 pt-2">3D Confirm</h6>
            </div>
            <img class="me-3" src="@/assets/img/mark.svg" />
        </div>
        <div v-if="getErrorStatus">
            <div style="height:100vh" class="d-flex justify-content-center align-items-center">
                <button class="btn btn-sm bg-light"><img class="me-3" src="@/assets/img/mark.svg" /> Error : Can't
                    Connect To Server</button>
            </div>
        </div>

        <div v-else-if="getIsSectionEmpty">
            <div style="height:100vh" class="d-flex justify-content-center align-items-center">
                <button class="btn btn-sm bg-light"><img class="me-3" src="@/assets/img/mark.svg" /> Section not
                    Created</button>
            </div>
        </div>
        <div v-else>
            <div class="d-flex justify-content-between mt-1">
                <h6 class="date-text ms-2 mt-2">Date</h6>
                <small class="close-time-btn mt-2 ms-4">Close Time : {{
                    $helper.formattedTime(getThreedSection.closing_time) }}</small>
                <div class="thai-box">
                    <button class="btn btn-sm bg-primary">
                        {{ getThreedSection.opening_date }}
                    </button>
                </div>
            </div>
            <BetLogTable class="mt-4" />
        </div>
    </div>
    <BetLogCard v-if="!getIsSectionEmpty && !getErrorStatus"></BetLogCard>
</template>

<script>

import { mapGetters } from 'vuex';
import LoadingComponent from '@/components/LoadingComponent.vue';
import BetLogTable from '@/views/Threed/_bet_log_table.vue';
import BetLogCard from '@/views/Threed/_bet_log_card.vue';

export default {
    components: {
        LoadingComponent,
        BetLogTable,
        BetLogCard
    },
    created() {
        this.$store.dispatch('threed_section/fetchThreedSection');
    },
    computed: {
        ...mapGetters('threed_section', ['getThreedSection', 'getIsSectionEmpty', 'getErrorStatus', 'getLoadingStatus']),
    },
}
</script>

