<template>
    <div class="container">
        <div @click="handleback()" class="btns mt-4 ms-2 d-flex justify-content-between">
            <div class="d-flex">
                <img src="@/assets/img/back.svg" />
                <h6 class="withdraw ms-2 pt-2">2D Lottery</h6>
            </div>
            <img class="me-3" src="@/assets/img/mark.svg" />
        </div>

        <div class="row mt-4 mb-1">
            <div @click="handleChange()" class="col-6">
                <h6 :class="isStatus ? `text-center action-3d-one` : `text-center action-2d-one`">Deposit</h6>
                <div v-if="!isStatus" class="line-bet"></div>
            </div>
            <div @click="handleChange()" class="col-6">
                <h6 :class="isStatus ? `text-center action-2d-one` : `text-center action-3d-one`">Withdraw</h6>
                <div v-if="isStatus" class="line-bet"></div>
            </div>
        </div>

        <div v-if="!isStatus" class="row mt-4">
            <div class="col-12 ">
                <div class="table-box-one">
                    <div class="">
                        <table class="w-100">
                            <thead class="text-center header-line">
                                <tr>
                                    <th class="fs-16">Date</th>
                                    <th class="fs-16">Bank</th>
                                    <th class="fs-16">Amount</th>
                                    <th class="fs-16">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="body-line">
                                    <td class="fs-14 text-black">26-03-2023</td>
                                    <td class="fs-14 text-black">Kpay</td>
                                    <td class="fs-14 text-black">23,000</td>
                                    <td class="fs-14" style="color:#FFB74D">Pending</td>
                                </tr>
                                <tr class="body-line">
                                    <td class="fs-14 text-black">13-03-2023</td>
                                    <td class="fs-14 text-black">Kpay</td>
                                    <td class="fs-14 text-black">141,000</td>
                                    <td class="fs-14" style="color:#0FA958">Approved</td>
                                </tr>
                                <tr class="body-line">
                                    <td class="fs-14 text-black">04-03-2023</td>
                                    <td class="fs-14 text-black">Kpay</td>
                                    <td class="fs-14 text-black">314,000</td>
                                    <td class="fs-14" style="color:#0FA958">Approved</td>
                                </tr>
                                <tr class="body-line">
                                    <td class="fs-14 text-black">21-03-2023</td>
                                    <td class="fs-14 text-black">Kpay</td>
                                    <td class="fs-14 text-black">710,000</td>
                                    <td class="fs-14" style="color:#0FA958">Approved</td>
                                </tr>
                                <tr class="body-line">
                                    <td class="fs-14 text-black">08-03-2023</td>
                                    <td class="fs-14 text-black">Kpay</td>
                                    <td class="fs-14 text-black">844,000</td>
                                    <td class="fs-14" style="color:red">Rejected</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        </div>

        <div v-if="isStatus" class="row mt-4">
            <div class="col-12 ">
                <div class="table-box-one">
                    <div class="">
                        <table class="w-100">
                            <thead class="text-center header-line">
                                <tr>
                                    <th class="fs-16">Date</th>
                                    <th class="fs-16">Bank</th>
                                    <th class="fs-16">Amount</th>
                                    <th class="fs-16">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="body-line">
                                    <td class="fs-14 text-black">26-03-2023</td>
                                    <td class="fs-14 text-black">Kpay</td>
                                    <td class="fs-14 text-black">23,000</td>
                                    <td class="fs-14" style="color:#FFB74D">Pending</td>
                                </tr>
                                <tr class="body-line">
                                    <td class="fs-14 text-black">13-03-2023</td>
                                    <td class="fs-14 text-black">Kpay</td>
                                    <td class="fs-14 text-black">141,000</td>
                                    <td class="fs-14" style="color:#0FA958">Approved</td>
                                </tr>
                                <tr class="body-line">
                                    <td class="fs-14 text-black">04-03-2023</td>
                                    <td class="fs-14 text-black">Kpay</td>
                                    <td class="fs-14 text-black">314,000</td>
                                    <td class="fs-14" style="color:#0FA958">Approved</td>
                                </tr>
                                <tr class="body-line">
                                    <td class="fs-14 text-black">21-03-2023</td>
                                    <td class="fs-14 text-black">Kpay</td>
                                    <td class="fs-14 text-black">710,000</td>
                                    <td class="fs-14" style="color:#0FA958">Approved</td>
                                </tr>
                                <tr class="body-line">
                                    <td class="fs-14 text-black">08-03-2023</td>
                                    <td class="fs-14 text-black">Kpay</td>
                                    <td class="fs-14 text-black">844,000</td>
                                    <td class="fs-14" style="color:red">Rejected</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        </div>


    </div>
</template>
<script>
export default {
    name: "depositComponent",
    data() {
        return {
            isStatus: false,
        }
    },
    methods: {
        handleback() {
            history.back();
        },
        handleChange() {
            this.isStatus = !this.isStatus
        }
    }
}
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Secular+One&display=swap');

.action-2d-one {
    font-family: 'Secular One', sans-serif;
    font-size: 17px;
    font-weight: 400;
    color: #7210FF;
}

.action-3d-one {
    font-family: 'Secular One', sans-serif;
    font-size: 17px;
    font-weight: 400;
    color: #000;
}

.header-line {
    height: 48px;
    border-bottom: 2px solid #7410ffa5;
    font-family: 'Secular One', sans-serif;
    font-size: 17px;
    font-weight: 400;
}

.body-line {
    height: 48px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.189);
    font-family: 'Secular One', sans-serif;
    font-size: 16px;
    font-weight: 400;
}
</style>
