<template>
    <div class="container">
        <div @click="handleback()" class="btns mt-4 ms-2 d-flex">
            <img src="@/assets/img/back.svg" />
            <h6 class="withdraw ms-2 pt-2">Withdraw</h6>
        </div>

        <div class="row mt-3 ps-2">
            <div class="col-12 mb-3">
                <h6 class="withdraw ">Choose the Bank</h6>
            </div>

            <div @click="handlego(item.id)" v-for="(item,index) in data" :key="index" class="col-3">
                <img src="@/assets/img/pay1.svg" class="w-75 d-block mx-auto" />
            </div>
            <div class="linethrougn mt-2"></div>
        </div>

        <div class="row justify-content-center ms-1 mt-3">
            <div class="col-4 action-one">
                <h6 class="mt-1">Balance</h6>
            </div>
            <div class="col-5 action-two">
                <h6 class="mt-2 ps-4">{{balance}} Ks</h6>
            </div>
        </div>

        <div class="row mt-5 pt-5">
            <div class="col mt-3">
                <h6 class="ft ms-3 ">if you have any problem, don't hesitate contact use</h6>
            </div>
            <div class="pt-1">
                <div class="d-flex justify-content-between box mt-2">
                    <div class="d-flex">
                        <img src="@/assets/img/viber.svg" />
                        <h6 class="ph ms-2 mt-2">+95998745612</h6>
                    </div>
                    <div>
                        <h6 class="condition mt-2">24/7 available</h6>
                    </div>
                </div>
            </div>
            <div class="pt-1 ">
                <div class="d-flex justify-content-between box mt-3">
                    <div class="d-flex">
                        <img src="@/assets/img/tele.svg" />
                        <h6 class="ph ms-2 mt-2">+95998745612</h6>
                    </div>
                    <div>
                        <h6 class="condition mt-2">24/7 available</h6>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import { getBalance, getPaymentType } from '@/services/Auth-Services';
export default {
    data(){
        return {
            data :[],
            balance:null,
        }
    },
    created(){
        this.getPayment();
        this.getBalanceAmount();
    },
    methods: {
        handleback() {
            history.back();
        },
        getPayment(){
            getPaymentType().then((response)=>{
                console.log(response)
                this.data = response.data;
            }).catch((error)=>{
                console.log(error);
            })
        },
        handlego(id){
            this.$router.push({
                name:"kpaywithdraw",
                params:{
                    id:id
                }
            })
        },
        getBalanceAmount(){
            getBalance().then((response)=>{
                this.balance = response.data[0].balance;
                console.log(response)
            }).catch((error)=>{
                console.log(error);
            })
        }
    }
}
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Secular+One&display=swap');

.withdraw {
    font-family: 'Secular One', sans-serif;
    font-size: 16px;
    font-style: Regular;
}

.choose {
    font-family: 'Secular One', sans-serif;
    font-size: 16px;
    font-style: Regular;
}

.linethrougn {
    width: 89% !important;
    height: 1px;
    display: block;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.216);
}

.action-one {
    background-color: #FFB74D;
    font-size: 18px;
    font-family: 'Roboto', sans-serif;
    color: #5856D6;
    padding: 10px 10px;
    text-align: center;
    border-radius: 25px;
    position: inherit;
    left: 20px;
    z-index: 1;
}

.action-two {
    background-color: #5856D6;
    font-size: 18px;
    font-family: 'Roboto', sans-serif;
    color: white;
    padding: 5px 5px;
    height: 52px !important;
    text-align: center;
    border-radius: 0px 25px 25px 0px;
    position: relative;
    right: 20px;
}

.ft {
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    font-weight: 400;
}

.condition {
    font-size: 12px;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
}

.ph {
    font-size: 15px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
}
</style>
