<template>
    <div>
        <div class="row modal-box-float5">
            <div class="p-4 pt-3 mt-3">
                <!-- individual number and individual amount -->
                <div class="d-flex justify-content-between align-items-center ">
                    <h6 class="options-text">Fill number <span class="options-text ">:</span> </h6>
                    <input v-model="bet_number" type="tel" minlength="3" class="bet-number py-1" placeholder="---"
                        required />
                    <div style="width:45%" class="d-flex justify-content-between ">
                        <button class="btn btn-sm">
                            <i class="bi bi-plus-circle-fill" @click="handleIncrementAmount"
                                style="font-size: 25px; color:#7210ff"></i>
                        </button>
                        <input v-model="bet_amount" :placeholder="bet_amount" :min="threed_section.min_amount"
                            :max="threed_section.max_amount" type="number" class="individual-amount mx-1 mt-2" />
                        <button class="btn btn-sm" @click="handleDecrementAmount">
                            <i class="bi bi-dash-circle-fill" style="font-size: 25px; color:#7210ff"></i>
                        </button>
                    </div>
                </div>
                <!-- Make R and Fill Button  -->
                <div class="d-flex justify-content-between mt-3">
                    <h6 class="options-text mt-2 ms-4">
                        <input v-model="is_make_reverse" type="checkbox" />Choose(R)
                    </h6>
                    <button @click="handleFill(bet_number, bet_amount)" class="fill-btn me-3 btn-sm">
                        Fill
                    </button>
                </div>

                <!-- total bet number , total amount and Bet Now -->
                <div class="d-flex justify-content-between mt-3">
                    <div class="d-flex">
                        <h6 class="options-text mt-2">No </h6>
                        <span class="options-text mt-1 ms-2 ">:</span>
                        <input :value="current_bet_log_info.length" readonly class="number-bet-number ms-2" />
                    </div>
                    <div class="d-flex pe-4">
                        <h6 class="options-text mt-2 text-black">Total </h6>
                        <span class="options-text mt-1  ">:</span>
                        <input :value="total_bet_amount()" readonly class="action-input-3 ms-2" />
                    </div>
                    <div>
                        <button @click="handleBetNow()" class="bet me-3">Bet</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { fetchBalanceQuery } from '@/composables/balance';
import { makeR } from '@/composables/mixin';
import Swal from 'sweetalert2'
import threedService from "@/services/threed-service";
import { useRouter } from 'vue-router'
export default {

    setup() {

        const store = useStore();
        const getThreedSection = computed(() => store.getters['threed_section/getThreedSection']);
        const getCurrentBetLogInfo = computed(() => store.getters['threed_bet_log/getBetInfo']);
        const { data: currentUser } = fetchBalanceQuery();
        const min_amount = getThreedSection.value.min_amount;
        const router = useRouter()
        let bet_amount = ref(min_amount);
        let bet_number = ref();
        let is_make_reverse = ref(false);



        const handleFill = (bet_num, bet_amount) => {
            if (betNumberValidation() && betAmountValidation()) {
                if (is_make_reverse.value) {
                    const { generateRNumber } = makeR();
                    const generatedRNumber = generateRNumber(bet_num);
                    for (const iterator of generatedRNumber) {
                        let bet_log = {
                            'number': iterator,
                            'odd': getThreedSection.value.odd,
                            'amount': bet_amount
                        }
                        store.dispatch('threed_bet_log/setBetInfo', bet_log);
                    }
                    return false
                }
                let bet_log = {
                    'number': bet_num,
                    'odd': getThreedSection.value.odd,
                    'amount': bet_amount
                }
                store.dispatch('threed_bet_log/setBetInfo', bet_log);
            }
        };
        const handleBetNow = () => {
            if (!checkBalance())
                return false
            store.dispatch('loading');
            threedService.bet(getCurrentBetLogInfo.value).then(({ data }) => {
                console.log(data);
                store.dispatch('loadingComplete')
                router.push({
                    name: '3DComfirmSuccess',
                })
            }).catch(error => {
                Swal.fire({
                    icon: 'error',
                    text: `Error: ${error}`,
                })
            }).finally(() => {
                store.dispatch('loadingComplete')
            });

        };
        const checkBalance = () => {
            const curren_balance = currentUser.value[0].balance;
            if (totalBetAmount() > curren_balance) {
                Swal.fire({
                    icon: 'error',
                    text: 'Insufficient amount !',
                })
                return false
            }
            return true
        };

        const handleIncrementAmount = () => parseInt(bet_amount.value) >= parseInt(getThreedSection.value.max_amount) ? null : bet_amount.value += 100;
        const handleDecrementAmount = () => parseInt(bet_amount.value) <= parseInt(getThreedSection.value.min_amount) ? null : bet_amount.value -= 100;
        const isNumber = (targetValue) => typeof targetValue === "string" ? !isNaN(targetValue) : undefined;

        const totalBetAmount = () => {
            return getCurrentBetLogInfo.value.reduce((newAmount, bet) => {
                const amountAsNumber = parseInt(bet.amount, 10);
                return newAmount + amountAsNumber;
            }, 0);
        };

        const betNumberValidation = () => {
            if (!isNumber(bet_number.value)) {
                Swal.fire({
                    icon: 'error',
                    text: 'Wrong Number!',
                })
                return false
            }
            if (bet_number.value.length > 3) {
                Swal.fire({
                    icon: 'error',
                    text: 'Too Much Number!',
                })
                return false
            }
            if (bet_number.value.length < 3) {
                Swal.fire({
                    icon: 'error',
                    text: 'Too Short Number!',
                })
                return false
            }
            let is_match_with_block_number = getThreedSection.value.block_number.some((block_number) => block_number.number == bet_number.value)
            if (is_match_with_block_number) {
                Swal.fire({
                    icon: 'error',
                    text: `Sorry! ${bet_number.value} was Block`,
                })
                return false
            }
            let is_match_with_number_limit = getThreedSection.value.number_limit.some((number_limit) => number_limit.number == bet_number.value);
            if (is_match_with_number_limit) {
                Swal.fire({
                    icon: 'error',
                    text: `Checking ... Available bet mount...`,
                })
                return false
            }
            return true
        };

        const betAmountValidation = () => {
            if (!bet_amount.value) {
                Swal.fire({
                    icon: 'error',
                    text: 'Fill Amount',
                })
                return false
            }
            if (isNumber(bet_amount.value)) {
                Swal.fire({
                    icon: 'error',
                    text: 'Worng Amount!',
                })
                return false
            }
            if (bet_amount.value.length < 3) {
                Swal.fire({
                    icon: 'error',
                    text: 'Amount at lest 3 Character',
                })
                return false
            }
            if (bet_amount.value > getThreedSection.value.max_amount) {
                Swal.fire({
                    icon: 'error',
                    text: `Max Amount : ${getThreedSection.value.max_amount}`,
                })
                return false
            }
            if (bet_amount.value < getThreedSection.value.min_amount) {
                Swal.fire({
                    icon: 'error',
                    text: `Min Amount : ${getThreedSection.value.min_amount}`,
                })
                return false
            }
            return true
        };
        return {

            bet_amount,
            bet_number,
            threed_section: getThreedSection.value,
            current_bet_log_info: getCurrentBetLogInfo.value,
            total_bet_amount: totalBetAmount,
            is_make_reverse,

            handleFill,
            handleIncrementAmount,
            handleDecrementAmount,
            handleBetNow,
        };
    },
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Secular+One&display=swap');
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css");

.individual-amount {
    width: 70% !important;
    height: 30px;
    border-radius: 10px;
    border: 2px solid #7210FF;
}

.bet {
    font-family: 'Secular One', sans-serif;
    font-size: 15px;
    font-weight: 400;
    padding: 4px 20px;
    color: #7210FF;
    background: #FF9800;
    border: 0;
    border-radius: 10px;
    text-align: center;
}

.fill-btn {
    font-family: 'Secular One', sans-serif;
    padding-left: 3px;
    font-size: 18px;
    font-weight: 400;
    background: #7210FF;
    border: 0;
    color: white;
    padding: 1px 40px;
    border-radius: 10px;
}

.close-time-btn {
    font-family: 'Secular One', sans-serif;
    font-size: 11px;
    font-weight: 400;
    color: #EA2839;
}

.date-text {
    font-family: 'Secular One', sans-serif;
    font-size: 16px;
    font-weight: 400;
}

.bet-number {
    width: 20% !important;
    height: 2%;
    border-radius: 10px;
    border: 2px solid #7210FF;
}

.bet-number::placeholder {
    font-family: 'Secular One', sans-serif;
    padding-left: 3px;
    font-size: 15px;
    font-weight: 400;
    color: #7210FF;
    text-align: center;
}

.number-bet-number {
    width: 40% !important;
    height: 85%;
    border-radius: 10px;
    border: 2px solid #7210FF;
}

.number-bet-number::placeholder {
    font-family: 'Secular One', sans-serif;
    padding-left: 3px;
    font-size: 17px;
    font-weight: 400;
    color: #7210FF;
    text-align: center;
}

.action-input-3 {
    width: 100% !important;
    height: 85%;
    border-radius: 10px;
    border: 2px solid #7210FF;
}

.action-input-3::placeholder {
    font-family: 'Secular One', sans-serif;
    padding-left: 3px;
    font-size: 15px;
    font-weight: 400;
    color: #7210FF;
    text-align: center;
}

.action-input::placeholder {
    font-family: 'Secular One', sans-serif;
    padding-left: 3px;
    font-size: 15px;
    font-weight: 400;
    color: #7210FF;
}



@media screen and (max-width: 3000px) {
    .modal-box-float5 {
        position: fixed;
        width: 30.9%;
        bottom: 0;
        left: 35.5%;
        border-radius: 80px 80px 0 0;
        border: 2px solid #7210FF;
        background: white;
    }
}

@media screen and (max-width: 500px) {
    .modal-box-float5 {
        position: fixed;
        width: 107%;
        left: 0;
        bottom: 0;
        border-radius: 80px 80px 0 0;
        border: 2px solid #7210FF;
        background: white;
    }
}
</style>
<!-- 
{
    "data": {
        "id": 2,
        "opening_date": "2023-08-31",
        "opening_time": "16:00",
        "closing_time": "16:00",
        "odd": 600,
        "r_odd": 11,
        "min_amount": 500,
        "max_amount": 10000,
        "user_commission": 10,
        "agent_commission": 14,
        "set": null,
        "value": null,
        "winning_number": null,
        "reward_users": null,
        "is_maintenace": 0,
        "status": 1,
        "block_number": [
            {
                "id": 5,
                "threed_section_id": "2",
                "number": "456"
            },
            {
                "id": 6,
                "threed_section_id": "2",
                "number": "789"
            },
            {
                "id": 7,
                "threed_section_id": "2",
                "number": "999"
            },
            {
                "id": 9,
                "threed_section_id": "2",
                "number": "888"
            }
        ],
        "number_limit": [
            {
                "id": 5,
                "threed_section_id": "2",
                "number": 444,
                "min_amount": 500,
                "max_amount": 20000
            },
            {
                "id": 6,
                "threed_section_id": "2",
                "number": 723,
                "min_amount": 500,
                "max_amount": 20000
            },
            {
                "id": 7,
                "threed_section_id": "2",
                "number": 124,
                "min_amount": 500,
                "max_amount": 30000
            }
        ]
    }
} -->