<template>
    <div class="profile-page">
        <div @click="handleback()" class="py-3 px-3 d-flex justify-content-between align-items-center">
            <div class="d-flex gap-2 align-items-center">
                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M11.7812 20.9084L4.63125 13.7584C4.52291 13.65 4.446 13.5327 4.4005 13.4063C4.355 13.2799 4.33261 13.1445 4.33333 13C4.33333 12.8556 4.35608 12.7202 4.40158 12.5938C4.44708 12.4674 4.52364 12.35 4.63125 12.2417L11.7812 5.09169C11.9799 4.89308 12.2283 4.78944 12.5266 4.78078C12.8249 4.77211 13.082 4.87575 13.2979 5.09169C13.5146 5.2903 13.6276 5.53875 13.637 5.83702C13.6464 6.1353 13.5424 6.39241 13.325 6.60836L8.01666 11.9167H20.1229C20.4299 11.9167 20.6873 12.0207 20.8953 12.2287C21.1033 12.4367 21.207 12.6938 21.2062 13C21.2062 13.307 21.1026 13.5644 20.8953 13.7724C20.6881 13.9804 20.4306 14.0841 20.1229 14.0834H8.01666L13.325 19.3917C13.5236 19.5903 13.6276 19.8431 13.637 20.15C13.6464 20.457 13.5424 20.7097 13.325 20.9084C13.1264 21.125 12.8736 21.2334 12.5667 21.2334C12.2597 21.2334 11.9979 21.125 11.7812 20.9084Z"
                        fill="black" />
                </svg>
                <h6 class="fs-18 fw-normal mb-0 only-font">Confirm Lottery</h6>
            </div>
            <div>
                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M13 4.33341C17.7786 4.33341 21.6667 8.2215 21.6667 13.0001C21.6667 17.7787 17.7786 21.6667 13 21.6667C8.22144 21.6667 4.33335 17.7787 4.33335 13.0001C4.33335 8.2215 8.22144 4.33341 13 4.33341ZM13 2.16675C7.01677 2.16675 2.16669 7.01683 2.16669 13.0001C2.16669 18.9833 7.01677 23.8334 13 23.8334C18.9833 23.8334 23.8334 18.9833 23.8334 13.0001C23.8334 7.01683 18.9833 2.16675 13 2.16675ZM14.0834 16.2501H11.9167V18.4167H14.0834V16.2501ZM11.9167 14.0834H14.0834L14.625 7.58342H11.375L11.9167 14.0834Z"
                        fill="black" />
                </svg>
            </div>
        </div>

        <div class="my-3 mx-3">
            <div class="">
                <div class="text-center py-3">
                    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M40 70C56.5685 70 70 56.5685 70 40C70 23.4315 56.5685 10 40 10C23.4315 10 10 23.4315 10 40C10 56.5685 23.4315 70 40 70Z"
                            fill="#0FA958" fill-opacity="0.3" stroke="#0FA958" stroke-width="5" stroke-linecap="round"
                            stroke-linejoin="round" />
                        <path d="M26.6666 39.9999L36.6666 49.9999L53.3333 33.3333" stroke="#0FA958" stroke-width="5"
                            stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </div>
                <h6 class="fs-16 fw-500 my-3 text-center only-font">Confirm your Ticket numbers</h6>
                <h6 class="fs-12 text-center fw-500 text-black px-2 only-font" style="word-spacing: 3px;">
                    Thanks for purchasing our Thailand Lottery ticket system
                </h6>
            </div>
            <div class="d-flex gap-2 align-items-center justify-content-center my-4">
                <div>
                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M21.4482 8.55461C20.9608 8.06701 20.6754 7.41336 20.6491 6.72439C20.6228 6.03543 20.8576 5.36193 21.3064 4.8386C21.3436 4.79507 21.3629 4.73912 21.3605 4.68197C21.3581 4.62481 21.3342 4.57067 21.2935 4.53039L18.7078 1.94172C18.6651 1.899 18.6071 1.875 18.5467 1.875C18.4862 1.875 18.4283 1.899 18.3855 1.94172L14.264 6.06321C14.112 6.21523 13.9974 6.4006 13.9295 6.60461C13.8618 6.80909 13.7474 6.99499 13.5953 7.14755C13.4433 7.30011 13.2578 7.41514 13.0535 7.48352C12.8494 7.55154 12.6638 7.66608 12.5115 7.81809L1.94178 18.3855C1.89906 18.4282 1.87506 18.4862 1.87506 18.5466C1.87506 18.607 1.89906 18.665 1.94178 18.7077L4.52752 21.2935C4.5678 21.3341 4.62194 21.358 4.6791 21.3604C4.73625 21.3628 4.7922 21.3435 4.83573 21.3064C5.35895 20.857 6.0326 20.6219 6.7218 20.648C7.411 20.6741 8.0649 20.9596 8.55259 21.4473C9.04028 21.935 9.32577 22.5889 9.35191 23.2781C9.37804 23.9673 9.1429 24.641 8.69354 25.1642C8.65642 25.2077 8.63711 25.2637 8.6395 25.3208C8.6419 25.378 8.6658 25.4321 8.70643 25.4724L11.2922 28.0581C11.3349 28.1009 11.3929 28.1249 11.4533 28.1249C11.5137 28.1249 11.5717 28.1009 11.6144 28.0581L22.1848 17.4884C22.3368 17.3361 22.4513 17.1506 22.5193 16.9464C22.587 16.7419 22.7014 16.556 22.8535 16.4035C23.0055 16.2509 23.191 16.1359 23.3953 16.0675C23.5993 15.9996 23.7847 15.885 23.9367 15.7329L28.0582 11.6114C28.1009 11.5687 28.1249 11.5107 28.1249 11.4503C28.1249 11.3899 28.1009 11.3319 28.0582 11.2892L25.4724 8.70344C25.4322 8.66281 25.378 8.6389 25.3209 8.63651C25.2637 8.63412 25.2078 8.65343 25.1642 8.69055C24.6416 9.14018 23.9686 9.37597 23.2796 9.35077C22.5906 9.32556 21.9366 9.04123 21.4482 8.55461Z"
                            stroke="#7210FF" stroke-width="1.625" stroke-miterlimit="10" />
                        <path
                            d="M14.6777 8.22886L13.7104 7.26147M17.257 10.8082L16.6119 10.1636M19.8363 13.388L19.1918 12.7429M22.7385 16.2896L21.7711 15.3222"
                            stroke="#7210FF" stroke-width="1.625" stroke-miterlimit="10" stroke-linecap="round" />
                    </svg>
                </div>
                <h6 class="fs-13 fw-bold mb-0" style="color: #7210FF">You can save your tickets</h6>
            </div>
        </div>

        <!-- start tickets  -->
        <div class="my-3 mx-3">

            <!-- start ticket box  -->
            <div v-for="(item, index) in ThaidList" :key="index"
                class="d-flex justify-content-between gap-1 align-items-center mb-3">
                <div class="px-2 d-flex justify-content-center align-items-center gap-1 ticket-container">
                    <div v-for="(digit, digitIndex) in item.bet_number.split('')" :key="digitIndex"
                        class="text-center py-2 px-3">
                        <h6 class="fw-bold fs-16 mb-1">{{ digit }}</h6>
                        <h6 class="fw-bold  mb-0 ticket-number">Sev</h6>
                    </div>
                </div>
                <div class="px-1">
                    <svg @click="printTicket(item.id)" width="30" height="30" viewBox="0 0 30 30" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M6.25 26.25C5.5625 26.25 4.97375 26.005 4.48375 25.515C3.99375 25.025 3.74917 24.4367 3.75 23.75V6.25C3.75 5.5625 3.995 4.97375 4.485 4.48375C4.975 3.99375 5.56334 3.74917 6.25 3.75H21.25L26.25 8.75V15.5L23.75 18V9.78125L20.2188 6.25H6.25V23.75H18L15.5 26.25H6.25ZM15 22.5C16.0417 22.5 16.9271 22.1354 17.6563 21.4063C18.3854 20.6771 18.75 19.7917 18.75 18.75C18.75 17.7083 18.3854 16.8229 17.6563 16.0938C16.9271 15.3646 16.0417 15 15 15C13.9583 15 13.0729 15.3646 12.3438 16.0938C11.6146 16.8229 11.25 17.7083 11.25 18.75C11.25 19.7917 11.6146 20.6771 12.3438 21.4063C13.0729 22.1354 13.9583 22.5 15 22.5ZM7.5 12.5H18.75V7.5H7.5V12.5ZM18.75 28.75V26.5313L25 20.3125L27.1875 22.5313L20.9688 28.75H18.75ZM28 21.6875L25.7813 19.5L26.8438 18.4375C26.9688 18.3125 27.12 18.25 27.2975 18.25C27.475 18.25 27.6258 18.3125 27.75 18.4375L29.0625 19.75C29.1875 19.875 29.25 20.0208 29.25 20.1875C29.25 20.3542 29.1875 20.5 29.0625 20.625L28 21.6875Z"
                            fill="#34B232" />
                    </svg>
                </div>
            </div>

            <div>
                <div v-for="(item, index) in ListData" :key="index" class="row justify-content-center mx-1 mt-3 my-1">
                    <div class="w-100 h-auto mb-2 position-relative">
                        <img src="@/assets/lottery.jpeg" class="w-100 h-100" />
                        <div class="history-box ms-1">
                            <h6 v-for="(digit, digitIndex) in item.bet_number.split('')" :key="digitIndex" class="p-l">{{
                                digit }}</h6>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end ticket box  -->
            <!-- end ticket box  -->
            <div class="d-flex justify-content-center align-items-center py-5">
                <button @click="handlenextpage()" class="my_btn btn_primary ripple only-font">Go History</button>
            </div>
        </div>
        <!-- end tickets  -->

    </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    data() {
        return {
            ThaidList: null,
            ListData: null,
        };
    },
    computed: {
        ...mapGetters(['getdata'])
    },
    created() {
        this.ThaidList = this.getdata;
    },
    methods: {
        handleback() {
            history.back();
        },
        handlenextpage() {
            this.$router.push({
                name: 'thaidhistory',
            })
        },
         printTicket(id) {
            // Find the selected item in the ThaidList
            const selectedItem = this.ThaidList.find(item => item.id === id);

            if (!selectedItem) {
                console.error("Item not found!");
                return;
            }

            // Create a new window for printing
            const printWindow = window.open("", "_blank");

            // Fetch the data and update ListData
            this.ListData = selectedItem;

            // Create a promise that resolves after data is updated
            const dataReadyPromise = new Promise(resolve => {
                // Check every 100 milliseconds if ListData is updated
                const checkDataInterval = setInterval(() => {
                    if (this.ListData === selectedItem) {
                        // Data is updated, resolve the promise
                        clearInterval(checkDataInterval);
                        resolve();
                    }
                }, 100);
            });

            // Wait for the data to be updated and then write the content to the print window
            dataReadyPromise.then(() => {
                printWindow.document.write(`
        <html>
          <head>
            <link rel="stylesheet" href="path/to/your/print/style.css">
          </head>
          <body>
            <div class="print-content">
              <!-- Your v-for loop content -->
              <div v-for="(item, index) in ListData" :key="index" class="row justify-content-center mx-1 mt-3 my-1">
                <div class="w-100 h-auto mb-2 position-relative">
                  <img src="https://example.com/assets/lottery.jpeg" class="w-100 h-100" />
                  <div class="history-box ms-1">
                    <h6 v-for="(digit, digitIndex) in item.bet_number.split('')" :key="digitIndex" class="p-l">{{ digit }}</h6>
                  </div>
                </div>
              </div>
            </div>
          </body>
        </html>
      `);

                // Close the document writing to ensure content is loaded
                printWindow.document.close();

                // Wait for the window to load its content
                printWindow.onload = () => {
                    // Print the content
                    printWindow.print();
                    // Close the print window after printing
                    printWindow.close();
                };
            });
        },
        },
}
</script>

<style scoped>
h6,
p {
    font-family: 'Roboto';
}

.profile-page {
    overflow: scroll;
}

.ticket-container {
    background-image: url('@/assets/img/background.jpg');
    background-position: center;
    background-size: cover;
}

.ticket-number {
    font-size: 10px;
}

.ticket-img-container {
    width: 100%;
    /* height: 300px; */
    overflow: hidden;
}

@media print {

    /* Show the <div> with v-for loop content when printing */
    .profile-page>div {
        display: block;
    }
}

.my_btn {
    display: inline-block;
    font-weight: bold;
    outline: none;
    border: none;
    box-shadow: none;
    text-decoration: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    transform: translateY(0);
}

.btn_warning {
    color: #fff;
    background-color: #FF9800;
    border-radius: 10px;
    width: 80px;
    height: 45px;
}

.btn_primary {
    color: #fff;
    background-color: #7210FF;
    /* width: 80px; */
    border-radius: 30px;
    /* transition: .3s linear; */
}

.my_btn.ripple {
    background-position: center;
    transition: background 0.8s;
}

.btn_primary.ripple:hover {
    background: #7210FF radial-gradient(circle, transparent 1%, #7210FF 1%) 50%/16000%;
}

.btn_warning.ripple:hover {
    background: #FF9800 radial-gradient(circle, transparent 1%, #FF9800 1%) 50%/16000%;
}

.my_btn.ripple:active {
    background-color: #ffffff80;
    background-size: 100%;
    transition: background 0s, transform 0.2s;
    transform: translateY(1px);
}

.only-font {
    font-family: 'Secular One', sans-serif;
}</style>
