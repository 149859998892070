<template>
    <div style="background: #F4ECFF;border-radius: 10px;">
        <div class="p-3 pt-2">
            <table class="w-100">
                <thead class="text-center">
                    <tr>
                        <th>No</th>
                        <th>Odd</th>
                        <th>Clear</th>
                        <th>Amount</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in getBetInfo" :key="index">
                        <td>{{ item.number }}</td>
                        <td>{{ item.odd }}</td>
                        <td>
                            <button @click="deleteNumber(index)" class="btn btn-sm">
                                <i class="bi bi-trash" style="font-size: 25px; color:#7210ff"></i>
                            </button>
                        </td>
                        <td>
                            <button @click="incrementAmount(index)" class="btn btn-sm">
                                <i class="bi bi-plus-circle-fill" style="font-size: 20px; color:#7210ff"></i>
                            </button>
                            {{ item.amount }}
                            <button @click="decrementAmount(index)" class="btn btn-sm">
                                <i class="bi bi-dash-circle-fill" style="font-size: 20px; color:#7210ff"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>

import { mapGetters } from 'vuex';
export default {
    computed: {
        ...mapGetters('threed_bet_log', ['getBetInfo']),
    },
    methods: {
        deleteNumber(index) {
            this.$store.dispatch('threed_bet_log/deleteBetInfo', index);
        },
        incrementAmount(index) {
            this.$store.dispatch('threed_bet_log/incrementAmount', index);
        },
        decrementAmount(index) {
            this.$store.dispatch('threed_bet_log/decrementAmount', index);
        },
    },
}
</script>
