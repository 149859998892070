<template>
    <div class="">
        <div class="container position-relative">
            <div @click="handleback()" class="btns mt-4 ms-2 d-flex">
                <img src="@/assets/img/back.svg" />
                <h6 class="withdraw ms-2 pt-2">Slip : #51A4V6T0L2</h6>
            </div>

            <div class="row mt-4">
                <div class="col-12 ">
                    <div class=" p-4 pt-0 table-box">
                        <div class="p-3 pt-2">
                            <table>
                                <thead class="text-center">
                                    <tr>
                                        <th>No</th>
                                        <th>Amount</th>
                                        <th>Rewards</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>523</td>
                                        <td>2,500</td>
                                        <td>0</td>
                                    </tr>
                                    <tr>
                                        <td>671</td>
                                        <td>500</td>
                                        <td>0</td>
                                    </tr>
                                    <tr>
                                        <td>852</td>
                                        <td>3,800</td>
                                        <td>0</td>
                                    </tr>
                                    <tr>
                                        <td>049</td>
                                        <td>900</td>
                                        <td>0</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="row modal-box-float">
            <div class="col-12 pt-4">
                <div class="p-2 px-4">
                    <div class="d-flex justify-content-between">
                        <h6 class="options-text">Status </h6>
                        <span class="options-text me-5">:</span>
                        <h6 class="status-text">Active</h6>
                    </div>
                    <div class="d-flex justify-content-between mt-1">
                        <h6 class="options-text">Odd </h6>
                        <span class="options-text ">:</span>
                        <h6 class="status-text1">600 (R-10)</h6>
                    </div>
                    <div class="d-flex justify-content-between mt-1">
                        <h6 class="options-text">Total Bet </h6>
                        <span class="options-text me-5 pe-5">:</span>
                        <h6 class="status-text1">4</h6>
                    </div>
                    <div class="d-flex justify-content-between mt-1">
                        <h6 class="options-text">Date </h6>
                        <span class="options-text ">:</span>
                        <h6 class="status-text1">03 APR AM</h6>
                    </div>
                    <div class="d-flex justify-content-between mt-1">
                        <h6 class="options-text">Total Amount </h6>
                        <span class="options-text me-5 pe-5">:</span>
                        <h6 class="status-text2">15,000</h6>
                    </div>
                    <div class="d-flex justify-content-between mt-1">
                        <h6 class="options-text">Reward </h6>
                        <span class="options-text me-5 pe-5">:</span>
                        <h6 class="status-text2">0</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    methods: {
        handleback() {
            history.back();
        },
    }
}
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Secular+One&display=swap');


table {
    width: 110%;
}

th,
td {
    border: 1px solid black;
    text-align: center;
}

tbody tr {
    height: 48px;
    border-bottom: 1px solid #7410ff5d;
    font-family: 'Secular One', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #7210FF;
}

thead th {
    height: 48px;
    border-bottom: 1px solid #7410ff5d;
    font-family: 'Secular One', sans-serif;
    font-size: 17px;
    font-weight: 400;
}

.table-box {
    background: #F4ECFF;
    border-radius: 10px;
    height: 600px;
}

.modal-box-float {
    position: fixed;
    width: 105%;
    bottom: 0;
    border: 1px solid black;
    border-radius: 90px 75px 0px 0px;
    border: 2px solid #7210FF;
    background: white;
}

.options-text {
    font-family: 'Secular One', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #7210FF;
}

.status-text {
    font-family: 'Secular One', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #34B232;
}

.status-text1 {
    font-family: 'Secular One', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: black;
}

.status-text2 {
    font-family: 'Secular One', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: red;
}
</style>
