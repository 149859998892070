<template>
    <div class="container">
        <div @click="handleback()" class="btns mt-4 ms-2 d-flex justify-content-between">
            <div class="d-flex">
                <img src="@/assets/img/back.svg" />
                <h6 class="withdraw ms-2 pt-2">2D Confirm</h6>
            </div>
        </div>

        <div class="row mt-1">
            <div class="col-12">
                <div class="d-flex justify-content-between align-items-center mt-1 mb-2">
                    <h6 class="comfirm-3d ms-2 mt-2">Date/Time</h6>
                    <div class="d-flex gap-1 align-items-center">
                        <h6 class=" mt-2 ms-4 ps-3 twod-text mt-2">( {{ this.section.date }} )</h6>
                        <div class="box-twod">
                            <h6 class=" ms-2 pt-1 mb-1 twod-text text-white">{{ this.section.opening_time }}</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 ">
                <div v-if="this.section.id" class="" style="background: #F4ECFF; border-radius: 10px;min-height: 600px;margin-bottom: 15vh;">
                    <div class="p-3 pt-2">
                        <table class="w-100">
                            <thead class="text-center">
                                <tr>
                                    <th>No</th>
                                    <th>Odd</th>
                                    <th>Clear</th>
                                    <th>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in selectedNumbers" :key="index">
                                    <td>{{ item.number }}</td>
                                    <td>{{ this.section.odd }}</td>
                                    <td><img src="@/assets/img/delete.svg" @click="remove(index)"/></td>
                                    <td class="" style="width:40%">
                                        <img src="@/assets/img/plus.svg" @click="increaseBetAmount(item.id)"/>
                                        <input class="action-input mx-1" :placeholder="item.amount" @change="updateBetAmount(item.id,$event.target.value)" :value="item.amount"/>
                                        <img src="@/assets/img/minus.svg" @click="decreaseBetAmount(item.id)"/>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        </div>

       <div class="row bet-box-container bg-white" style="border: 2px solid #7210FF;border-radius: 20px 20px 0px 0px;">
            <div class="col-12 mb-4">
                <div class="d-flex justify-content-center mt-3">
                    <div class="d-flex pe-4 pt-2 ms-4">
                        <h6 class="options-text mt-2 text-black">Total </h6>
                        <span class="options-text mt-1  ">:</span>
                        <input class="action-input-3 ms-2 py-2 w-50" :placeholder="this.totalBetAmount" />
                    </div>
                    <div class="me-1 pt-2 me-4">
                        <button @click="bet()" class="bet me-3">Bet</button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import userService from '@/services/user.service';
import Toastify from 'toastify-js';
import "toastify-js/src/toastify.css"

export default {
    name: "depositComponent",
    data() {
        return {
            isStatus: false,
            selectedNumbers: [],
            totalBetAmount:0,
            section: {},
        }
    },
    methods: {
        handleback() {
            history.back();
        },
        handleChange() {
            this.isStatus = !this.isStatus
        },
        handlenext() {
            this.$router.push({
                name: "3DComfirmSuccess"
            });
        },
        getSelectedNumbers(){
            let numbers = JSON.parse(localStorage.getItem('numbers'));
            let unitBetAmount = JSON.parse(localStorage.getItem('unitBetAmount'));
            let data = [];
            for(let i=0;i < numbers.length;i++){
                data.push({
                    'id' : i,
                    'number': numbers[i].number,
                    'odd': 80,
                    'amount': parseInt(unitBetAmount)
                });
            }
            this.selectedNumbers = data;
            this.gettotalBetAmount();
        },
        async getSectionInfo(){
            await userService.getTwodSectionInfo(this.$route.params.id).then(({data})=>{
                this.section = data.data;
                if(this.section.ended){
                    this.$router.push({
                        name: 'home',
                    })
                }
            });
        },
        increaseBetAmount(id) {
            this.selectedNumbers.forEach((item)=>{
                if(item.id == id){
                    return item.amount += 50;
                }
            });
            this.gettotalBetAmount();

        },
        decreaseBetAmount(id) {
            this.selectedNumbers.forEach((item)=>{
                if(item.id == id && item.amount > 0){
                    return item.amount -= 50;
                }
            });
            this.gettotalBetAmount();

        },
        updateBetAmount(id,value){
            this.selectedNumbers.forEach((item)=>{
                if(item.id == id){
                    return item.amount = parseInt(value);
                }
            });
            this.gettotalBetAmount();
        },
        remove(index){
            confirm('A u sure') ?
            this.selectedNumbers.splice(index, 1) : '';
            this.gettotalBetAmount();

        },
        gettotalBetAmount(){
            this.totalBetAmount = 0;
            this.selectedNumbers.forEach((item)=>{
                    this.totalBetAmount += parseInt(item.amount);
            });
            console.log(JSON.stringify(this.selectedNumbers));
        },
        bet(){
            try {
                userService.twoDbet(this.section.id,{
                    numbers: JSON.stringify(this.selectedNumbers)
                }).then(({ data }) => {
                    console.log(data)
                   if(data){
                    this.$router.push({
                        name: "twodcomfirmsuccess"
                    });
                   }
                }).catch((error) => {
                    return Toastify({
                        text: error.response.data.error,
                        duration: 5000, 
                        close: true, 
                        gravity: "top", 
                        position: "center", 
                        backgroundColor: "#000",
                      
                    }).showToast();
                } );
                
            } catch (error) {
                console.log(error);
            }
        }
    },
    created(){
        this.getSectionInfo(); 
        this.getSelectedNumbers();
    }
}
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Secular+One&display=swap');
h6 {
    font-size: 13px !important;
}
tbody tr {
    font-size: 13px ;
}
thead th {
    font-size: 13px;
}
.action-2d-one {
    font-family: 'Secular One', sans-serif;
    font-size: 17px;
    font-weight: 400;
    color: #7210FF;
}

.action-3d-one {
    font-family: 'Secular One', sans-serif;
    font-size: 17px;
    font-weight: 400;
    color: #000;
}

.header-line {
    height: 48px;
    border-bottom: 2px solid #7410ffa5;
    font-family: 'Secular One', sans-serif;
    font-size: 17px;
    font-weight: 400;
}

.body-line {
    height: 48px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.189);
    font-family: 'Secular One', sans-serif;
    font-size: 16px;
    font-weight: 400;
}

.comfirm-3d {
    font-family: 'Secular One', sans-serif;
    font-size: 16px;
    font-weight: 400;
}

.comfirm-3d-text {
    font-family: 'Secular One', sans-serif;
    font-size: 11px;
    font-weight: 400;
    color: #EA2839;
}
.twod-text{
    font-family: 'Secular One', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #7210FF;
}
.box-twod{
    background: #7210FF;
    padding: 4px 15px;
    border-radius: 40px;
}
.modal-box-float6 {
    position: fixed;
    width: 100%;
    bottom: 0;
    border-radius: 40px 40px 0 0;
    border: 2px solid #7210FF;
    background: white;
}
@media screen and (max-width: 3000px) {
.modal-box-float6 {
    position: fixed;
    width: 30.9%;
    bottom: 0;
    left: 35.5%;
    border-radius: 80px 80px 0 0;
    border: 2px solid #7210FF;
    background: white;
}
}


.bet-box-container {
    position: fixed;
    bottom: 0px;
    left: 0;
    right: 0;
}

@media screen and (max-width: 3000px) {
.bet-box-container {
    position: fixed;
    bottom: 0px;
    left: 33.6%;
    right: 50 !important;
    width: 32.5%;
}
}
</style>
