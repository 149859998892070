<template>
    <div class="container">
        <div @click="handleback()" class="btns mt-4 ms-2 d-flex">
            <img src="@/assets/img/back.svg" />
            <h6 class="withdraw ms-2 pt-2">Deposit</h6>
        </div>

        <div class="row mt-3 ps-2">
            <div class="col-12 mb-3">
                <h6 class="withdraw ">Choose the Bank</h6>
            </div>

            <div class="col-3">
                    <img src="@/assets/img/pay1.svg" class="w-75 d-block mx-auto" />
            </div>

            <div class="col-3">
                <img src="@/assets/img/pay2.svg" class="w-75 d-block mx-auto" />
            </div>
            <div class="col-3">
                <img src="@/assets/img/pay3.svg" class="w-75 d-block mx-auto" />
            </div>
            <div class="col-3">
                    <img src="@/assets/img/pay4.svg" class="w-75 d-block mx-auto" />
            </div>
            <div class="col-3 mt-4">
                <img src="@/assets/img/apy5.svg" class="w-75 d-block mx-auto" />
            </div>
            <div class="col-3 mt-4">
                <img src="@/assets/img/pay6.svg" style="width:210%;position:relative;bottom:11px;right:35px" />
            </div>
            <div class="linethrougn mt-2"></div>
        </div>

        <div class="row justify-content-center ms-1 mt-3">
            <div class="col-4 action-one">
                <h6 class="mt-1">Balance</h6>
            </div>
            <div class="col-5 action-two">
                <h6 class="mt-2 ps-4">915,042 Ks</h6>
            </div>
        </div>

        <div class="row justify-content-center mt-4">
            <div class="col-3">
                <img src="@/assets/img/pay1.svg" class="w-75 d-block mx-auto" />
            </div>
        </div>

        <div class="row justify-content-center">
            <div class="col-12">
                <div class="p-4 ">
                    <div class="p-2 px-3 pt-3 pay-box">
                        <label class="labeltext1" for="">1 .Copy phone to transfer</label>
                        <label class="labeltext1 ms-2" for="">Kpay phone number</label>
                        <input type="number" class="form-control mt-1 inputbox" />

                        <label class="labeltext1 mt-3" for="">2 .Transfer amount above Phone, Then enter amount</label>
                        <label class="labeltext1 ms-2" for="">Make sure exact amount</label>
                        <input type="number" class="form-control mt-1 mb-3 inputbox" />

                        <div class="mt-4 pb-2">
                            <button @click="handleComfirm()" class="loginbtn1 mt-2">
                                Comfirm
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-1 pb-5">
            <div class="col ">
                <h6 class="ft ms-3 ">if you have any problem, don't hesitate contact use</h6>
            </div>
            <div class="pt-1">
                <div class="d-flex justify-content-between box mt-2">
                    <div class="d-flex">
                        <img src="@/assets/img/viber.svg" />
                        <h6 class="ph ms-2 mt-2">+95998745612</h6>
                    </div>
                    <div>
                        <h6 class="condition mt-2">24/7 available</h6>
                    </div>
                </div>
            </div>
            <div class="pt-1 ">
                <div class="d-flex justify-content-between box mt-3">
                    <div class="d-flex">
                        <img src="@/assets/img/tele.svg" />
                        <h6 class="ph ms-2 mt-2">+95998745612</h6>
                    </div>
                    <div>
                        <h6 class="condition mt-2">24/7 available</h6>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
export default {
    methods: {
        handleback() {
            history.back();
        },
        handleComfirm() {
            this.$router.push({
                name:'KpayDepositComfirm'
            })
        }
    }
}
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Secular+One&display=swap');

.withdraw {
    font-family: 'Secular One', sans-serif;
    font-size: 16px;
    font-style: Regular;
}

.choose {
    font-family: 'Secular One', sans-serif;
    font-size: 16px;
    font-style: Regular;
}

.linethrougn {
    width: 89% !important;
    height: 1px;
    display: block;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.216);
}

.action-one {
    background-color: #FFB74D;
    font-size: 18px;
    font-family: 'Roboto', sans-serif;
    color: #5856D6;
    padding: 10px 10px;
    text-align: center;
    border-radius: 25px;
    position: inherit;
    left: 20px;
    z-index: 1;
}

.action-two {
    background-color: #5856D6;
    font-size: 18px;
    font-family: 'Roboto', sans-serif;
    color: white;
    padding: 5px 5px;
    height: 52px !important;
    text-align: center;
    border-radius: 0px 25px 25px 0px;
    position: relative;
    right: 20px;
}

.ft {
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    font-weight: 400;
}

.condition {
    font-size: 12px;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
}

.ph {
    font-size: 15px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
}

.labeltext1 {
    font-size: 11px;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
    color: black;
}
</style>
