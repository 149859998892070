<template>
    <div class="container">
        <div @click="handleback()" class="btns mt-4 ms-2 d-flex justify-content-between">
            <div class="d-flex">
                <img src="@/assets/img/back.svg" />
                <h6 class="withdraw ms-2 pt-2">3D Confirm</h6>
            </div>
            <img class="me-3" src="@/assets/img/mark.svg" />
        </div>

        <div class="row mt-1">
            <div class="col-12">
                <div class="d-flex justify-content-between mt-1">
                    <h6 class="comfirm-3d ms-2 mt-2">Date</h6>
                    <h6 class="comfirm-3d-text mt-2 ms-4 ps-3">Close Time : 12:00 PM</h6>
                    <div class="thai-box">
                        <h6 class="thai-text">(24-03-2023)</h6>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-12 ">
                <div class="  table-box">
                    <div class="p-3 pt-2">
                        <table class="w-100">
                            <thead class="text-center">
                                <tr>
                                    <th>No</th>
                                    <th>Odd</th>
                                    <th>Clear</th>
                                    <th>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in ThreedList" :key="index">
                                    <td>{{ item.bet_number }}</td>
                                    <td>{{ item.odd }}</td>
                                    <td><img class="pointer" @click="handledelete(item.id)" src="@/assets/img/delete.svg" />
                                    </td>
                                    <td class="" style="width:40%">
                                        <img class="pointer" @click="handleadd(item.id)" src="@/assets/img/plus.svg" />
                                        <input readonly class="action-input mx-1" :placeholder="item.amount" />
                                        <img class="pointer" @click="handleminus(item.id)" src="@/assets/img/minus.svg" />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        </div>

        <div class="row modal-box-float6 pb-4">
            <div class="col-12 pt-4">
                <div class="p-2 px-4">
                    <div class="d-flex justify-content-between">
                        <h6 class="options-text mt-2">Fill number <span class="options-text ">:</span> </h6>
                        <input required v-model="bet_number" class="action-input-1 ms-1" placeholder="---" />
                        <div style="width:38%">
                            <img class="pointer" @click="add()" src="@/assets/img/plus.svg " />
                            <input v-model="amount" class="action-input mx-1" placeholder="15,000" />
                            <img class="pointer" @click="minus()" src="@/assets/img/minus.svg" />
                        </div>
                    </div>
                    <div class="d-flex justify-content-between mt-2">
                        <h6 class="options-text mt-2 ms-4">
                            <input :disabled="disableStatus" v-model="isChecked" @change="handleCheckboxChange" type="checkbox"
                                class="me-2 mt-1 custom-checkbox1" />
                            Choose(R)
                        </h6>
                        <button @click="handlefill()" class="fill-btn me-3 btn-sm">
                            Fill
                        </button>
                    </div>
                    <div class="d-flex justify-content-between mt-3">
                        <div class="d-flex">
                            <h6 class="options-text mt-2">No </h6>
                            <span class="options-text mt-1 ms-2 ">:</span>
                            <input class="action-input-2 ms-2" :placeholder="ThreedList.length" />
                        </div>
                        <div class="d-flex pe-4">
                            <h6 class="options-text mt-2 text-black">Total </h6>
                            <span class="options-text mt-1  ">:</span>
                            <input class="action-input-3 ms-2" :placeholder="totalAmount" />
                        </div>
                        <div>
                            <button @click="handlenext()" class="bet me-3">Bet</button>
                        </div>

                    </div>
                </div>
            </div>
        </div>



    </div>
</template>
<script>
import { storeThreed, getThreed, updateThreed, deleteThreeD, storeThreedComfirm,getThreedSection } from '@/services/function.services'
export default {
    name: "depositComponent",
    data() {
        return {
            isStatus: false,
            bet_number: null,
            odd: 500,
            amount: null,
            user_id: 1,
            ThreedList: null,
            totalAmount: null,
            isChecked: false,
            displayCombinations: false,
            combinations: [],
            disableStatus: false,
        }
    },
    created() {
        this.handlegetList();
        this.handlegetThreedSection();
    },
    methods: {
        handlegetThreedSection() {
            getThreedSection().then((response) =>{
                console.log(response)
            }).catch((error) => {
                console.log(error);
            })
        },
        handleback() {
            history.back();
        },
        handleChange() {
            this.isStatus = !this.isStatus
        },
        handlegetList() {
            getThreed(this.user_id).then((response) => {
                this.ThreedList = response.data;
                this.ThreedList.forEach(i => {
                    this.totalAmount += i.amount
                })
                console.log(this.totalAmount);
            }).catch((error) => {
                console.log(error);
            })
        },
        handlenext() {
            let data = {
                user_id : this.user_id,
                data : this.ThreedList
            };
            storeThreedComfirm(data).then((response) => {
                console.log(response);
            }).catch((error) => {
                console.log(error);
            })
            // this.$router.push({
            //     name: "3DComfirmSuccess"
            // });
        },
        handlefill() {
            storeThreed(this.user_id, this.bet_number, this.odd, this.amount).then((response) => {
                this.handlegetList();
                console.log(response);
            }).catch((error) => {
                console.log(error.response.data.message);
            })
        },
        handleadd(threed_id) {
            updateThreed(this.user_id, threed_id, 'add').then((response) => {
                console.log(response)
                this.handlegetList();
            }).catch((error) => {
                console.log(error);
            })
        },
        handleminus(threed_id) {
            updateThreed(this.user_id, threed_id, 'minus').then((response) => {
                console.log(response)
                this.handlegetList();
            }).catch((error) => {
                console.log(error);
            })
        },
        handledelete(id) {
            deleteThreeD(id).then((response) => {
                console.log(response)
                this.handlegetList();
            }).catch((error) => {
                console.log(error);
            });
        },
        add() {
            this.amount = this.amount + 100;
        },
        minus() {
            this.amount = this.amount - 100;
        },
        handleCheckboxChange() {
            if (this.isChecked) {
                // Checkbox is checked
                this.displayCombinations = true;
                this.combinations = this.generateCombinations(this.bet_number);
                this.combinations.forEach(i => {
                    this.bet_number += i
                });
                this.bet_number = this.combinations.join(",");
                this.bet_number = this.bet_number.trim();
                this.disableStatus = true;
            } else {
                // Checkbox is unchecked
            }
        },
        generateCombinations(string) {
            const combinations = [];

            // Generate permutations
            this.permute(string.split(''), 0, combinations);

            return combinations;
        },
        permute(array, startIndex, combinations) {
            if (startIndex === array.length - 1) {
                combinations.push(array.join(''));
                return;
            }

            for (let i = startIndex; i < array.length; i++) {
                [array[startIndex], array[i]] = [array[i], array[startIndex]];
                this.permute(array, startIndex + 1, combinations);
                [array[startIndex], array[i]] = [array[i], array[startIndex]]; // backtrack
            }
        },
    }
}
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Secular+One&display=swap');

.action-2d-one {
    font-family: 'Secular One', sans-serif;
    font-size: 17px;
    font-weight: 400;
    color: #7210FF;
}

.action-3d-one {
    font-family: 'Secular One', sans-serif;
    font-size: 17px;
    font-weight: 400;
    color: #000;
}

.header-line {
    height: 48px;
    border-bottom: 2px solid #7410ffa5;
    font-family: 'Secular One', sans-serif;
    font-size: 17px;
    font-weight: 400;
}

.body-line {
    height: 48px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.189);
    font-family: 'Secular One', sans-serif;
    font-size: 16px;
    font-weight: 400;
}

.comfirm-3d {
    font-family: 'Secular One', sans-serif;
    font-size: 16px;
    font-weight: 400;
}

.comfirm-3d-text {
    font-family: 'Secular One', sans-serif;
    font-size: 11px;
    font-weight: 400;
    color: #EA2839;
}

.action-input {
    width: 50% !important;
    border-radius: 10px;
    border: 2px solid #7210FF;
}

.action-input-1 {
    width: 20% !important;
    height: 10%;
    border-radius: 10px;
    border: 2px solid #7210FF;
}

.action-input-2 {
    width: 40% !important;
    height: 85%;
    border-radius: 10px;
    border: 2px solid #7210FF;
}

.action-input-3 {
    width: 100% !important;
    height: 85%;
    border-radius: 10px;
    border: 2px solid #7210FF;
}

.action-input-3::placeholder {
    font-family: 'Secular One', sans-serif;
    padding-left: 3px;
    font-size: 15px;
    font-weight: 400;
    color: #7210FF;
    text-align: center;
}

.bet {
    font-family: 'Secular One', sans-serif;
    font-size: 18px;
    font-weight: 400;
    padding: 4px 20px;
    color: #7210FF;
    background: #FF9800;
    border: 0;
    border-radius: 10px;
    text-align: center;
}

.action-input-2::placeholder {
    font-family: 'Secular One', sans-serif;
    padding-left: 3px;
    font-size: 17px;
    font-weight: 400;
    color: #7210FF;
    text-align: center;
}

.action-input-1::placeholder {
    font-family: 'Secular One', sans-serif;
    padding-left: 3px;
    font-size: 15px;
    font-weight: 400;
    color: #7210FF;
    text-align: center;
}

.action-input::placeholder {
    font-family: 'Secular One', sans-serif;
    padding-left: 3px;
    font-size: 15px;
    font-weight: 400;
    color: #7210FF;
}

/* .custom-checkbox {
 -moz-appearance:none;
	-webkit-appearance:none;
	-o-appearance:none;
	outline: none;
	content: none;	
} */
.custom-checkbox:before {
    font-family: "FontAwesome";
    content: "\f00c";
    font-size: 15px;
    color: transparent !important;
    display: block;
    width: 17px;
    height: 17px;
    border: 2px solid #7210FF;
    margin-right: 7px;
}

.fill-btn {
    font-family: 'Secular One', sans-serif;
    padding-left: 3px;
    font-size: 18px;
    font-weight: 400;
    background: #7210FF;
    border: 0;
    color: white;
    padding: 1px 40px;
    border-radius: 10px;
}

.modal-box-float1 {
    position: fixed;
    width: 110%;
    bottom: 0;
    border: 1px solid black;
    border-radius: 65px 110px 0px 0px;
    border: 2px solid #7210FF;
    background: white;
}

.modal-box-float6 {
    position: fixed;
    width: 110%;
    bottom: 0;
    border: 1px solid black;
    border-radius: 65px 110px 0px 0px;
    border: 2px solid #7210FF;
    background: white;
}

.pointer {
    cursor: pointer;
}
.custom-checkbox1{
   transform: scale(1.4);
   margin: 30px;
   outline: 2px solid #7210FF !important;
}

</style>
