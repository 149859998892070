<template>
  <div class="container">
    <div
      @click="handleback()"
      class="btns mt-4 ms-2 d-flex justify-content-between"
    >
      <div class="d-flex">
        <img src="@/assets/img/back.svg" />
        <h6 class="withdraw ms-2 pt-2">2D/3D Calendar</h6>
      </div>
      <img class="me-3" src="@/assets/img/mark.svg" />
    </div>

    <div class="row mt-4 mb-1">
      <div @click="handleChange()" class="col-6">
        <h6
          :class="
            isStatus
              ? `text-center action-3d1-one`
              : `text-center action-2d1-one`
          "
        >
          2D Calendar
        </h6>
        <div v-if="!isStatus" class="line-bet"></div>
      </div>
      <div @click="handleChange()" class="col-6">
        <h6
          :class="
            isStatus
              ? `text-center action-2d1-one`
              : `text-center action-3d1-one`
          "
        >
          3D Calendar
        </h6>
        <div v-if="isStatus" class="line-bet"></div>
      </div>
    </div>

    <div v-if="!isStatus" class="row mt-4">
      <div class="col-12">
        <div class="d-flex justify-content-center">
          <button class="border-0 bg-white" @click="goToPrevMonth">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              viewBox="0 0 30 30"
              fill="none"
            >
              <path
                d="M18.9063 26.375L8.37502 15.875C8.25002 15.75 8.16127 15.6146 8.10877 15.4688C8.05627 15.3229 8.03044 15.1667 8.03127 15C8.03127 14.8333 8.0571 14.6771 8.10877 14.5313C8.16044 14.3854 8.24919 14.25 8.37502 14.125L18.9063 3.59375C19.1979 3.30208 19.5625 3.15625 20 3.15625C20.4375 3.15625 20.8125 3.3125 21.125 3.625C21.4375 3.9375 21.5938 4.30208 21.5938 4.71875C21.5938 5.13542 21.4375 5.5 21.125 5.8125L11.9375 15L21.125 24.1875C21.4167 24.4792 21.5625 24.8387 21.5625 25.2662C21.5625 25.6937 21.4063 26.0633 21.0938 26.375C20.7813 26.6875 20.4167 26.8438 20 26.8438C19.5834 26.8438 19.2188 26.6875 18.9063 26.375Z"
                fill="#7210FF"
              />
              <path
                xmlns="http://www.w3.org/2000/svg"
                d="M18.9063 26.375L8.37502 15.875C8.25002 15.75 8.16127 15.6146 8.10877 15.4688C8.05627 15.3229 8.03044 15.1667 8.03127 15C8.03127 14.8333 8.0571 14.6771 8.10877 14.5313C8.16044 14.3854 8.24919 14.25 8.37502 14.125L18.9063 3.59375C19.1979 3.30208 19.5625 3.15625 20 3.15625C20.4375 3.15625 20.8125 3.3125 21.125 3.625C21.4375 3.9375 21.5938 4.30208 21.5938 4.71875C21.5938 5.13542 21.4375 5.5 21.125 5.8125L11.9375 15L21.125 24.1875C21.4167 24.4792 21.5625 24.8387 21.5625 25.2662C21.5625 25.6937 21.4063 26.0633 21.0938 26.375C20.7813 26.6875 20.4167 26.8438 20 26.8438C19.5834 26.8438 19.2188 26.6875 18.9063 26.375Z"
                fill="#7210FF"
              />
            </svg>
          </button>
          <h6 class="calendar-text mt-2 mx-3 text-center" style="width: 150px;">{{ months[selectedMonth] }} 2023</h6>
          <button class="border-0 bg-white" @click="goToNextMonth">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              viewBox="0 0 30 30"
              fill="none"
            >
              <path
                d="M8.9375 26.375C8.625 26.0625 8.46875 25.6925 8.46875 25.265C8.46875 24.8375 8.625 24.4679 8.9375 24.1562L18.0938 15L8.90625 5.8125C8.61458 5.52083 8.46875 5.15625 8.46875 4.71875C8.46875 4.28125 8.625 3.90625 8.9375 3.59375C9.25 3.28125 9.62 3.125 10.0475 3.125C10.475 3.125 10.8446 3.28125 11.1562 3.59375L21.6563 14.125C21.7813 14.25 21.87 14.3854 21.9225 14.5312C21.975 14.6771 22.0008 14.8333 22 15C22 15.1667 21.9738 15.3229 21.9213 15.4688C21.8688 15.6146 21.7804 15.75 21.6563 15.875L11.125 26.4062C10.8333 26.6979 10.4737 26.8438 10.0462 26.8438C9.61875 26.8438 9.24917 26.6875 8.9375 26.375Z"
                fill="#7210FF"
              />

              <path
                xmlns="http://www.w3.org/2000/svg"
                d="M8.9375 26.375C8.625 26.0625 8.46875 25.6925 8.46875 25.265C8.46875 24.8375 8.625 24.4679 8.9375 24.1562L18.0938 15L8.90625 5.8125C8.61458 5.52083 8.46875 5.15625 8.46875 4.71875C8.46875 4.28125 8.625 3.90625 8.9375 3.59375C9.25 3.28125 9.62 3.125 10.0475 3.125C10.475 3.125 10.8446 3.28125 11.1562 3.59375L21.6563 14.125C21.7813 14.25 21.87 14.3854 21.9225 14.5312C21.975 14.6771 22.0008 14.8333 22 15C22 15.1667 21.9738 15.3229 21.9213 15.4688C21.8688 15.6146 21.7804 15.75 21.6563 15.875L11.125 26.4062C10.8333 26.6979 10.4737 26.8438 10.0462 26.8438C9.61875 26.8438 9.24917 26.6875 8.9375 26.375Z"
                fill="#7210FF"
              />
            </svg>
          </button>
        </div>
      </div>
      <div class="d-flex" style="width: 100%">
        <div class="" style="width: 20%">
          <h6 class="text-center calender-header">Mon</h6>
        </div>
        <div style="width: 20%">
          <h6 class="text-center calender-header">Tue</h6>
        </div>
        <div style="width: 20%">
          <h6 class="text-center calender-header">Wed</h6>
        </div>
        <div style="width: 20%">
          <h6 class="text-center calender-header">Thu</h6>
        </div>
        <div style="width: 20%">
          <h6 class="text-center calender-header">Fri</h6>
        </div>
      </div>
      <div class="d-flex flex-wrap gap-2" style="width: 100%">
        <div @click="handleshow()" class="calendar-box" v-for="(twod,index) in this.twodData" :key="index">
          <div class="">
            <h6 class="text-small text-center mb-0 pt-1">{{ twod.day }}</h6>
            <h6 class="mb-0 text-c text-center" v-if="twod.day === twod[index].day">{{ twod.winning_number }}</h6>
            <h6 class="text-c mb-0 text-center pb-1">{{ twod.winning_number }}</h6>
          </div>
        </div>
      </div>
    </div>

    <div v-if="isStatus" class="row mt-4">
      <div class="col-12">
        <div class="d-flex justify-content-center">
          <button class="border-0 bg-white">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              viewBox="0 0 30 30"
              fill="none"
            >
              <path
                d="M18.9063 26.375L8.37502 15.875C8.25002 15.75 8.16127 15.6146 8.10877 15.4688C8.05627 15.3229 8.03044 15.1667 8.03127 15C8.03127 14.8333 8.0571 14.6771 8.10877 14.5313C8.16044 14.3854 8.24919 14.25 8.37502 14.125L18.9063 3.59375C19.1979 3.30208 19.5625 3.15625 20 3.15625C20.4375 3.15625 20.8125 3.3125 21.125 3.625C21.4375 3.9375 21.5938 4.30208 21.5938 4.71875C21.5938 5.13542 21.4375 5.5 21.125 5.8125L11.9375 15L21.125 24.1875C21.4167 24.4792 21.5625 24.8387 21.5625 25.2662C21.5625 25.6937 21.4063 26.0633 21.0938 26.375C20.7813 26.6875 20.4167 26.8438 20 26.8438C19.5834 26.8438 19.2188 26.6875 18.9063 26.375Z"
                fill="#7210FF"
              />
              <path
                xmlns="http://www.w3.org/2000/svg"
                d="M18.9063 26.375L8.37502 15.875C8.25002 15.75 8.16127 15.6146 8.10877 15.4688C8.05627 15.3229 8.03044 15.1667 8.03127 15C8.03127 14.8333 8.0571 14.6771 8.10877 14.5313C8.16044 14.3854 8.24919 14.25 8.37502 14.125L18.9063 3.59375C19.1979 3.30208 19.5625 3.15625 20 3.15625C20.4375 3.15625 20.8125 3.3125 21.125 3.625C21.4375 3.9375 21.5938 4.30208 21.5938 4.71875C21.5938 5.13542 21.4375 5.5 21.125 5.8125L11.9375 15L21.125 24.1875C21.4167 24.4792 21.5625 24.8387 21.5625 25.2662C21.5625 25.6937 21.4063 26.0633 21.0938 26.375C20.7813 26.6875 20.4167 26.8438 20 26.8438C19.5834 26.8438 19.2188 26.6875 18.9063 26.375Z"
                fill="#7210FF"
              />
            </svg>
          </button>
          <h6 class="calendar-text mt-2 mx-3">2023</h6>
          <button class="border-0 bg-white">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              viewBox="0 0 30 30"
              fill="none"
            >
              <path
                d="M8.9375 26.375C8.625 26.0625 8.46875 25.6925 8.46875 25.265C8.46875 24.8375 8.625 24.4679 8.9375 24.1562L18.0938 15L8.90625 5.8125C8.61458 5.52083 8.46875 5.15625 8.46875 4.71875C8.46875 4.28125 8.625 3.90625 8.9375 3.59375C9.25 3.28125 9.62 3.125 10.0475 3.125C10.475 3.125 10.8446 3.28125 11.1562 3.59375L21.6563 14.125C21.7813 14.25 21.87 14.3854 21.9225 14.5312C21.975 14.6771 22.0008 14.8333 22 15C22 15.1667 21.9738 15.3229 21.9213 15.4688C21.8688 15.6146 21.7804 15.75 21.6563 15.875L11.125 26.4062C10.8333 26.6979 10.4737 26.8438 10.0462 26.8438C9.61875 26.8438 9.24917 26.6875 8.9375 26.375Z"
                fill="#7210FF"
              />

              <path
                xmlns="http://www.w3.org/2000/svg"
                d="M8.9375 26.375C8.625 26.0625 8.46875 25.6925 8.46875 25.265C8.46875 24.8375 8.625 24.4679 8.9375 24.1562L18.0938 15L8.90625 5.8125C8.61458 5.52083 8.46875 5.15625 8.46875 4.71875C8.46875 4.28125 8.625 3.90625 8.9375 3.59375C9.25 3.28125 9.62 3.125 10.0475 3.125C10.475 3.125 10.8446 3.28125 11.1562 3.59375L21.6563 14.125C21.7813 14.25 21.87 14.3854 21.9225 14.5312C21.975 14.6771 22.0008 14.8333 22 15C22 15.1667 21.9738 15.3229 21.9213 15.4688C21.8688 15.6146 21.7804 15.75 21.6563 15.875L11.125 26.4062C10.8333 26.6979 10.4737 26.8438 10.0462 26.8438C9.61875 26.8438 9.24917 26.6875 8.9375 26.375Z"
                fill="#7210FF"
              />
            </svg>
          </button>
        </div>
      </div>
      <div class="d-flex flex-wrap gap-2 mt-4" style="width: 100%">
        <div class="calendar-box-3d">
          <div class="">
            <h6 class="text-small text-center mb-0 pt-3">JAN</h6>
            <h6 class="mb-0 text-c text-center">431</h6>
            <h6 class="text-c mb-0 text-center pb-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
              >
                <path
                  d="M10.6667 31.6663L8.33337 29.333L17.6667 19.9997L8.33337 10.6663L10.6667 8.33301L20 17.6663L29.3334 8.33301L31.6667 10.6663L22.3334 19.9997L31.6667 29.333L29.3334 31.6663L20 22.333L10.6667 31.6663Z"
                  fill="#EA2839"
                />

                <path
                  xmlns="http://www.w3.org/2000/svg"
                  d="M10.6667 31.6663L8.33337 29.333L17.6667 19.9997L8.33337 10.6663L10.6667 8.33301L20 17.6663L29.3334 8.33301L31.6667 10.6663L22.3334 19.9997L31.6667 29.333L29.3334 31.6663L20 22.333L10.6667 31.6663Z"
                  fill="#EA2839"
                />
              </svg>
            </h6>
          </div>
        </div>
        <div class="calendar-box-3d">
          <div class="">
            <h6 class="text-small text-center mb-0 pt-3">FEB</h6>
            <h6 class="mb-0 text-c text-center">322</h6>
            <h6 class="text-c mb-0 text-center pb-2">534</h6>
          </div>
        </div>
        <div class="calendar-box-3d">
          <div class="">
            <h6 class="text-small text-center mb-0 pt-3">MAR</h6>
            <h6 class="mb-0 text-c text-center">713</h6>
            <h6 class="text-c mb-0 text-center pb-2">341</h6>
          </div>
        </div>
        <div class="calendar-box-3d">
          <div class="">
            <h6 class="text-small text-center mb-0 pt-3">APR</h6>
            <h6 class="mb-0 text-c text-center">831</h6>
            <h6 class="text-c mb-0 text-center pb-2">783</h6>
          </div>
        </div>
        <div class="calendar-box-3d">
          <div class="">
            <h6 class="text-small text-center mb-0 pt-3">MAY</h6>
            <h6 class="mb-0 text-c text-center">431</h6>
            <h6 class="text-c mb-0 text-center pb-2">318</h6>
          </div>
        </div>
        <div class="calendar-box-3d">
          <div class="">
            <h6 class="text-small text-center mb-0 pt-3">JUN</h6>
            <h6 class="mb-0 text-c text-center">322</h6>
            <h6 class="text-c mb-0 text-center pb-2">534</h6>
          </div>
        </div>
        <div class="calendar-box-3d">
          <div class="">
            <h6 class="text-small text-center mb-0 pt-3">JUL</h6>
            <h6 class="mb-0 text-c text-center">731</h6>
            <h6 class="text-c mb-0 text-center pb-2">341</h6>
          </div>
        </div>
        <div class="calendar-box-3d">
          <div class="">
            <h6 class="text-small text-center mb-0 pt-3">AUG</h6>
            <h6 class="mb-0 text-c text-center">831</h6>
            <h6 class="text-c mb-0 text-center pb-2">783</h6>
          </div>
        </div>
        <div class="calendar-box-3d">
          <div class="">
            <h6 class="text-small text-center mb-0 pt-3">SEP</h6>
            <h6 class="mb-0 text-c text-center">431</h6>
            <h6 class="text-c mb-0 text-center pb-2">318</h6>
          </div>
        </div>
        <div class="calendar-box-3d">
          <div class="">
            <h6 class="text-small text-center mb-0 pt-3">OCT</h6>
            <h6 class="mb-0 text-c text-center">322</h6>
            <h6 class="text-c mb-0 text-center pb-2">534</h6>
          </div>
        </div>
        <div class="calendar-box-3d">
          <div class="">
            <h6 class="text-small text-center mb-0 pt-3">NOV</h6>
            <h6 class="mb-0 text-c text-center">713</h6>
            <h6 class="text-c mb-0 text-center pb-2">341</h6>
          </div>
        </div>
        <div class="calendar-box-3d">
          <div class="">
            <h6 class="text-small text-center mb-0 pt-3">DEC</h6>
            <h6 class="mb-0 text-c text-center">831</h6>
            <h6 class="text-c mb-0 text-center">783</h6>
            <h6 class="text-c mb-0 text-center pb-2">233</h6>
          </div>
        </div>
      </div>
    </div>

    <div v-if="modelbox" class="ttmodelbox">
      <div class="d-flex py-4">
        <h6 class="d-block mx-auto text-only">Wed, 8 Mar, 2023</h6>
        <button @click="handleshow()" class="bg-white border-0">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            fill="currentColor"
            class="bi bi-x-circle"
            style="color: #7210ff"
            viewBox="0 0 16 16"
          >
            <path
              d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
            />
            <path
              d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
            />
          </svg>
        </button>
      </div>
      <div class="w-100 d-flex justify-content-center gap-5">
        <div class="calendar-box-mini mb-3">
          <div class="calendar-box-mini-text-one">
            <h6 class="pt-3">12:01 PM</h6>
          </div>
          <div class="calendar-box-mini-text-two-box">
            <h6 class="calendar-box-mini-text-two mb-0 py-2">58</h6>
          </div>
        </div>
        <div class="calendar-box-mini mb-3">
          <div class="calendar-box-mini-text-one">
            <h6 class="pt-3">04:30 PM</h6>
          </div>
          <div class="calendar-box-mini-text-two-box">
            <h6 class="calendar-box-mini-text-two mb-0 py-2">27</h6>
          </div>
        </div>
      </div>
      <div class="w-100 d-flex justify-content-center mb-4 gap-1 pt-4">
        <div class="box-one">
          <h6 class="text-center text-only">09:30 AM</h6>
          <div class="bottom-box">
            <h6 class="mb-0 text-center text-only-one pt-2">43</h6>
            <h6 class="text-center text-only-one pb-2">95</h6>
          </div>
        </div>
        <div class="box-one">
          <h6 class="text-center text-only pt-5">Modern</h6>
          <h6 class="text-center text-only pt-2">Internet</h6>
        </div>
        <div class="box-one">
          <h6 class="text-center text-only">02:00PM</h6>
          <div class="bottom-box">
            <h6 class="mb-0 text-center text-only-one pt-2">31</h6>
            <h6 class="text-center text-only-one pb-2">80</h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "depositComponent",
  data() {
    return {
      isStatus: false,
      modelbox: false,
      selectedMonth: 7,
      selectedYear: 2023,
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      twodData: [],
    };
  },
  methods: {
    handleback() {
      history.back();
    },
    handleChange() {
      this.isStatus = !this.isStatus;
    },
    handleshow() {
      this.modelbox = !this.modelbox;
    },
    goToPrevMonth() {
      if (this.selectedMonth > 0) {
        this.selectedMonth--;
      }
    },
    goToNextMonth() {
      if (this.selectedMonth < this.months.length - 1) {
        this.selectedMonth++;
      }
    },
    getTwodCalendarData(){
        // userService.getTwodCalendar(this.months[this.selectedMonth],this.year).then(({data})=>{
        //     this.twodData = data.data;
        // })
    }
  },
  created(){
    this.getTwodCalendarData();
  }
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Secular+One&display=swap");

.action-2d1-one {
  font-family: "Secular One", sans-serif;
  font-size: 17px;
  font-weight: 400;
  color: red;
}

.action-3d1-one {
  font-family: "Secular One", sans-serif;
  font-size: 17px;
  font-weight: 400;
  color: #000;
}

.header-line {
  height: 48px;
  border-bottom: 2px solid #7410ffa5;
  font-family: "Secular One", sans-serif;
  font-size: 17px;
  font-weight: 400;
}

.body-line {
  height: 48px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.189);
  font-family: "Secular One", sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.calendar-text {
  font-family: "Secular One", sans-serif;
  font-size: 17px;
  font-weight: 400;
  color: #7210ff;
}

.calender-header {
  font-family: "Secular One", sans-serif;
  font-size: 18px;
  font-weight: 400;
}

.text-small {
  font-family: "Secular One", sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: #ff9800;
}

.text-c {
  font-family: "Secular One", sans-serif;
  font-size: 22px;
  font-weight: 400;
  color: white;
}

.calendar-box {
  background: #7210ff;
  width: 18%;
  border-radius: 20px;
}

.calendar-box-3d {
  background: #7210ff;
  width: 23%;
  border-radius: 20px;
}

.ttmodelbox {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  background: white;
  border-radius: 20px;
}

.calendar-box-mini {
  width: 33%;
  border: 1px solid #5856d6;
  border-radius: 25px;
  overflow: hidden;
}

.calendar-box-mini-text-one {
  font-family: "Secular One", sans-serif;
  font-size: 22px;
  text-align: center;
}

.calendar-box-mini-text-two {
  font-family: "Secular One", sans-serif;
  font-size: 50px;
  color: white;
  text-align: center;
}

.calendar-box-mini-text-two-box {
  background: #5856d6;
}

.box-one {
  width: 26%;
}

.text-only {
  font-family: "Secular One", sans-serif;
  font-size: 15px;
}

.bottom-box {
  background: #5856d6;
  border-radius: 20px;
}

.text-only-one {
  color: white;
  font-size: 25px;
  font-family: "Secular One", sans-serif;
}
</style>
