<template>
    <div class="container">
        <div @click="handleback()" class="btns mt-4 ms-2">
            <img src="@/assets/img/back.svg" />
        </div>

        <div class="row justify-content-center mt-5">
            <div class="col-8">
                <img src="@/assets/img/logo.jpg" class="w-100" />
            </div>
        </div>

        <div class="row justify-content-center mt-5 " >
            <div @click="handlechange()" class="col-5 login_btn" :class="status ? 'active' : ''">
                <h6 class="action-btn mt-1">Login Account</h6>
            </div>
            <div @click="handlechange()" class="col-5 register_btn"  :class="status ? '' : 'active'">
                <h6 class="action-btn mt-1">Register Account</h6>
            </div>
        </div>

        <div v-if="status" class="container">
            <div class="row justify-content-center">
                <div class="col-12">    
                    <div class="mt-5 pt-3">
                        <input v-model="phone" type="number" class="phonefield "  placeholder="Phone"/>
                    </div>
                    <div class="mt-4 pt-3">
                        <input v-model="password" type="number" class="phonefield" placeholder="Password Pin" />
                    </div>
                    <div class="d-flex ms-2 mt-4">
                        <div>
                            <img src="@/assets/img/GetCaptcha.jpg" />
                        </div>
                        <div>
                            <input v-model="security_code" type="number" class="security" placeholder="Security Code" />
                        </div>
                    </div>
                    <div class="d-flex justify-content-between mt-3">
                        <div class="d-flex ms-2">
                            <input type="checkbox" />
                            <h6 class="forget pt-2 ms-2">Remember Me</h6>
                        </div>
                        <div>
                            <h6 class="forget mt-2">Forget Password?</h6>
                        </div>
                    </div>
                    <div>
                        <button @click="handleLogin()" class="loginbtn mt-3">
                            Login
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!status" class="container">
            <div class="row justify-content-center">
                <div class="col-12">
                    <div class="mt-5">
                        <input v-model="name" type="text" class="phonefield " placeholder="Name" />
                    </div>
                    <div class="mt-4">
                        <input v-model="phone" type="number" class="phonefield " placeholder="Phone Number"/>
                    </div>
                    <div class="mt-4">
                        <input v-model="password" type="number" class="phonefield" placeholder="Password Pin" />
                    </div>
                    <div class="mt-4">
                        <input v-model="referal_code" type="text" class="phonefield" placeholder="Referral Code" />
                    </div>
                    <div class="d-block ms-4">
                        <div class="d-flex  mt-4 justify-content-center">
                            <div>
                                <img src="@/assets/img/GetCaptcha.jpg" />
                            </div>
                            <div>
                                <input v-model="security_code" type="number" class="security" placeholder="Security Code" />
                            </div>
                        </div>
                    </div>
                    <div>
                        <button @click="handleModel()" class="loginbtn mt-4  mb-5 my_btn ripple">
                            Register
                        </button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import { register,login } from '../services/Auth-Services';
import Swal from 'sweetalert2'
export default {
    data() {
        return {
            name: "",
            phone: "",
            password: "",
            security_code: "",
            referal_code: "",
            status: false,
            errors: {
                phone: []
            }
        }
    },
    created() {
        // if (this.$store.state.auth.status.loggedIn) {
        //     this.$router.push({ name: 'home' });
        // } else {
        //     this.status = true; // Set status to true for the login component
        // }
    },
    methods: {
        handlechange() {
            this.status = !this.status;
        },
        handleback() {
            history.back();
        },
        handleModel() {
            const name = this.name;
            const phone = this.phone;
            const password = this.password;
            const referralCode = this.referal_code;
            const securityCode = this.security_code;
            this.$store.dispatch('loading')
            register(name, phone, password, referralCode, securityCode)
                .then((response) => {
                    localStorage.setItem('token', response.data.token)
                    this.$store.dispatch('loadingComplete')
                    if (response.status === 200) {
                        Swal.fire({
                            icon: 'success',
                            html: `
                            <div class="congrat">
                               Congratulation
                            </div>
                            <div class="registered">
                                Now you are registered
                            </div>
                             <div class="ready">
                               Get ready to start betting
                            </div>
                        `,
                            width: "89%",
                            customClass: {
                                icon: 'my-icon-class',
                                popup: 'my-popup-class',
                                confirmButton: 'my-button-class',
                            },
                            confirmButtonText: 'Start Now',
                        }).then(() => {
                            this.$router.push({ name: 'home' });
                        })

                    }
                })
                .catch((error) => {
                    console.log(error);
                   this.$store.dispatch('showError','Please Fill Data')
                });
        },
        handleLogin() {
            this.$store.dispatch('loading')
            login(this.phone,this.password,this.security_code)
                .then((response)=>{
                    localStorage.setItem('token', response.data.token)
                    this.$store.dispatch('loadingComplete')
                    if (response.status === 200) {
                        this.$store.dispatch('showToast', 'Login Successful!')
                        this.$router.push({ name: 'home' })
                    }
                }).catch(({ response }) => {
                console.log(response)
                if (response.status == 422) {
                    this.errors = response.data.errors
                }
            })
           
        }
    }
}
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

.btns {
    background: white;
    border: 0;
}

.action-btn {
    font-size: 15px;
    font-style: bold;
    /* line-height: 18px; */
    font-family: Roboto;
}


.login_btn {
    background: #D9D9D9;
    color: #964DFF;
    padding: 6px;
    text-align: center;
    border: 1px solid #00000032;
    border-radius: 5px 0px 0px 5px;
}

.register_btn {
    background: #D9D9D9;
    color: #964DFF;
    padding: 6px;
    text-align: center;
    border: 1px solid #00000032;
    border-radius: 0px 5px 5px 0px;
}
.active {
    background: #964DFF;
    color: white;
    transform: scale(1.05);
    border-radius: 8px;
    box-shadow: 3px 3px 10px #888;
}



.phonefield {
    width: 96%;
    padding: 5px 15px;
    display: block;
    margin: auto;
    background-color: #ECECEC;
    border: 0;
    border-radius: 5px;
    height: 46px;
}

input:focus {
    outline: #964DFF solid 1px !important;
    transition: .3s ease;
}

.phonefield::placeholder {
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    padding-left: 10px;
    color: #858585;
    font-family: 'Roboto', sans-serif;
}

.security {
    width: 70%;
    display: block;
    margin: auto;
    background-color: #d9d9d989;
    border: 0;
    border-radius: 5px;
    height: 40px;
}

.security::placeholder {
    font-size: 12px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    line-height: 18px;
    padding-left: 10px;
    color: rgba(0, 0, 0, 0.281);
}

.forget {
    font-size: 13px;
    line-height: 15px;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
}

.loginbtn {
    font-family: 'Roboto', sans-serif;
    font-size: 17px;
    font-weight: bold;
    color: white;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 70px;
    padding-right: 70px;
    background-color: #7210FF;
    border: 0;
    display: block;
    margin: auto;
    border-radius: 25px;
}

.my-icon-class {
    font-size: 10px;
    margin-top: 50px;
}

.my-popup-class {
    border-radius: 20px;
    /* Adjust the border radius as needed */
}

.congrat {
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    font-style: bold;
    color: #964DFF;
    font-weight: bold;
}

.registered {
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-style: bold;
    color: #000;
    margin-top: 14px;
    font-weight: 700;
}

.ready {
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    font-style: bold;
    color: #ACACAC;
    margin-top: 10px;
    font-weight: bold;
}

.swal2-confirm.my-button-class {
    font-family: 'Roboto', sans-serif;
    font-size: 17px;
    font-weight: bold;
    color: white;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #7210FF;
    border: 0;
    width: 170px;
    display: block;
    margin: auto;
    border-radius: 25px;
    margin-bottom: 20px;
    margin-top: 30px;
}

.my_btn {
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    transform: translateY(0);
}

.my_btn.ripple {
    background-position: center;
    transition: background 0.8s;
}

.my_btn.ripple:hover {
    background: #7210FF radial-gradient(circle, transparent 1%, #7210FF 1%) 50%/16000%;
}

.my_btn.ripple:active {
    background-color: #ffffff80;
    background-size: 100%;
    transition: background 0s, transform 0.2s;
    transform: translateY(1px);
}
</style>
