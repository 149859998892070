<template>
    <div class="container">
        <div @click="handleback()" class="btns mt-4 ms-2 d-flex">
            <img src="@/assets/img/back.svg" />
            <h6 class="withdraw ms-2 pt-2">Thai Lottery</h6>
        </div>

        <div class="row justify-content-center">
            <div @click="handlenext(item.slip_id,item.slip_number)" class="col-12" v-for="(item, index) in ListData" :key="index">
                <div class="mt-3 bet-box">
                    <div class="p-3 px-4">
                        <div class="d-flex justify-content-between">
                            <h6 class="slipnumber mt-2">Slip : #{{ item.slip_number }}</h6>
                            <div class="active-box mb-2">
                                <h6 class="active-text text-capitalize">{{ item.status }}</h6>
                            </div>
                        </div>
                        <div class="d-flex">
                            <div class="active-box1 mt-2">
                                <h6 class="active-text1">1Baht : 70Ks</h6>
                            </div>
                        </div>
                        <div class="line-bett mt-2"></div>
                        <div class="d-flex justify-content-between mt-1">
                            <h6 class="slipnumber mt-2">Tickets : {{ item.data.length }}</h6>
                            <h6 class="slipnumber mt-2">Time : {{ formatDateTime(item.bet_date) }}</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ThaiDcomfirmList, ThaiDDetails } from '../services/Auth-Services';

export default {
    data() {
        return {
            isStatus: false,
            ListData: null,
        };
    },
    created() {
        this.handlegetdata();
    },
    methods: {
        handleback() {
            history.back();
        },
        handleChange() {
            this.isStatus = !this.isStatus;
        },
        handlegetdata() {
            ThaiDcomfirmList()
                .then((response) => {
                    this.ListData = response.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        handlenext(slip_id,slip_number) {
            ThaiDDetails(slip_id)
                .then((response) => {
                    console.log(response);
                    this.$router.push({
                        name: 'thailandlotteryhistory',
                        params: {
                            slip_id: slip_id,
                            slip_number:slip_number,
                        },
                    });
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        formatDateTime(dateString) {
            const dateObj = new Date(dateString);
            const options = { month: 'short', day: 'numeric', year: 'numeric' };
            return dateObj.toLocaleDateString('en-US', options);
        },
    },
};
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Secular+One&display=swap');

.action-2d {
    font-family: 'Secular One', sans-serif;
    font-size: 21px;
    font-weight: 400;
    color: #7210FF;
}

.action-3d {
    font-family: 'Secular One', sans-serif;
    font-size: 21px;
    font-weight: 400;
    color: #546E7A;
}

.line-bet {
    width: 50%;
    height: 2px;
    background: #7210FF;
    display: block;
    margin: auto;
}

.slipnumber {
    font-family: 'Secular One', sans-serif;
    font-size: 15px;
    font-weight: 400;
}

.active-text {
    font-family: 'Secular One', sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: #279958;
    padding-top: 6px;
}

.active-text1 {
    font-family: 'Secular One', sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: #EB73ED;
    padding-top: 6px;
}

.active-text2 {
    font-family: 'Secular One', sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: #F69945;
    padding-top: 6px;
}

.active-text4 {
    font-family: 'Secular One', sans-serif;
    font-size: 13px;
    font-weight: 400;
    color: #F69945;
    padding-top: 6px;
}

.active-text3 {
    font-family: 'Secular One', sans-serif;
    font-size: 13px;
    font-weight: 400;
    color: #EB73ED;
    padding-top: 6px;
}


.active-box {
    background: #E0EBE5;
    padding: 5px 20px;
    border-radius: 17px;
}

.active-box1 {
    background: #F6E9F6;
    padding: 5px 20px;
    border-radius: 17px;
}

.active-box2 {
    background: #F5ECE4;
    padding: 5px 20px;
    border-radius: 17px;
}

.line-bett {
    width: 97%;
    height: 1px;
    background: rgba(0, 0, 0, 0.38);
}

.bet-box {
    border: 1px solid #5856D6;
    border-radius: 17px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
</style>
