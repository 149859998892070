export const Data = {
    state: {
        data: null,
    },
    getters: {
        getdata: state => state.data,
    },
    mutations: {
        setdata: (state, value) => {
            state.data = value;
        },
    },
    actions: {
        setdata: ({ commit }, value) => {
            commit('setdata', value);
        },
    },
};