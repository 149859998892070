<template>
    <div class="">
        <div class="container position-relative">
            <div @click="handleback()" class="btns mt-4 ms-2 d-flex">
                <img src="@/assets/img/back.svg" />
                <h6 class="withdraw ms-2 pt-2">Slip : #51A4V6T0L2</h6>
            </div>

            <div class="row mt-4">
                <div class="col-12 ">
                    <div class=" p-4 pt-0 " style="background: #F4ECFF;
    border-radius: 10px;
    min-height: 600px;margin-bottom: 30vh !important;">
                        <div class="p-3 pt-2">
                            <table> 
                                <thead class="text-center">
                                    <tr>
                                        <th>No</th>
                                        <th>Amount</th>
                                        <th>Rewards</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item,index) in this.betlog?.bet_logs" :key="index">
                                        <td>{{ item.bet_number }}</td>
                                        <td>{{ item.bet_amount }}</td>
                                        <td>{{ item.reward_amount }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="row modal-box-float">
            <div class="col-12 pt-4">
                <div class="p-2 px-4">
                    <div class="d-flex justify-content-between">
                        <div class="d-flex justify-content-between" style="width: 40%;">
                            <h6 class="options-text">Status </h6>
                            <span class="options-text">:</span>
                        </div>
                        <h6 class="status-text"  v-if="!this.betlog.section?.ended">Active</h6>
                        <h6 class="status-text"  v-else style="color: red;">Finish</h6>
                    </div>
                    <div class="d-flex justify-content-between mt-1">
                        <div class="d-flex justify-content-between" style="width: 40%;">
                            <h6 class="options-text">Odd </h6>
                            <span class="options-text">:</span>
                        </div>
                        <h6 class="status-text1">{{ this.betlog.section?.odd }}</h6>
                    </div>
                    <div class="d-flex justify-content-between mt-1">
                        <div class="d-flex justify-content-between" style="width: 40%;">
                            <h6 class="options-text">Total Bet </h6>
                            <span class="options-text">:</span>
                        </div>
                        <h6 class="status-text1">{{ this.betlog.bet_logs.length }}</h6>
                    </div>
                    <div class="d-flex justify-content-between mt-1">
                        <div class="d-flex justify-content-between" style="width: 40%;">
                            <h6 class="options-text">Date </h6>
                            <span class="options-text ">:</span>
                        </div>
                        <h6 class="status-text1">{{ this.betlog.bet_date }}</h6>
                    </div>
                    <div class="d-flex justify-content-between mt-1">
                        <div class="d-flex justify-content-between" style="width: 40%;">
                            <h6 class="options-text">Total Amount </h6>
                            <span class="options-text ">:</span>
                        </div>
                        <h6 class="status-text2">{{ this.betlog.total_amount }}</h6>
                    </div>
                    <div class="d-flex justify-content-between mt-1">
                        <div class="d-flex justify-content-between" style="width: 40%;">
                            <h6 class="options-text">Reward </h6>
                            <span class="options-text">:</span>
                        </div>
                        <h6 class="status-text2">{{ this.betlog.reward_amount ?? 0 }}</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import userService from '@/services/user.service';

export default {
    data() {
        return {
            betlog: {},
            id: ''
        }
    },  
    methods: {
        handleback() {
            history.back();
        },
        getTwodBetlogs(){
            userService.getTwodBetlogsDetail(this.id).then(({data})=>{
                this.betlog = data.data;
                console.log(this.betlog);
            })
        }
    },
    created(){
        this.id = this.$route.params.id;
        this.getTwodBetlogs(this.id);
    }
}
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Secular+One&display=swap');


table {
    width: 100%;
}

th,
td {
    border: 1px solid black;
    text-align: center;
}

tbody tr {
    height: 48px;
    border-bottom: 1px solid #7410ff5d;
    font-family: 'Secular One', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #7210FF;
}

thead th {
    height: 48px;
    border-bottom: 1px solid #7410ff5d;
    font-family: 'Secular One', sans-serif;
    font-size: 17px;
    font-weight: 400;
}

.table-box {
    background: #F4ECFF;
    border-radius: 10px;
    height: 600px;
}

/* .modal-box-float {
    position: fixed;
    width: 105%;
    bottom: 0;
    border: 1px solid black;
    border-radius: 90px 75px 0px 0px;
    border: 2px solid #7210FF;
    background: white;
}
 */

 @media screen and (max-width: 3000px) {
.modal-box-float {
    position: fixed !important;
    width: 30.9% !important;
    bottom: 0;
    left: 35.5%;
    border-radius: 80px 80px 0 0;
    border: 2px solid #7210FF;
    background: white;
}
}

.options-text {
    margin-bottom: 0px !important;
    font-family: 'Secular One', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #7210FF;
    text-align: start;
}

.status-text {
    font-family: 'Secular One', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #34B232;
    text-align: end;
}

.status-text1 {
    font-family: 'Secular One', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: black;
    text-align: end;

}

.status-text2 {
    font-family: 'Secular One', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: red;
    text-align: end;

}
</style>
