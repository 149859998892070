<template>
    <div class="">
        <div class=" pt-4 px-3">
            <div class="btns  d-flex justify-content-between">
                <div class="d-flex">
                    <img @click="handleback()" src="@/assets/img/back.svg" style="width:10%" />
                    <h6 class="withdraw  pt-2 fs-16 mt-1 ms-2">Referral Commsion</h6>
                </div>
                <div @click="handleModel()" class="ref-box">
                    <h6 class="ref-text-1 text-center px-3 pt-2">
                        Enter your Friend
                        Referral Code
                    </h6>
                </div>
            </div>

            <div class="row mt-4 pb-4">
                <div class="col-12">
                    <h6 class="ref-text-3 text-center">
                        Refer your friends, Earn Cash Back
                    </h6>
                </div>
                <div class="col-12 d-flex justify-content-center mt-2">
                    <h6 class="ref-text-3 text-center mt-2">
                        Your Code
                    </h6>
                    <input class="ref-boxing mx-2" />
                    <img class="" src="@/assets/img/mark.svg" />
                </div>
            </div>
        </div>

        <div class="container px-4">
            <div class="row ms-1">
                <div class="col-6 total-one-box">
                    <div class="text-center">
                        <h6 class="total mt-4">Total Commission</h6>
                        <h6 class="total-amount mt-4 pt-1">2,312,054 Ks</h6>
                    </div>
                </div>
                <div class="col-6">
                    <div class="row">
                        <div class="col-12">
                            <div class="text-end total-one-box">
                                <h6 class="only-text pe-2 pt-1">This Week</h6>
                                <h6 class="only-text-one pe-2">28,431 Ks</h6>
                            </div>
                        </div>
                        <div class="col-12 mt-2">
                            <div class="text-end total-one-box">
                                <h6 class="only-text pe-2 pt-1">This Month</h6>
                                <h6 class="only-text-one pe-2">312,054 Ks</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container">
            <div class="row mt-3">
                <div class="col-12">
                    <div class="underline-box"></div>
                </div>
            </div>
        </div>

        <div class="container">
            <div class="row mt-3 ms-1">
                <div class="col-6">
                    <h6 class="friend">Friend’s Slips <span class="pai">(421)</span></h6>
                    <div class="underline-friend"></div>
                </div>
                <div class="col-6">
                    <h6 class="total-friend">Total Frineds <span class="pai">(102)</span></h6>
                </div>
            </div>
            <div class="row ">
                <div v-for="(item,index) in data" :key="index" class="col-12 px-4 mt-3">
                    <div class="border-slip">
                        <div class="d-flex justify-content-between mt-2">
                            <h6 class="slip-number">{{ item.slip }}</h6>
                            <h6 class="slip-date">{{ item.date }}</h6>
                        </div>
                        <div class="d-flex justify-content-between mt-1">
                            <h6 class="slip-date">{{ item.name }}</h6>
                            <h6 class="slip-amount">{{ item.amount }}<span class="slip-date">({{item.percentage}}%)</span></h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import Swal from 'sweetalert2'
export default {
    name: "ReferralComponent",
    data() {
        return {
            data: [
                {
                    id: 1,
                    slip: "Slip #2D00X2V3Z1B5 (2D)",
                    date: "27 Mar AM",
                    name: "Shwe Bawat",
                    amount: "250Ks",
                    percentage:"10"
                },
                 {
                    id: 2,
                    slip: "Slip #2D00X9Z1A8X3 (2D)",
                    date: "26 Mar AM",
                    name: "Aung",
                    amount: "380Ks",
                    percentage: "10"
                },
                 {
                    id: 3,
                    slip: "Slip #3D00B5H2J6IJ3 (3D)",
                    date: "25 Mar AM",
                    name: "Naing Wain",
                    amount: "980Ks",
                    percentage: "10"
                },
                {
                    id: 4,
                    slip: "Slip #6D00B5N4H5S8 (3D)",
                    date: "24 Mar AM",
                    name: "Linn Naing",
                    amount: "800Ks",
                    percentage: "10"
                },
                 {
                    id: 5,
                    slip: "Slip #3D00R4A1B8E4 (3D)",
                    date: "24 Mar AM",
                    name: "Khin Thu",
                    amount: "4,010Ks",
                    percentage: "10"
                },
                {
                    id: 6,
                    slip: "Slip #2D00B4E3W4N4 (Thai Lottery)",
                    date: "23 Mar AM",
                    name: "Su Wai",
                    amount: "10,840Ks",
                    percentage: "10"
                },
                 {
                    id: 7,
                    slip: "Slip #2D00B2T4E4W6 (Thai Lottery)",
                    date: "23 Mar AM",
                    name: "Wanna Aung",
                    amount: "4,100Ks",
                    percentage: "10"
                },
            ],
        }
    },
    methods: {
        handleback() {
            history.back();
        },
        handleModel() {
            Swal.fire({
                icon: 'success',
                html: `
            <div class="congrat1">
              Enter your friend code
            </div>
             <div class="ready1" style="color:#7B7680">
              Your frined will get cash back 
                earning commssion.
                You can also get cash back by referring your friend.
            </div>
            <input type="number" class="model-input-box" placeholder="-------"/>
        `,
                width: "89%",
                customClass: {
                    icon: 'my-icon-class',
                    popup: 'my-popup-class',
                    confirmButton: 'my-button-class1',
                },
                confirmButtonText: 'Okay',
            });
        }
    }
}
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Secular+One&display=swap');

.line1 {
    width: 89% !important;
    height: 1px;
    display: block;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.609);
}

.ref-text-1 {
    font-family: 'Secular One', sans-serif;
    font-size: 11px;
    font-weight: 400;
    color: white;
}

.total {
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    color: #EA2839;
    font-size: 15px;
}

.total-amount {
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    color: #0FA958;
    font-size: 20px;
}

.only-text {
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    color: #EA2839;
    font-size: 13px;
}

.only-text-one {
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    color: #000;
    font-size: 15px;
}

.total-one-box {
    border: 1px solid black;
    border-radius: 5px;
}

.underline-box {
    width: 86%;
    height: 1px;
    background: rgba(0, 0, 0, 0.187);
    display: block;
    margin: auto;
}

.friend {
    font-family: 'Secular One', sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: #7210FF;
}

.pai {
    font-family: 'Secular One', sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: #0029FF;
}

.total-friend {
    font-family: 'Secular One', sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: #000;
}

.slip-number {
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    color: #000;
    font-size: 14px;
}

.slip-date {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    color: #000;
    font-size: 14px;
}

.slip-amount {
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    color: #0FA958;
    font-size: 14px;
}

.border-slip {
    padding: 5px 13px;
    border-radius: 5px;
    background: #F4ECFF;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
.underline-friend{
    width:70%;
    height: 2px;
    background: #7210FF;
}
</style>
