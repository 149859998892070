<template>
    <div class="container">
        <div class="btns mt-4 ms-2 ">
            <div class="d-flex justify-content-between">
                <div class="d-flex" @click="handleback()">
                    <img src="@/assets/img/back.svg" />
                    <h6 class="withdraw ms-2 pt-2">2D Lottery</h6>
                </div>
                <img class="me-3"  src="@/assets/img/mark.svg" />
            </div>
            <!-- <div class="row">
                <div class="col-12">
                    <div class="d-flex justify-content-between">
                        <h6 class="withdraw1 ms-2 pt-2">Choose Date/Time</h6>
                        <h6 class="withdraw2 ms-2 pt-2">( {{ formattedDate }} )</h6>
                    </div>
                </div>
            </div> -->
            <div class="row mt-2">
                <div class="col-12">
                    <div class=" mt-3 position-sticky">
                        <h6 class="mb-1 fw-bolder"> 
                            {{ formattedDate }} , {{ this.section?.opening_time }} Section 
                        </h6>
                        <h6 class="mb-2 text-danger text-end fw-bolder">Close Time : {{ this.section?.ended_time }}</h6>
                    </div>
                </div>
                <!-- <div class="col-8">
                    <div v-for="(value,index) in this.sections" :key="index" @click="selectSection(value)">
                        <h6 class="morning-part mb-0 ms-2">{{ value.time_part }}</h6>
                        <h6 class="morning-time mt-1 ms-2">(Open time {{ value.opening_time }} / Close Time {{ value.ended_time }})</h6>
                    </div>
                </div>
                <div class="col-4">
                    <div class="time-box mt-3" v-for="(value,index) in this.sections" :key="index">
                        <h6 class="time-text mb-0" v-if="this.selectedSection === value.id"> 
                            {{ formattedDate }} , {{ value.opening_time }}
                        </h6>
                    </div>
                </div> -->
            </div>
        </div>
        <div class="">
            <div class="numbers mt-3 d-flex flex-wrap justify-content-center py-2 px-2 gap-1">
                <div v-for="(value, index) in numbers" @click="toggleNumber(value)" :key="index" class="number">
                    <div :class="{ 'full': value.percent > 99.99, 'choosed': selectedNumbers.find(n => n.number == value.number),'blocked': value.is_blocked }"
                        class="fw-bolder h6 mb-0 d-flex flex-column justify-content-center number-box">
                        <p class="mb-0 py-0">{{ value.number }}</p>
                        <!-- <font-awesome-icon v-if="value.percent < 50" icon="fa-solid fa-circle" size="2xs"
                            class="position-absolute icon text-success" />
                        <font-awesome-icon v-else-if="(value.percent >= 50 && value.percent <= 90)"
                            icon="fa-solid fa-circle" size="2xs" class="position-absolute icon text-warning" />
                        <font-awesome-icon v-else-if="(value.percent > 90 && value.percent < 100)" icon="fa-solid fa-circle"
                            size="2xs" class="position-absolute icon text-danger" /> -->
                            <!-- <p class="progress-bar p-0 m-0">
                                <p class="progress p-0 m-0" :style="{ width: currentProgress + '%' }"></p>
                            </p> -->
                            <!-- <p class="mb-0 py-0 bg-success" style="width:80%;height: 2px;"> </p> -->
                            <ProgressBar :progress="value.percent" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row bet-box-container bg-white" style="border: 2px solid #7210FF;border-radius: 20px 20px 0px 0px;">
        <div class="col-12 mt-3">
            <button class="r-box d-block mx-auto" @click="makeR()">
                <div class="">
                    <h6 class="r-text mb-0">Make (R)</h6>
                </div>
            </button>
        </div>
        <div class="col-3 mt-2">
            <div class="r-box1 d-block mx-auto ms-3" @click="reset()">
                <h6 class="r-text text-center mb-0">Reset</h6>
            </div>
        </div>
        <div class="col-4 mt-2">
            <button @click="handlemodel()" class="r-box2 d-block mx-auto border-0">
                <div>
                    <h6 class="r-text text-center mb-0">Fast</h6>
                </div>
            </button>
        </div>
        <div class="col-5 mt-2">
            <div style="width:100%">
                <img src="@/assets/img/plus.svg " @click="increaseBetAmount()"/>
                <input class="action-input mx-1" placeholder="0" v-model="unitBetAmount" />
                <img src="@/assets/img/minus.svg" @click="decreaseBetAmount()"/>
            </div>
        </div>
        <div class="col-12 mb-4">
            <div class="d-flex justify-content-between mt-3">
                <div class="d-flex">
                    <h6 class="options-text mt-2 ms-3 mb-0">No </h6>
                    <span class="options-text mt-1 ms-2 ">:</span>
                    <input class="action-input-2 ms-2" :placeholder="selectedNumbers.length"  disabled/>
                </div>
                <div class="d-flex pe-4">
                    <h6 class="options-text mt-2 text-black mb-0">Total </h6>
                    <span class="options-text mt-1  ">:</span>
                    <input class="action-input-3 ms-2" :placeholder="unitBetAmount * selectedNumbers.length"  disabled/>
                </div>
                <div class="me-1">
                    <button @click="bet()" class="bet text-white me-3">Bet</button>
                </div>
            </div>
        </div>
    </div>
    <div v-if="showModal" class="model-2d-box">
        <div class="d-flex justify-content-center">
            <h6 class="text-center choose mt-4">Choose Fast</h6>
            <div @click="handlemodel()" style="position:absolute;right:10px;top:17px">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-x-circle"
                    style="color:#7210FF" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path
                        d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
            </div>
        </div>
        <div class="h-100 pb-5" style="overflow-y: scroll;">
            <div class="row mt-5">
                <div class="col-3">
                    <h6 class="sin ms-1">အကွက် -</h6>
                </div>
                <div class="col-9">
                    <div class="d-flex flex-wrap justify-content-start">
                        <div class=" mini-box" style="width:16%">
                            <div class="" @click="findKyi()">
                                <h6 class="mini-text mb-0">ကြီး</h6>
                            </div>
                        </div>
                        <div class=" mini-box ms-2" style="width:16%">
                            <div class="" @click="findNgel()">
                                <h6 class="mini-text mb-0"> ငယ်</h6>
                            </div>
                        </div>
                        <div class=" mini-box ms-2" style="width:16%">
                            <div class="" @click="findOdd()">
                                <h6 class="mini-text mb-0">မ</h6>
                            </div>
                        </div>
                        <div class=" mini-box ms-2" style="width:16%">
                            <div class="" @click="findEven()">
                                <h6 class="mini-text mb-0">စုံ</h6>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex flex-wrap justify-content-start mt-3">
                        <div class="mini-box" style="width:16%">
                            <div class="" @click="findTwoEven()">
                                <h6 class="mini-text mb-0">စုံစုံ</h6>
                            </div>
                        </div>
                        <div class="mini-box ms-2" style="width:16%">
                            <div class="" @click="findEvenOdd()">
                                <h6 class="mini-text mb-0">စုံမ</h6>
                            </div>
                        </div>
                        <div class="mini-box ms-2" style="width:16%">
                            <div class="" @click="findOddEven()">
                                <h6 class="mini-text mb-0">မစုံ</h6>
                            </div>
                        </div>
                        <div class="mini-box ms-2" style="width:16%">
                            <div class="" @click="findTwoOdd()">
                                <h6 class="mini-text mb-0">မမ</h6>
                            </div>
                        </div>
                        <div class="mini-box ms-2" style="width:16%">
                            <div class="" @click="findBothSame()">
                                <h6 class="mini-text mb-0 text-nowrap">အပူး</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-3">
                    <h6 class="sin ms-1">၁လုံးပါ -</h6>
                </div>
                <div class="col-9">
                    <div class="d-flex flex-wrap gap-1 justify-content-start">
                        <div class="mini-box1 mb-2 text-center" style="width: 12%;">
                            <div class="" @click="findWithNumber(0)">
                                <h6 class="mini-text1 mb-0">0</h6>
                            </div>
                        </div>
                        <div class="mini-box1 mb-2 text-center" style="width: 12%;">
                            <div class="" @click="findWithNumber(1)">
                                <h6 class="mini-text1 mb-0">1</h6>
                            </div>
                        </div>
                        <div class="mini-box1 mb-2 text-center" style="width: 12%;">
                            <div class="" @click="findWithNumber(2)">
                                <h6 class="mini-text1 mb-0">2</h6>
                            </div>
                        </div>
                        <div class="mini-box1 mb-2 text-center" style="width: 12%;">
                            <div class="" @click="findWithNumber(3)">
                                <h6 class="mini-text1 mb-0">3</h6>
                            </div>
                        </div>
                        <div class="mini-box1 mb-2 text-center" style="width: 12%;">
                            <div class="" @click="findWithNumber(4)">
                                <h6 class="mini-text1 mb-0">4</h6>
                            </div>
                        </div>
                        <div class="mini-box1 mb-2 text-center" style="width: 12%;">
                            <div class="" @click="findWithNumber(5)">
                                <h6 class="mini-text1 mb-0">5</h6>
                            </div>
                        </div>
                        <div class="mini-box1 mb-2 text-center" style="width: 12%;">
                            <div class="" @click="findWithNumber(6)">
                                <h6 class="mini-text1 mb-0">6</h6>
                            </div>
                        </div>
                        <div class="mini-box1 mb-2 text-center" style="width: 12%;">
                            <div class="" @click="findWithNumber(7)">
                                <h6 class="mini-text1 mb-0">7</h6>
                            </div>
                        </div>
                        <div class="mini-box1 mb-2 text-center" style="width: 12%;">
                            <div class="" @click="findWithNumber(8)">
                                <h6 class="mini-text1 mb-0">8</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-3">
                    <h6 class="sin ms-1">ထိပ် -</h6>
                </div>
                <div class="col-9">
                    <div class="d-flex flex-wrap gap-1 justify-content-start">
                        <div v-for="(item, index) in this.sampleNumbers" :key="index" class="mini-box1 mb-2 text-center" style="width: 12%;">
                            <div class="" @click="findFirstNumber(item)">
                                <h6 class="mini-text1 mb-0">{{ item }}</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-3">
                    <h6 class="sin ms-1">နောက် -</h6>
                </div>
                <div class="col-9">
                    <div class="d-flex flex-wrap gap-1 justify-content-start">
                        <div v-for="(item, index) in this.sampleNumbers" :key="index" class="mini-box1 mb-2 text-center" style="width: 12%;">
                            <div class="" @click="findSecondNumber(item)">
                                <h6 class="mini-text1 mb-0">{{ item }}</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-3">
                    <h6 class="sin ms-1">နက္ခတ် -</h6>
                </div>
                <div class="col-9">
                    <div class="d-flex flex-wrap gap-1 justify-content-start">
                        <div class="mini-box1 text-center mb-2" style="width: 50%;">
                            <div class="" @click="findWithNumbers([7,18,24,35,69])">
                                <h6 class="mini-text1 mb-0">07,18,24,35,69</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-3">
                    <h6 class="sin ms-1">နက္ခတ်(R) -</h6>
                </div>
                <div class="col-9">
                    <div class="d-flex flex-wrap gap-1 justify-content-start">
                        <div class="mini-box1 text-center mb-2" style="width: 50%;">
                            <div class="" @click="findWithNumbers([70,81,42,53,96])">
                                <h6 class="mini-text1 mb-0">70,81,42,53,96</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-3">
                    <h6 class="sin ms-1">ပါဝါ -</h6>
                </div>
                <div class="col-9">
                    <div class="d-flex flex-wrap gap-1 justify-content-start">
                        <div class="mini-box1 text-center mb-2" style="width: 50%;">
                            <div class="" @click="findWithNumbers([5,16,27,38,49])">
                                <h6 class="mini-text1 mb-0">05,16,27,38,49</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-3">
                    <h6 class="sin ms-1">ပါဝါ(R) -</h6>
                </div>
                <div class="col-9">
                    <div class="d-flex flex-wrap gap-1 justify-content-start">
                        <div class="mini-box1 text-center mb-2" style="width: 50%;">
                            <div class="" @click="findWithNumbers([50,61,72,83,94])">
                                <h6 class="mini-text1 mb-0">50,61,72,83,94</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3" style="width:350px">
                <div class="col-3">
                    <h6 class="sin ms-1 text-nowrap">အလုံး၂၀စီ -</h6>
                </div>
                <div class="col-9 d-flex overflow-scroll pb-2">
                    <!-- <div class=""> -->
                        <!-- <h6 class="sin ms-1 text-nowrap">အလုံး၂၀စီ -</h6> -->
                    <!-- </div> -->
                    <div class="d-flex ">
                        <div class="t-box" @click="findNumbersWithRange(0,19)">
                            <h6 class="t-text mb-0 text-nowrap">00-19</h6>
                        </div>
                        <div class="t-box ms-2" @click="findNumbersWithRange(20,39)">
                            <h6 class="t-text mb-0 text-nowrap">20-39</h6>
                        </div>
                        <div class="t-box ms-2" @click="findNumbersWithRange(40,59)">
                            <h6 class="t-text mb-0 text-nowrap">40-59</h6>
                        </div>
                        <div class="t-box ms-2" @click="findNumbersWithRange(60,79)">
                            <h6 class="t-text mb-0 text-nowrap">60-79</h6>
                        </div>
                        <div class="t-box ms-2" @click="findNumbersWithRange(60,79)">
                            <h6 class="t-text mb-0 text-nowrap">60-79</h6>
                        </div>
                        <div class="t-box ms-2" @click="findNumbersWithRange(80,99)">
                            <h6 class="t-text mb-0 text-nowrap">80-99</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import userService from '@/services/user.service';
import Toastify from 'toastify-js';
import "toastify-js/src/toastify.css";
import ProgressBar from '@/components/ProgressBar.vue';

export default {
    name: "depositComponent",
    data() {
        return {
            showModal: false,
            numbers: [
            ],
            selectedNumbers: [],
            totalBetAmount : 0,
            unitBetAmount: 0,
            sampleNumbers: [0,1,2,3,4,5,6,7,8,9],
            selectedSection: 0,
            sections: [],
            section: {},
        }
    },
    components: {
    ProgressBar,
  },

     computed: {
        formattedDate() {
            const currentDate = new Date();
            const day= currentDate.getDate().toString().padStart(2, '0');
            const month= (currentDate.getMonth() + 1).toString().padStart(2, '0');
            const year= currentDate.getFullYear().toString();
            return `${day} ${month} ${year}`;
        },
    },
    methods: {
        handleback() {
            history.back();
        },
        handlemodel() {
            this.showModal = !this.showModal
        },
        handlenext() {
            this.$router.push({
                name: 'twodcomfirmsuccess',
            })
        },
        increaseBetAmount() {
            this.unitBetAmount += 50;
        },
        decreaseBetAmount() {
            this.unitBetAmount > 0 ? this.unitBetAmount -= 50 : this.unitBetAmount; 
        },
        toggleNumber(number) {
            
            if (number.percent > 99.99 || number.is_blocked) {
                return;
            }

            let index = this.selectedNumbers.findIndex(n => {
                return n.number == number.number
            });

            if (index != -1) {
                this.selectedNumbers.splice(index, 1)
            } else {
                this.selectedNumbers.push({
                    id: number.id,
                    number: number.number,
                    amount: this.betAmount
                })
            }
        },
        reset() {
           this.selectedNumbers = [];
        },
        makeR() {
            this.selectedNumbers.forEach(value => {
                let number = value.number;
                let round_number = this.numbers.find(n => {
                    return n.number == Number(String(number).split('').reverse().join(''))
                })

                let round_number_index = this.selectedNumbers.findIndex(n => {
                    return n.number == round_number.number
                });

                if (round_number_index == -1) {
                    this.selectedNumbers.push({
                        id: round_number.id,
                        number: round_number.number,
                        amount: this.betAmount
                    })
                }
            })
        },
        bet(){
            if(!this.selectedNumbers.length) {
                return Toastify({
                        text: 'Please choose at least one number!',
                        duration: 5000, 
                        close: true, 
                        gravity: "top", 
                        position: "center", 
                        backgroundColor: "#000",
                      
                    }).showToast();
           
            }

            if(!this.unitBetAmount) {
                return Toastify({
                        text: 'Please Fill bet amount!',
                        duration: 5000, 
                        close: true, 
                        gravity: "top", 
                        position: "center", 
                        backgroundColor: "#000",
                      
                    }).showToast();
            }

            localStorage.setItem('numbers',JSON.stringify(this.selectedNumbers));
            localStorage.setItem('unitBetAmount',JSON.stringify(this.unitBetAmount));

            this.$router.push({
                name: 'twodcomfirm',
                params: { id: this.selectedSection } 
            })

        },
        findKyi(){
           this.numbers.findIndex(n => {
                if(n.number >= 50){
                    this.selectedNumbers.push({
                        id: n.id,
                        number: n.number,
                        amount: this.betAmount
                    })
                }
            });
            this.showModal = false;
        },
        findNgel(){
           this.numbers.findIndex(n => {
                if(n.number <= 50){
                    this.selectedNumbers.push({
                        id: n.id,
                        number: n.number,
                        amount: this.betAmount
                    })
                }
            });
            this.showModal = false;
        },
        findEven(){
            this.numbers.filter(n => {
                if(n.number % 2 === 0){
                    this.selectedNumbers.push({
                        id: n.id,
                        number: n.number,
                        amount: this.betAmount
                    })
                }
            });
            this.showModal = false;
        },
        findOdd(){
            this.numbers.filter(n => {
                if(n.number % 2 !== 0){
                    this.selectedNumbers.push({
                        id: n.id,
                        number: n.number,
                        amount: this.betAmount
                    })
                }
            });
            this.showModal = false;
        },
        findTwoEven() {
            for (const num of this.numbers) {
                const tensDigit = parseInt(num.number[0]);
                const onesDigit = parseInt(num.number[1]);
                if (tensDigit % 2 === 0 && onesDigit % 2 === 0) {
                    this.selectedNumbers.push({
                        id: num.id,
                        number: num.number,
                        amount: this.betAmount
                    })
                }
            }
            this.showModal = false;
        },
        findTwoOdd() {
            for (const num of this.numbers) {
                const tensDigit = parseInt(num.number[0]);
                const onesDigit = parseInt(num.number[1]);
                if (tensDigit % 2 !== 0 && onesDigit % 2 !== 0) {
                    this.selectedNumbers.push({
                        id: num.id,
                        number: num.number,
                        amount: this.betAmount
                    })
                }
            }
            this.showModal = false;
        },
        findEvenOdd() {
            for (const num of this.numbers) {
                const tensDigit = parseInt(num.number[0]);
                const onesDigit = parseInt(num.number[1]);
                if (tensDigit % 2 === 0 && onesDigit % 2 !== 0) {
                    this.selectedNumbers.push({
                        id: num.id,
                        number: num.number,
                        amount: this.betAmount
                    })
                }
            }
            this.showModal = false;
        },
        findOddEven() {
            for (const num of this.numbers) {
                const tensDigit = parseInt(num.number[0]);
                const onesDigit = parseInt(num.number[1]);
                if (tensDigit % 2 !== 0 && onesDigit % 2 === 0) {
                    this.selectedNumbers.push({
                        id: num.id,
                        number: num.number,
                        amount: this.betAmount
                    })
                }
            }
            this.showModal = false;
        },
        findBothSame() {
            for (const num of this.numbers) {
                const tensDigit = parseInt(num.number[0]);
                const onesDigit = parseInt(num.number[1]);
                if (tensDigit === onesDigit) {
                    this.selectedNumbers.push({
                        id: num.id,
                        number: num.number,
                        amount: this.betAmount
                    })
                }
            }
            this.showModal = false;
        },
        findWithNumber(x){
            for (const num of this.numbers) {
                const tensDigit = parseInt(num.number[0]);
                const onesDigit = parseInt(num.number[1]);
                if (tensDigit === parseInt(x) || onesDigit === parseInt(x)) {
                    this.selectedNumbers.push({
                        id: num.id,
                        number: num.number,
                        amount: this.betAmount
                    })
                }
            }
            this.showModal = false;
        },
        findFirstNumber(x){
            for (const num of this.numbers) {
                const tensDigit = parseInt(num.number[0]);
                if (tensDigit === parseInt(x)) {
                    this.selectedNumbers.push({
                        id: num.id,
                        number: num.number,
                        amount: this.betAmount
                    })
                }
            }
            this.showModal = false;
        },
        findSecondNumber(x){
            for (const num of this.numbers) {
                const onesDigit = parseInt(num.number[1]);
                if (onesDigit === parseInt(x)) {
                    this.selectedNumbers.push({
                        id: num.id,
                        number: num.number,
                        amount: this.betAmount
                    })
                }
            }
            this.showModal = false;
        },
        findWithNumbers(numArr){
            for (const num of this.numbers) {
                const digit = parseInt(num.number);
                for(const x of numArr){
                    if (digit === parseInt(x)) {
                        this.selectedNumbers.push({
                            id: num.id,
                            number: num.number,
                            amount: this.betAmount
                        })
                    }
                }
            }
            this.showModal = false;
        },
        findNumbersWithRange(from,to){
            let numArr = [];
            for(let i=parseInt(from);i <= parseInt(to);i++){
                numArr.push(i);
            }
            for (const num of this.numbers) {
                const digit = parseInt(num.number);
                for(const x of numArr){
                    if (digit === parseInt(x)) {
                        this.selectedNumbers.push({
                            id: num.id,
                            number: num.number,
                            amount: this.betAmount
                        })
                    }
                }
            }
            this.showModal = false;
        },
        selectSection(section){
            if(section.ended){
                return Toastify({
                        text: section.opening_time+' is ended!',
                        duration: 5000, 
                        close: true, 
                        gravity: "top", 
                        position: "center", 
                        backgroundColor: "#000",
                      
                    }).showToast();
            }
            this.selectedSection = section.id;
            this.getSectionNumbers(false);
        },
        async getSectionList(){
            await userService.getSectionList().then(({ data }) => {
                this.sections = data.data;
                
                // this.sections.forEach((section)=>{
                    // if(!section.ended){
                    //     this.selectedSection = section.id;
                    //     return this.selectedSection;
                    // }
                // })
            })
        },
        getSection(){
            userService.getSection(this.selectedSection).then(({data}) => {
                this.section = data.data;
                if(this.section.ended){
                    this.$router.push({
                        name: 'home',
                    })
                }
            });
        },
        async getSectionNumbers(created){
            if(created){
                await this.getSectionList();
            }
            await userService.getSectionNumbers(this.selectedSection).then(({ data }) => {
                this.numbers = data.data;
            })
        }

    },
    created() {
        this.selectedSection = this.$route.params.id
        this.getSection();
        this.getSectionNumbers(false);
    }
}
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Secular+One&display=swap');


h6 {
    font-size: 13px;
}
.action-2d {
    font-family: 'Secular One', sans-serif;
    font-size: 21px;
    font-weight: 400;
    color: #7210FF;
}

.withdraw1 {
    font-family: 'Secular One', sans-serif;
    font-size: 16px;
    font-weight: 400;
    /* color: #7210FF; */
}

.withdraw2 {
    font-family: 'Secular One', sans-serif;
    font-size: 16px;
    font-weight: 300;
    color: #7210FF;
}

.morning-part {
    font-family: 'Secular One', sans-serif;
    font-size: 9px;
    font-weight: 300;
    color: #34B232;
}

.morning-time {
    font-family: 'Secular One', sans-serif;
    font-size: 9px;
    font-weight: 300;
    color: #EA2839;
}

.time-box {
    width: 100%;
    background: #7210FF;
    border-radius: 40px;
}

.time-text {
    font-family: 'Secular One', sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: white;
    padding: 9px 15px;
}

.modal-box-float5 {
    position: fixed;
    width: 107%;
    bottom: 0;
    border-radius: 80px 80px 0 0;
    border: 2px solid #7210FF;
    background: white;
}
@media screen and (max-width: 3000px) {
.modal-box-float5 {
    position: fixed;
    width: 30.9%;
    bottom: 0;
    left: 35.5%;
    border-radius: 80px 80px 0 0;
    border: 2px solid #7210FF;
    background: white;
}
}

.r-box1 {
    background: #7210FF;
    width: 90%;
    border-radius: 5px;
}

.r-box {
    background: #7210FF;
    width: 30%;
    border: 0;
    border-radius: 5px;
}

.r-box2 {
    background: #7210FF;
    width: 100%;
    border-radius: 5px;
}


.r-text {
    color: white;
    font-family: 'Secular One', sans-serif;
    font-size: 9px;
    /* font-weight: 400; */
    padding: 6px 6px;
    vertical-align: middle;
    text-align: center;
}

.model-2d-box {
    position: fixed;
    width: 100% !important ;
    top: 20vh;
    left: 0;
    right: 0;
    bottom: 0;
    background: #F4ECFF;
    border-radius: 20px 20px 0px 0px;
    padding: 10px;
    box-shadow: 1px 1px 13px #888;
}


.choose {
    font-family: 'Secular One', sans-serif;
    font-size: 15px;
    font-weight: 400;
}

.sin {
    font-family: 'Secular One', sans-serif;
    font-size: 15px;
    font-weight: 400;
}

.mini-box {
    border: 2px solid #7210FF;
    border-radius: 5px;
    /* width: 48px !important; */
}

.mini-box1 {
    border: 2px solid #7210FF;
    border-radius: 5px;
}


.mini-text {
    font-family: 'Secular One', sans-serif;
    font-size: 12px;
    font-weight: 400;
    color: #7210FF;
    padding: 12px 0px;
    text-align: center;
}

.mini-text1 {
    font-family: 'Secular One', sans-serif;
    font-size: 12px;
    font-weight: 400;
    color: #7210FF;
    /* padding: 12px 0px; */
    /* position: relative; */
    /* right: 3px; */
}

.t-text {
    font-family: 'Secular One', sans-serif;
    font-size: 12px;
    font-weight: 400;
    color: #7210FF;
    /* padding: 12px 10px; */
}

.t-box {
    border: 2px solid #7210FF;
    border-radius: 5px;
}
.action-input{
    padding: 0px 5px;
    color: #7210FF;
    font-weight: bolder;
}
.numbers {
    background-color: #F4ECFF;
    border-radius: 10px;
    margin-bottom: 30vh;
}
.number {
    width:  10%;
}

.number .number-box {
    border: 1px solid #7210FF;
    padding: 10px 5px;
    border-radius: 5px;
    background-color: #fff;
    color: #000;
    width: 100%;
    aspect-ratio: 1/1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.number .icon {
    bottom: 5px;
    font-size: .2rem;
}

.number .full {
    background-color: transparent !important;
    color: #9e9e9e !important;
    border: 1px solid #F1F3FA;

}

.number .choosed {
    background-color: #7210FF !important;
    color: #fff !important;
}

.blocked {
    background-color: #666666 !important;
}

.bet-box-container {
    position: fixed;
    bottom: 0px;
    left: 0;
    right: 0;
}

@media screen and (max-width: 3000px) {
.bet-box-container {
    position: fixed;
    bottom: 0px;
    left: 33.6%;
    right: 50 !important;
    width: 32.5%;
}
}
</style>
