import axios from 'axios';
import store from '@/store';
import router from '@/router';

const API_URL = "https://admin.kmlottery.com/api/";
// const API_URL = "http://127.0.0.1:8000/api/";



axios.interceptors.request.use(
  function(request) {
    return request
  }, 
  function(error) {
    return Promise.reject(error)
  }
);

axios.interceptors.response.use(
  function(response) {
    return response
  }, 
  function (error) {
    store.state.index.loading = 0
    if (error.code == "ERR_NETWORK") {
      // store.dispatch('showError',"Network Error")
    }else if(error.response.status == 500) {
      store.dispatch('showError',"Internal Server Error")
    } else if (error.response.status == 401) {
      localStorage.removeItem('user');
      // window.location.reload()
      router.push({ name: "login" })
    }

    return Promise.reject(error)
  }
);

class AuthService {
  login(user) {
    return axios
      .post(API_URL + 'login', {
        phone: user.phone,
        password: user.password,
        security_code: user.security_code,
        name:user.name
      }).then(response => {
          if (response.data.data.token) {
            localStorage.setItem('user', JSON.stringify(response.data.data));
          }
        return response.data;
      });
  }

  logout() {
    localStorage.removeItem('user');
  }

  register(user) {
    return axios.post(API_URL + 'register', user, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }).then(response => {
      console.log(response)
        if (response.data.data.token) {
          localStorage.setItem('user', JSON.stringify(response.data.data));
        }
        return response.data;
      });
  }
}

export default new AuthService();