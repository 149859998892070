<template>
    <div class="progress-bar m-0 p-0">
      <p class="progress m-0 p-0" :style="{ width: progress + '%' }"> </p>
    </div>
</template>
  
  <script>
  export default {
    props: {
      progress: {
        type: Number,
        required: true,
      },
    },
  };
  </script>
  
  <style scoped>
 .progress-bar {
    width: 90%;
    background-color: #d8d8d8;
    height: 3px;
    /* border-radius: 5px; */
}
  
.progress {
    height: 100%;
    /* border-radius: 5px; */
    background-color: #18d11e;
}
  </style>
  