import { createWebHistory, createRouter } from "vue-router";
import LoginView from "@/views/LoginView.vue";
import HomeView from "@/views/HomeView.vue";

import ReferralView from "@/views/Referral.vue";


import HistoryView from "@/views/2D3DHistory.vue";

import ServicesView from "@/views/CheckThai.vue";
import SupportView from '@/views/Support.vue';

import NotificationsView from "@/views/NotificationsView.vue";



import WithdrawListView from "@/views/Withdraw/WithdrawList.vue";
import KpayWithdrawView from "@/views/Withdraw/KpayWithdraw.vue";
import BangkokWithdrawView from "@/views/Withdraw/BangkokWithdraw.vue";

import TwoDBetSlip from "@/views/2DBetSlip.vue";
import ThreeDBetSlip from "@/views/3DBetSlip.vue";
import ThreeDComfirm from "@/views/3DComfirm.vue";
import ThreeDComfirmSuccess from "@/views/3DComfirmSuccess.vue";

import ProfileView from "@/views/Profile.vue";

import TransactionView from "@/views/Transaction.vue";

import DepositView from "@/views/Deposit.vue";
import KayDepositView from "@/views/KpayDeposit.vue";

import ReferralCommsionView from "@/views/ReferralCommsion.vue";

import KayDepositComfirmView from "@/views/KpayDepositComfirm.vue";

import EditProfileView from "@/views/EditProfile.vue";
import ChangePassword from "@/views/ChangePassword.vue"

import ThailandLotteryHistoryView from "@/views/ThailandLotteryHistory.vue";
import ThaiDView from "@/views/ThaiD.vue";
import ComfirmThaiDView from "@/views/ComfirmThaiD.vue";
import SuccessfulThaiDView from "@/views/SuccessfulThaiD.vue";
import ThaiDHistoryView from "@/views/ThaiDHistory.vue";
import ThaiDDetailView from "@/views/ThaiDDetail.vue";
import TwoDView from "@/views/2D.vue";
import TwoDComfirm from "@/views/2DComfirm.vue";
import TwoDComfirmSuccess from "@/views/2DComfirmSuccess.vue";
import TwoDThreeDCalendar from "@/views/2D3DCalendar.vue";
import middleware from "@/middleware";
import threed from './threed';


const routes = [
  ...threed,
  {
    path: "/withdraw-list",
    name: "withdrawlist",
    component: WithdrawListView,
    meta: {
      navbar: true,
      footerNavbar: false,
      backButton: true,

    }
  },
  {
    path: "/kpaywithdraw/:id",
    name: "kpaywithdraw",
    component: KpayWithdrawView,
    meta: {
      navbar: true,
      footerNavbar: false,
      backButton: true,

    }
  },
  {
    path: "/bangkokwithdraw",
    name: "bangkokwithdraw",
    component: BangkokWithdrawView,
    meta: {
      navbar: true,
      footerNavbar: false,
      backButton: true,

    }
  },
  {
    path: "/profileview",
    name: "profileview",
    component:ProfileView ,
    meta: {
      navbar: true,
      footerNavbar: true,
      backButton: true,

    }
  },
  {
    path: "/twod/:id",
    name: "twod",
    component: TwoDView,
    meta: {
      footerNavbar: false,
      backButton: true,

    }
  },
  {
    path: "/twodcomfirm/:id",
    name: "twodcomfirm",
    component: TwoDComfirm,
    meta: {
      navbar: false,
      footerNavbar: false,
      backButton: true,

    }
  },
  {
    path: "/twodcomfirmsuccess",
    name: "twodcomfirmsuccess",
    component: TwoDComfirmSuccess,
    meta: {
      navbar: true,
      footerNavbar: false,
      backButton: true,

    }
  },
  {
    path: "/twodthreedcalendar",
    name: "twodthreedcalendar",
    component: TwoDThreeDCalendar,
    meta: {
      navbar: true,
      footerNavbar: false,
      backButton: true,

    }
  },
  {
    path: "/2DBetSlip/:id",
    name: "2DBetSlip",
    component: TwoDBetSlip,
    meta: {
      navbar: true,
      footerNavbar: false,
      backButton: true,

    }
  },
  {
    path: "/3DComfirm",
    name: "3DComfirm",
    component: ThreeDComfirm,
    meta: {
      navbar: true,
      footerNavbar: false,
      backButton: true,

    }
  },
  {
    path: "/comfirmthaid",
    name: "comfirmthaid",
    component: ComfirmThaiDView,
    meta: {
      navbar: true,
      footerNavbar: false,
      backButton: true,

    }
  },
  {
    path: "/thaiddetail",
    name: "thaiddetail",
    component: ThaiDDetailView,
    meta: {
      navbar: true,
      footerNavbar: false,
      backButton: true,

    }
  },
  {
    path: "/successfulthaid",
    name: "successfulthaid",
    component: SuccessfulThaiDView,
    meta: {
      navbar: true,
      footerNavbar: false,
      backButton: true,

    }
  },
  {
    path: "/thaidhistory",
    name: "thaidhistory",
    component: ThaiDHistoryView,
    meta: {
      navbar: true,
      footerNavbar: false,
      backButton: true,

    }
  },
  {
    // path: "/thailandlotteryhistory/:slip_number",
    path: "/thailandlotteryhistory", // for error fixed
    name: "thailandlotteryhistory",
    component: ThailandLotteryHistoryView,
    meta: {
      navbar: true,
      footerNavbar: false,
      backButton: true,

    }
  },
  {
    path: "/referral",
    name: "referral",
    component: ReferralView,
    meta: {
      navbar: true,
      footerNavbar: false,
      backButton: true,

    }
  },
  {
    path: "/3DComfirmSuccess",
    name: "3DComfirmSuccess",
    component: ThreeDComfirmSuccess,
    meta: {
      navbar: true,
      footerNavbar: false,
      backButton: true,

    }
  },
  {
    path: "/3DBetSlip",
    name: "3DBetSlip",
    component: ThreeDBetSlip,
    meta: {
      navbar: true,
      footerNavbar: false,
      backButton: true,

    }
  },
  {
    path: "/transaction-view",
    name: "transaction-view",
    component: TransactionView,
    meta: {
      navbar: true,
      footerNavbar: false,
      backButton: true,

    }
  },
  {
    path: "/deposit-view",
    name: "deposit-view",
    component: DepositView,
    meta: {
      navbar: true,
      footerNavbar: false,
      backButton: true,

    }
  },
  {
    path: "/KpayDeposit/:id",
    name: "KpayDeposit",
    component: KayDepositView,
    meta: {
      navbar: true,
      footerNavbar: false,
      backButton: true,

    }
  },
  {
    path: "/KpayDepositComfirm/:id",
    name: "KpayDepositComfirm",
    component: KayDepositComfirmView,
    meta: {
      navbar: true,
      footerNavbar: false,
      backButton: true,

    }
  },
  {
    path: "/Referralcommsion",
    name: "Referralcommsion",
    component: ReferralCommsionView,
    meta: {
      navbar: true,
      footerNavbar: false,
      backButton: true,

    }
  },
  {
    path: "/editprofile",
    name: "editprofile",
    component: EditProfileView,
    meta: {
      navbar: true,
      footerNavbar: false,
      backButton: true,

    }
  },
  {
    path: "/changepassword",
    name: "changepassword",
    component: ChangePassword,
    meta: {
      navbar: true,
      footerNavbar: false,
      backButton: true,
    }
  },
  {
    path: "/thaidview",
    name: "thaidview",
    component: ThaiDView,
    meta: {
      navbar: true,
      footerNavbar: false,
      backButton: true,

    }
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
    meta: {
      navbar: true,
      footerNavbar: false,
      backButton: true,
    }
  },
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      navbar: false,
      footerNavbar: true,
      backButton: true,
      requireAuth: false,
    }
  },
  {
    path: "/support",
    name: "support",
    component: SupportView,
    meta: {
      navbar: true,
      footerNavbar: false,
      backButton: false,
    }
  },
  {
    path: "/services",
    name: "services",
    component: ServicesView,
    meta: {
      navbar: false,
      footerNavbar: false,
      backButton: false
    }
  }, {
    path: "/profile",
    name: "profile",
    component: HistoryView,
    meta: {
      navbar: true,
      footerNavbar: false,
      backButton: false
    }
  },
  {
    path: "/notifications",
    name: "notifications",
    component: NotificationsView,
    meta: {
      title: "အသိပေးချက်များ",
      navbar: true,
      footerNavbar: false,
      backButton: true,
    }
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

middleware(router)

export default router;