<template>
    <div class="container">
        <div @click="handleback()" class="btns mt-3 ms-2 d-flex">
            <img src="@/assets/img/back.svg" />
              <h6 class=" ms-2 pt-2">Slip : #{{ slip }}</h6>
        </div>
        <div v-for="(item, index) in ListData" :key="index" class="row justify-content-center mx-1 mt-3 my-1">
            <div class="w-100 h-auto mb-2 position-relative">
                <img src="@/assets/lottery.jpeg" class="w-100 h-100" />
                <div class="history-box ms-1">
                    <h6 v-for="(digit, digitIndex) in item.bet_number.split('')" :key="digitIndex" class="p-l">{{
                        digit }}</h6>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { ThaiDDetails } from '../services/Auth-Services'
export default {
    data() {
        return {
            isStatus: false,
            ListData: null,
            slip: null,
        }
    },
    created() {
        const slip_number = this.$route.params.slip_number;
        this.slip = slip_number;
        ThaiDDetails(this.$route.params.slip_id).then((response) => {
            this.ListData = response.data
            console.log(response.data);
        }).catch((error) => {
            console.log(error);
        }); 
    },
    methods: {
        handleback() {
            history.back();
        },
        handleChange() {
            this.isStatus = !this.isStatus
        },
       
    }
}
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Secular+One&display=swap');
.p-l{
    width: 22px;
}
.history-box{
    position: absolute;
    display: flex;
    top: 8%;
    left: 48%;
}
.action-2d {
    font-family: 'Secular One', sans-serif;
    font-size: 21px;
    font-weight: 400;
    color: #7210FF;
}

.action-3d {
    font-family: 'Secular One', sans-serif;
    font-size: 21px;
    font-weight: 400;
    color: #546E7A;
}

.line-bet {
    width: 50%;
    height: 2px;
    background: #7210FF;
    display: block;
    margin: auto;
}

.slipnumber {
    font-family: 'Secular One', sans-serif;
    font-size: 15px;
    font-weight: 400;
}

.active-text {
    font-family: 'Secular One', sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: #279958;
    padding-top: 6px;
}

.active-text1 {
    font-family: 'Secular One', sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: #EB73ED;
    padding-top: 6px;
}

.active-text2 {
    font-family: 'Secular One', sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: #F69945;
    padding-top: 6px;
}

.active-text4 {
    font-family: 'Secular One', sans-serif;
    font-size: 13px;
    font-weight: 400;
    color: #F69945;
    padding-top: 6px;
}

.active-text3 {
    font-family: 'Secular One', sans-serif;
    font-size: 13px;
    font-weight: 400;
    color: #EB73ED;
    padding-top: 6px;
}


.active-box {
    background: #E0EBE5;
    padding: 5px 20px;
    border-radius: 17px;
}

.active-box1 {
    background: #F6E9F6;
    padding: 5px 20px;
    border-radius: 17px;
}

.active-box2 {
    background: #F5ECE4;
    padding: 5px 20px;
    border-radius: 17px;
}

.line-bett {
    width: 97%;
    height: 1px;
    background: rgba(0, 0, 0, 0.38);
}

.bet-box {
    border: 1px solid #5856D6;
    border-radius: 17px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
</style>
