import threedService from "@/services/threed-service";
export const threed_bet_log = {
    namespaced: true,
    state() {
        return {
            bet_info: [],
            error_status: false,
            loading_status: true
        }
    },
    getters: {
        getBetInfo: state => state.bet_info,
    },
    mutations: {
        SET_BET_INFO: (state, payload) => state.bet_info.push(payload),
        DELETE_BET_INFO: (state, index) => state.bet_info.splice(index, 1),
        INCREMENTAMOUNT: (state, target_index) => {
            state.bet_info.forEach((bet_log, index) => {
                if (target_index == index)
                    bet_log.amount += 100
            })
        },
        DECREMENTAMOUNT: (state, target_index) => {
            state.bet_info.forEach((bet_log, index) => {
                if (target_index == index)
                    bet_log.amount -= 100
            })
        },
    },
    
    actions: {
        
        setBetInfo: (context, payload) => context.commit('SET_BET_INFO', payload),
        deleteBetInfo: (context, index) => context.commit('DELETE_BET_INFO', index),
        incrementAmount: (context, index) => context.commit('INCREMENTAMOUNT', index),
        decrementAmount: (context, index) => context.commit('DECREMENTAMOUNT', index),

        bet: (context) => {
            if (context.state.bet_info.length <= 0)
                return
            threedService.bet(context.state.bet_info).then(({ data }) => {
                console.log(data);
                // context.commit('SET_SECTION', data.data);
            }).catch(error => {
                console.log(error);
                // context.commit('SET_ERROR_STATUS', true);
                // context.state.loading_status = false;
            }).finally(() => {
                // context.state.loading_status = false;
                // context.state.section.length <= 0 ? context.commit('SET_IS_SECTION_EMPTY', true) : context.commit('SET_IS_SECTION_EMPTY', false)
            });
        }
    },
};
