import axios from "axios";
import authHeader from "./auth-header";

const API_URL = 'https://admin.kmlottery.com/api/';
// const API_URL = 'http://127.0.0.1:8000/api/';


class ThreedService {
    getLatestSection() {
        return axios.get(API_URL + "threed/sections/latest", { headers: authHeader() });
    }
    bet(bet_log) {
        console.table(bet_log)
        return axios.post(API_URL + 'threed/sections/bet', bet_log, { headers: authHeader() });
    }
    fetchBalance = () => {
        return axios.get(API_URL + 'customer/balanceAmount', { headers: authHeader() });
    };
}
export default new ThreedService();

