<template>
    <div class="container">
        <div @click="handleback()" class="btns mt-4 ms-2">
            <img src="@/assets/img/back.svg" />
        </div>

        <div class="row">
            <div class="col">
                <div class="mt-4 pt-3">
                    <h6 class="text-center support-header">
                        Support Team
                    </h6>
                </div>
                <div class="pt-2">
                    <p class="support-title text-center">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa accusantium voluptatum eos officia
                        aliquid expedita cum in et a asperiores provident blanditiis, nostrum explicabo
                    </p>
                </div>
                <div>
                    <div class="d-flex justify-content-between box mt-5 ">
                        <div class="d-flex">
                            <img src="@/assets/img/viber.svg" />
                            <h6 class="ph ms-2 mt-2">+95912345678</h6>
                        </div>
                        <div>
                            <h6 class="condition mt-2">24/7 available</h6>
                        </div>
                    </div>
                    <div class="pt-1">
                        <div class="d-flex justify-content-between box mt-4">
                            <div class="d-flex">
                                <img src="@/assets/img/viber.svg" />
                                <h6 class="ph ms-2 mt-2">+95998745612</h6>
                            </div>
                            <div>
                                <h6 class="condition mt-2">24/7 available</h6>
                            </div>
                        </div>
                    </div>
                    <div class="pt-1">
                        <div class="d-flex justify-content-between box mt-4">
                            <div class="d-flex">
                                <img src="@/assets/img/tele.svg" />
                                <h6 class="ph ms-2 mt-2">+95998745612</h6>
                            </div>
                            <div>
                                <h6 class="condition mt-2">24/7 available</h6>
                            </div>
                        </div>
                    </div>
                    <div class="pt-1">
                        <div class="d-flex justify-content-between box mt-4">
                            <div class="d-flex">
                                <img src="@/assets/img/tele.svg" />
                                <h6 class="ph ms-2 mt-2">+95998745612</h6>
                            </div>
                            <div>
                                <h6 class="condition mt-2">24/7 available</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
export default {
    name: "supportComponent",
    methods: {
        handleback() {
            history.back();
        }
    }
}
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

.btns {
    background: white;
    border: 0;
}

.support-header {
    font-family: 'Roboto', sans-serif;
    font-size: 28px;
    line-height: 38px;
    font-style: Mdeium;
    font-weight: 500;
}

.support-title {
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    font-weight: 300;
}

.condition {
    font-size: 12px;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
}

.ph {
    font-size: 15px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
}

.box {
    width: 90%;
    background-color: #d9d9d976;
    display: block;
    margin: auto;
    padding: 7px;
    border-radius: 6px;
}
</style>
