import axios from 'axios';
const API_KEY = "https://admin.kmlottery.com/api";
// const API_KEY = "http://127.0.0.1:8000/api";
// Function to make a login request


export const storeThreed = (user_id, bet_number,odd,amount) => {
    const data = {
        user_id: user_id,
        bet_number: bet_number,
        odd: odd,
        amount: amount,
    };
    return axios.post(API_KEY + '/threed', data);
}

export const getThreed = (user_id) => {
    return axios.get(`${API_KEY}/threed?user_id=${user_id}`);
}

export const deleteThreeD = (id) => {
    return axios.delete(`${API_KEY}/threed/${id}`)
}

export const storeThreedComfirm = (data) => {
    return axios.post(API_KEY + '/threed/comfirm', data);
}

export const updateThreed = (user_id, threed_id, status) => {
    const data = {
        user_id: user_id,
        threed_id: threed_id,
        status : status,
    }
    return axios.post(API_KEY + '/threed/update', data);
}

export const getThreedSection = () => {
    return axios.get(API_KEY + '/threed/section/get');
}