<template>
    <div class="container">
        <div @click="handleback()" class="btns mt-4 ms-2 d-flex">
            <img src="@/assets/img/back.svg" />
            <h6 class="withdraw ms-2 pt-2">2D3D Bet History</h6>
        </div>

        <div class="row mt-3 mb-1">
            <div @click="handleChange()" class="col-6">
                <h6 :class="isStatus ? `text-center action-3d` : `text-center action-2d`">2D Bet</h6>
                <div v-if="!isStatus" class="line-bet"></div>
            </div>
            <div @click="handleChange()" class="col-6">
                <h6 :class="isStatus ? `text-center action-2d` : `text-center action-3d`">3D Bet</h6>
                <div v-if="isStatus" class="line-bet"></div>
            </div>
        </div>

        <div v-if="!isStatus" class="row justify-content-center">
            <div class="col-12">
                <router-link class="text-decoration-none text-black"  v-for="(item,key) in twodHistory" :key="key" :to="{ name: '2DBetSlip',params: { id: item.id } }">
                    <div class="mt-3 bet-box ">
                        <div class="p-3 px-4">
                            <div class="d-flex justify-content-between">
                                <h6 class="slipnumber mt-2">Slip : {{ item.slip_number }}</h6>

                                <div v-if="!(item.section?.ended)" class="active-box mb-2">
                                    <h6 class="active-text">Active</h6>
                                </div>

                                <div v-else class="active-box mb-2" style="background:#F2D9DB">
                                    <h6 class="active-text text-danger">Finish</h6>
                                </div>
                                
                            </div>
                            <div class="d-flex">
                                <div class="active-box1 mt-2">
                                    <h6 class="active-text1">Odd : {{ item.section?.odd }}</h6>
                                </div>
                                <div class="active-box2 ms-4 mt-2">
                                    <h6 class="active-text2">Bet total : {{ item.total_bet_logs }}</h6>
                                </div>
                            </div>
                            <div class="line-bett mt-2"></div>
                            <div class="d-flex justify-content-between mt-1">
                                <h6 class="slipnumber mt-2">Total : {{ item.total_amount }}</h6>
                                <h6 class="slipnumber mt-2">Time : {{ item.bet_date }}</h6>
                            </div>
                        </div>
                    </div>
                </router-link>
            </div>
        </div>

        <div v-if="isStatus" class="row justify-content-center">
            <div class="col-12">
                <router-link class="text-decoration-none text-black" :to="{ name: '3DBetSlip' }">
                    <div class="mt-3 bet-box ">
                        <div class="p-3 px-4">
                            <div class="d-flex justify-content-between">
                                <h6 class="slipnumber mt-2">Slip : #51A4V6T0L2</h6>
                                <div class="active-box mb-2">
                                    <h6 class="active-text">Active</h6>
                                </div>
                            </div>
                            <div class="d-flex">
                                <div class="active-box1 mt-2">
                                    <h6 class="active-text3">Odd : 600(R-10)</h6>
                                </div>
                                <div class="active-box2 ms-1 mt-2">
                                    <h6 class="active-text4">Bet total : 21</h6>
                                </div>
                            </div>
                            <div class="line-bett mt-2"></div>
                            <div class="d-flex justify-content-between mt-1">
                                <h6 class="slipnumber mt-2">Total : 53,340</h6>
                                <h6 class="slipnumber mt-2">Time : 01 Apr 3D</h6>
                            </div>
                        </div>
                    </div>
                </router-link>
                <div class="mt-3 bet-box ">
                    <div class="p-3 px-4">
                        <div class="d-flex justify-content-between">
                            <h6 class="slipnumber mt-2">Slip : #51A4V6T0L2</h6>
                            <div class="active-box mb-2">
                                <h6 class="active-text">Active</h6>
                            </div>
                        </div>
                        <div class="d-flex">
                            <div class="active-box1 mt-2">
                                <h6 class="active-text3">Odd : 600(R-10)</h6>
                            </div>
                            <div class="active-box2 ms-1 mt-2">
                                <h6 class="active-text4">Bet total : 21</h6>
                            </div>
                        </div>
                        <div class="line-bett mt-2"></div>
                        <div class="d-flex justify-content-between mt-1">
                            <h6 class="slipnumber mt-2">Total : 53,340</h6>
                            <h6 class="slipnumber mt-2">Time : 01 Apr 3D</h6>
                        </div>
                    </div>
                </div>
                <div class="mt-3 bet-box ">
                    <div class="p-3 px-4">
                        <div class="d-flex justify-content-between">
                            <h6 class="slipnumber mt-2">Slip : #51A4V6T0L2</h6>
                            <div class="active-box mb-2" style="background:#F2D9DB">
                                <h6 class="active-text text-danger">Finish</h6>
                            </div>
                        </div>
                        <div class="d-flex">
                            <div class="active-box1 mt-2">
                                <h6 class="active-text3">Odd : 600(R-10)</h6>
                            </div>
                            <div class="active-box2 ms-1 mt-2">
                                <h6 class="active-text4">Bet total : 21</h6>
                            </div>
                        </div>
                        <div class="line-bett mt-2"></div>
                        <div class="d-flex justify-content-between mt-1">
                            <h6 class="slipnumber mt-2">Total : 53,340</h6>
                            <h6 class="slipnumber mt-2">Time : 01 Apr 3D</h6>
                        </div>
                    </div>
                </div>
                <div class="mt-3 bet-box ">
                    <div class="p-3 px-4">
                        <div class="d-flex justify-content-between">
                            <h6 class="slipnumber mt-2">Slip : #51A4V6T0L2</h6>
                            <div class="active-box mb-2" style="background:#F2D9DB">
                                <h6 class="active-text text-danger">Finish</h6>
                            </div>
                        </div>
                        <div class="d-flex">
                            <div class="active-box1 mt-2">
                                <h6 class="active-text3">Odd : 600(R-10)</h6>
                            </div>
                            <div class="active-box2 ms-1 mt-2">
                                <h6 class="active-text4">Bet total : 21</h6>
                            </div>
                        </div>
                        <div class="line-bett mt-2"></div>
                        <div class="d-flex justify-content-between mt-1">
                            <h6 class="slipnumber mt-2">Total : 53,340</h6>
                            <h6 class="slipnumber mt-2">Time : 01 Apr 3D</h6>
                        </div>
                    </div>
                </div>
                <div class="mt-3 bet-box ">
                    <div class="p-3 px-4">
                        <div class="d-flex justify-content-between">
                            <h6 class="slipnumber mt-2">Slip : #51A4V6T0L2</h6>
                            <div class="active-box mb-2">
                                <h6 class="active-text">Active</h6>
                            </div>
                        </div>
                        <div class="d-flex">
                            <div class="active-box1 mt-2">
                                <h6 class="active-text3">Odd : 600(R-10)</h6>
                            </div>
                            <div class="active-box2 ms-1 mt-2">
                                <h6 class="active-text4">Bet total : 21</h6>
                            </div>
                        </div>
                        <div class="line-bett mt-2"></div>
                        <div class="d-flex justify-content-between mt-1">
                            <h6 class="slipnumber mt-2">Total : 53,340</h6>
                            <h6 class="slipnumber mt-2">Time : 01 Apr 3D</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import userService from '@/services/user.service';
export default {
    data() {
        return {
            isStatus: false,
            twodHistory: [],
            threedHistory: [],
        }
    },
    methods: {
        handleback() {
            history.back();
        },
        handleChange() {
            this.isStatus = !this.isStatus
        },
        getTwodBetLogsList(){
            userService.getTwodBetlogs().then(({data})=>{
                console.log(data);
                this.twodHistory = data.data;
                // console.log(this.twodHistory[0].slip_number);
            })
        }
    },
    created() {
        this.getTwodBetLogsList();
    }
}
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Secular+One&display=swap');

.action-2d {
    font-family: 'Secular One', sans-serif;
    font-size: 21px;
    font-weight: 400;
    color: #7210FF;
}

.action-3d {
    font-family: 'Secular One', sans-serif;
    font-size: 21px;
    font-weight: 400;
    color: #546E7A;
}

.line-bet {
    width: 50%;
    height: 2px;
    background: #7210FF;
    display: block;
    margin: auto;
}

.slipnumber {
    font-family: 'Secular One', sans-serif;
    font-size: 15px;
    font-weight: 400;
}

.active-text {
    font-family: 'Secular One', sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: #279958;
    padding-top: 6px;
}

.active-text1 {
    font-family: 'Secular One', sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: #EB73ED;
    padding-top: 6px;
}

.active-text2 {
    font-family: 'Secular One', sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: #F69945;
    padding-top: 6px;
}

.active-text4 {
    font-family: 'Secular One', sans-serif;
    font-size: 13px;
    font-weight: 400;
    color: #F69945;
    padding-top: 6px;
}

.active-text3 {
    font-family: 'Secular One', sans-serif;
    font-size: 13px;
    font-weight: 400;
    color: #EB73ED;
    padding-top: 6px;
}


.active-box {
    background: #E0EBE5;
    padding: 5px 20px;
    border-radius: 17px;
}

.active-box1 {
    background: #F6E9F6;
    padding: 5px 20px;
    border-radius: 17px;
}

.active-box2 {
    background: #F5ECE4;
    padding: 5px 20px;
    border-radius: 17px;
}

.line-bett {
    width: 97%;
    height: 1px;
    background: rgba(0, 0, 0, 0.38);
}

.bet-box {
    border: 1px solid #5856D6;
    border-radius: 17px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
</style>
