<template>
    <div class="d-flex text-center footer-nav footer-width bg-white p-2">

        <router-link v-for="(route, index) in routes" :to="{ name: route.routeName }" :key="index"
            class=" d-flex footer-nav-item justify-content-evenly  w-100">
            <div>
                <div v-if="route.icon === ''">
                    <h6 style="margin-top:1px">2D/3D</h6>
                </div>
                <div v-if="route.icon">
                    <img :src="route.icon" style="width:23px" />
                </div>
                <p class="footer-label text-center">{{ route.name }}</p>
            </div>
        </router-link>
    </div>
</template>
<script>
export default {
    data() {
        return {
            routes: [
                {
                    name: "Home",
                    icon: require("@/assets/img/i1.svg"),
                    iconSize: "lg",
                    routeName: "home"
                },
                {
                    name: "Commision",
                    icon: require("@/assets/img/i2.svg"),
                    iconSize: "lg",
                    routeName: "referral"
                },
                {
                    name: "Thai",
                    icon: require("@/assets/img/3i.svg"),
                    iconSize: "lg",
                    routeName: "services"
                },
                {
                    name: "Histroy",
                    icon: "",
                    iconSize: "lg",
                    routeName: "twodthreedcalendar"
                },
                {
                    name: "Profile",
                    icon: require("@/assets/img/4i.svg"),
                    iconSize: "lg",
                    routeName: "profileview"
                }
            ]
        }
    }
}
</script>
<style>
.footer-nav {
    border-top: 1px solid #00000010;
    bottom: 0;
    position: fixed;
    height: auto;
}

.footer-nav-item {
    color: #000000B2;
    text-decoration: none;
}

.footer-nav-item.router-link-active {
    color: #8530FF;
}

.footer-label {
    margin-top: 3px;
    font-size: 12px;
    margin-bottom: 0;
}

.footer-width{
    width: 100%;
}
@media screen and (max-width: 3000px) {
 .footer-width{
    width: 32.1% !important;
}
}

</style>