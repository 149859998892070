<template>
    <div class="container">
        <div @click="handleback()" class="btns mt-4 ms-2 d-flex justify-content-between">
            <div class="d-flex">
                <img src="@/assets/img/back.svg" />
                <h6 class="withdraw ms-2 pt-2">3D Successful</h6>
            </div>
        </div>

        <div class="row justify-content-center align-items-center h-100">
            <div class="col">
                <img class="d-block mx-auto" src="@/assets/img/right.svg"/>
                <h6 class="text-center suc mt-4">Successful!</h6>
                <p class="suc-one text-center mt-4">3D lottery have been placed in our ticket system. 
                 Thank you</p>
                 <div class="d-flex justify-content-center">
                    <img src="@/assets/img/green.svg"/>
                    <h6 class="suc-two mt-2 ms-2">Good Luck for you!</h6>
                 </div>
                 <router-link :to="{ name: 'profile' }">
                    <button class="suc-btn d-block mx-auto mt-5">Go History</button>
                </router-link>
            </div>
        </div>

    </div>
</template>
<script>

export default {
    name: "depositComponent",
    data() {
        return {
            isStatus: false,
            ThaidList: null,
        }
    },
   
    methods: {
        handleback() {
            history.back();
        },
        handleChange() {
            this.isStatus = !this.isStatus
        },
        handlenext() {
            this.$router.push({
                name: "3DComfirmSuccess"
            });
        },
       
    }
}
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Secular+One&display=swap');

.action-2d-one {
    font-family: 'Secular One', sans-serif;
    font-size: 17px;
    font-weight: 400;
    color: #7210FF;
}

.action-3d-one {
    font-family: 'Secular One', sans-serif;
    font-size: 17px;
    font-weight: 400;
    color: #000;
}

.header-line {
    height: 48px;
    border-bottom: 2px solid #7410ffa5;
    font-family: 'Secular One', sans-serif;
    font-size: 17px;
    font-weight: 400;
}
.suc{
    font-family: 'Secular One', sans-serif;
    font-size: 18px;
    font-weight: 400;
}
.suc-one{
    font-family: 'Secular One', sans-serif;
    font-size: 13px;
    font-weight: 400;
}
.suc-two{
    font-family: 'Secular One', sans-serif;
    font-size: 13px;
    font-weight: 400;
    color:#7210FF;
}
.suc-btn{
    font-family: 'Secular One', sans-serif;
    font-size: 17px;
    font-weight: 500;
    color:white;
    background: #7210FF;
    border: 0;
    padding: 8px 35px;
    border-radius: 25px;
}
</style>
