<template>
    <div class="profile-page">
        <div @click="handleback()" class="py-3 px-3 d-flex justify-content-between align-items-center">
            <div class="d-flex gap-2 align-items-center">
                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M11.7812 20.9084L4.63125 13.7584C4.52291 13.65 4.446 13.5327 4.4005 13.4063C4.355 13.2799 4.33261 13.1445 4.33333 13C4.33333 12.8556 4.35608 12.7202 4.40158 12.5938C4.44708 12.4674 4.52364 12.35 4.63125 12.2417L11.7812 5.09169C11.9799 4.89308 12.2283 4.78944 12.5266 4.78078C12.8249 4.77211 13.082 4.87575 13.2979 5.09169C13.5146 5.2903 13.6276 5.53875 13.637 5.83702C13.6464 6.1353 13.5424 6.39241 13.325 6.60836L8.01666 11.9167H20.1229C20.4299 11.9167 20.6873 12.0207 20.8953 12.2287C21.1033 12.4367 21.207 12.6938 21.2062 13C21.2062 13.307 21.1026 13.5644 20.8953 13.7724C20.6881 13.9804 20.4306 14.0841 20.1229 14.0834H8.01666L13.325 19.3917C13.5236 19.5903 13.6276 19.8431 13.637 20.15C13.6464 20.457 13.5424 20.7097 13.325 20.9084C13.1264 21.125 12.8736 21.2334 12.5667 21.2334C12.2597 21.2334 11.9979 21.125 11.7812 20.9084Z"
                        fill="black" />
                </svg>
                <h6 class="fs-18 fw-normal mb-0 only-font">Confirm Lottery</h6>
            </div>
            <div>
                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M13 4.33341C17.7786 4.33341 21.6667 8.2215 21.6667 13.0001C21.6667 17.7787 17.7786 21.6667 13 21.6667C8.22144 21.6667 4.33335 17.7787 4.33335 13.0001C4.33335 8.2215 8.22144 4.33341 13 4.33341ZM13 2.16675C7.01677 2.16675 2.16669 7.01683 2.16669 13.0001C2.16669 18.9833 7.01677 23.8334 13 23.8334C18.9833 23.8334 23.8334 18.9833 23.8334 13.0001C23.8334 7.01683 18.9833 2.16675 13 2.16675ZM14.0834 16.2501H11.9167V18.4167H14.0834V16.2501ZM11.9167 14.0834H14.0834L14.625 7.58342H11.375L11.9167 14.0834Z"
                        fill="black" />
                </svg>
            </div>
        </div>

        <div class="my-3 mx-3">
            <div class="">
                <div class="text-center py-3">
                    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_23_544)">
                            <path
                                d="M30 0C24.0666 0 18.2664 1.75947 13.3329 5.05591C8.39943 8.35235 4.55426 13.0377 2.28363 18.5195C0.0129985 24.0013 -0.581101 30.0333 0.576455 35.8527C1.73401 41.6721 4.59123 47.0176 8.78681 51.2132C12.9824 55.4088 18.3279 58.266 24.1473 59.4236C29.9667 60.5811 35.9987 59.987 41.4805 57.7164C46.9623 55.4458 51.6477 51.6006 54.9441 46.6671C58.2405 41.7336 60 35.9334 60 30C60 22.0435 56.8393 14.4129 51.2132 8.7868C45.5871 3.1607 37.9565 0 30 0ZM33 48H27V42H33V48ZM33 36H27V12H33V36Z"
                                fill="#7210FF" />
                        </g>
                        <defs>
                            <clipPath id="clip0_23_544">
                                <rect width="60" height="60" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                </div>
                <h6 class="fs-16 fw-500 my-3 text-center only-font">Confirm your Ticket numbers</h6>
                <h6 class="fs-12 text-center fw-400 text-black px-2 font-only" style="word-spacing:3px">Ticket prices is
                    7000Ks per 1 tickets
                    and also you can check
                    selected tickets number
                    in your bet history of your account.
                </h6>
            </div>
            <div class="d-flex gap-2 align-items-center justify-content-center my-4">
                <div>
                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M21.4482 8.55461C20.9608 8.06701 20.6754 7.41336 20.6491 6.72439C20.6228 6.03543 20.8576 5.36193 21.3064 4.8386C21.3436 4.79507 21.3629 4.73912 21.3605 4.68197C21.3581 4.62481 21.3342 4.57067 21.2935 4.53039L18.7078 1.94172C18.6651 1.899 18.6071 1.875 18.5467 1.875C18.4862 1.875 18.4283 1.899 18.3855 1.94172L14.264 6.06321C14.112 6.21523 13.9974 6.4006 13.9295 6.60461C13.8618 6.80909 13.7474 6.99499 13.5953 7.14755C13.4433 7.30011 13.2578 7.41514 13.0535 7.48352C12.8494 7.55154 12.6638 7.66608 12.5115 7.81809L1.94178 18.3855C1.89906 18.4282 1.87506 18.4862 1.87506 18.5466C1.87506 18.607 1.89906 18.665 1.94178 18.7077L4.52752 21.2935C4.5678 21.3341 4.62194 21.358 4.6791 21.3604C4.73625 21.3628 4.7922 21.3435 4.83573 21.3064C5.35895 20.857 6.0326 20.6219 6.7218 20.648C7.411 20.6741 8.0649 20.9596 8.55259 21.4473C9.04028 21.935 9.32577 22.5889 9.35191 23.2781C9.37804 23.9673 9.1429 24.641 8.69354 25.1642C8.65642 25.2077 8.63711 25.2637 8.6395 25.3208C8.6419 25.378 8.6658 25.4321 8.70643 25.4724L11.2922 28.0581C11.3349 28.1009 11.3929 28.1249 11.4533 28.1249C11.5137 28.1249 11.5717 28.1009 11.6144 28.0581L22.1848 17.4884C22.3368 17.3361 22.4513 17.1506 22.5193 16.9464C22.587 16.7419 22.7014 16.556 22.8535 16.4035C23.0055 16.2509 23.191 16.1359 23.3953 16.0675C23.5993 15.9996 23.7847 15.885 23.9367 15.7329L28.0582 11.6114C28.1009 11.5687 28.1249 11.5107 28.1249 11.4503C28.1249 11.3899 28.1009 11.3319 28.0582 11.2892L25.4724 8.70344C25.4322 8.66281 25.378 8.6389 25.3209 8.63651C25.2637 8.63412 25.2078 8.65343 25.1642 8.69055C24.6416 9.14018 23.9686 9.37597 23.2796 9.35077C22.5906 9.32556 21.9366 9.04123 21.4482 8.55461Z"
                            stroke="#7210FF" stroke-width="1.625" stroke-miterlimit="10" />
                        <path
                            d="M14.6777 8.22886L13.7104 7.26147M17.257 10.8082L16.6119 10.1636M19.8363 13.388L19.1918 12.7429M22.7385 16.2896L21.7711 15.3222"
                            stroke="#7210FF" stroke-width="1.625" stroke-miterlimit="10" stroke-linecap="round" />
                    </svg>
                </div>
                <h6 class="fs-13 fw-500 mb-0 only-font" style="color: #7210FF">Here your ticket numbers</h6>
            </div>
        </div>

        <!-- start tickets  -->
        <div class="my-3 mx-3">
            <!-- start ticket box  -->
            <div v-for="(item, index) in ThaidList" :key="index"
                class="d-flex justify-content-between gap-1 align-items-center mb-3">
                <div class="px-2 d-flex justify-content-center align-items-center gap-1 ticket-container ">
                    <div v-for="(digit, digitIndex) in item.bet_number.split('')" :key="digitIndex"
                        class="text-center py-2 px-3">
                        <h6 class="fw-bold fs-16 mb-1">{{ digit }}</h6>
                        <h6 class="fw-bold  mb-0 ticket-number">Sev</h6>
                    </div>
                </div>
                <div @click="handledelete(item.id)" class="px-1">
                    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M13 4.3335C8.21273 4.3335 4.33331 8.214 4.33331 13.0002C4.33331 17.7863 8.21273 21.6668 13 21.6668C17.7872 21.6668 21.6666 17.7863 21.6666 13.0002C21.6666 8.214 17.7872 4.3335 13 4.3335ZM17.0159 15.4842C17.219 15.6874 17.3331 15.9629 17.3331 16.2502C17.3331 16.5374 17.219 16.8129 17.0159 17.0161C16.8128 17.2192 16.5373 17.3333 16.25 17.3333C15.9627 17.3333 15.6872 17.2192 15.4841 17.0161L13 14.532L10.5159 17.0161C10.4155 17.117 10.2962 17.1971 10.1647 17.2518C10.0333 17.3065 9.89234 17.3346 9.74998 17.3346C9.60762 17.3346 9.46667 17.3065 9.33522 17.2518C9.20378 17.1971 9.08444 17.117 8.98406 17.0161C8.88337 16.9156 8.80349 16.7962 8.74899 16.6647C8.69448 16.5333 8.66643 16.3924 8.66643 16.2502C8.66643 16.1079 8.69448 15.967 8.74899 15.8356C8.80349 15.7042 8.88337 15.5848 8.98406 15.4842L11.4681 13.0002L8.98406 10.5161C8.78093 10.3129 8.66681 10.0374 8.66681 9.75016C8.66681 9.46289 8.78093 9.18738 8.98406 8.98425C9.1872 8.78111 9.46271 8.66699 9.74998 8.66699C10.0373 8.66699 10.3128 8.78111 10.5159 8.98425L13 11.4683L15.4841 8.98425C15.6872 8.78111 15.9627 8.66699 16.25 8.66699C16.5373 8.66699 16.8128 8.78111 17.0159 8.98425C17.219 9.18738 17.3331 9.46289 17.3331 9.75016C17.3331 10.0374 17.219 10.3129 17.0159 10.5161L14.5318 13.0002L17.0159 15.4842Z"
                            fill="#7210FF" />
                    </svg>
                </div>
            </div>
            <!-- end ticket box  -->
            <div class="d-flex justify-content-center align-items-center py-5">
                <button @click="handlecomfirm()" class="my_btn btn_primary ripple only-font">Confirm </button>
            </div>
        </div>
        <!-- end tickets  -->

    </div>
</template>
<script>
import Swal from 'sweetalert2'
import { getThaiD, deleteThaiD, ThaiDcomfirm, ThaiDSectionList } from '../services/Auth-Services'
export default {
    data() {
        return {
            step: 1,
            OTPSeconds: 60,
            OTPLabel: "Get OTP",
            previewImage: null,
            user: this.$store.state.auth.user,
            ThaidList: null,
            ThaidSection: null,
            section: null,
        };
    },
    created() {
        this.handlelist();
        this.handlegetListThaidSection();
        this.thaidSection();
    },
    methods: {
         thaidSection() {
            ThaiDSectionList().then((response) => {
                console.log(response.data);
                this.section = response.data.id;
            }).then((error) => {
                console.log(error)
            })
        },
        handleback() {
            history.back();
        },
        next() {
            this.$router.push({
                name: 'new-pin',
                params: {
                    phone: this.phone,
                    name: this.name
                }
            })
        },
        handlegetListThaidSection() {
            ThaiDSectionList().then((response) => {
                this.ThaidSection = response.data.pop();
            }).catch((error) => {
                console.log(error);
            })
        },
        handlelist() {
            getThaiD().then((response) => {
                this.ThaidList = response.data;
            }).catch((error) => {
                console.log(error);
            })
        },
        handledelete(id) {
            deleteThaiD(id).then((response) => {
                console.log(response);
                this.handlelist();
            }).catch((error) => {
                console.log(error);
            })
        },
        handlecomfirm() {
            console.log(this.section);
            this.$store.dispatch('setdata', this.ThaidList);
            this.$store.dispatch('loading')
            ThaiDcomfirm(this.ThaidList, this.section).then((response) => {
                this.$store.dispatch('loadingComplete')
                console.log(response.data);
                if (response.data.message === 402) {
                    Swal.fire({
                        title: 'Error!',
                        text: 'This number is not exist!',
                        icon: 'error',
                        confirmButtonText: 'Okey'
                    })
                }
                if (response.data.message === 404) {
                    Swal.fire({
                        title: 'Error!',
                        text: 'This number is already taken!',
                        icon: 'error',
                        confirmButtonText: 'Okey'
                    })
                }
                this.$router.push({
                    name: "successfulthaid",
                })
            }).catch((error) => {
                console.log(error);
            })
        }
    },
}
</script>

<style scoped>
h6,
p {
    font-family: 'Roboto';
}

.profile-page {
    overflow: scroll;
}

.ticket-container {
    background-image: url('@/assets/img/background.jpg');
    background-position: center;
    background-size: cover;
}

.ticket-number {
    font-size: 10px;
}

.ticket-img-container {
    width: 100%;
    /* height: 300px; */
    overflow: hidden;
}


.my_btn {
    display: inline-block;
    font-weight: bold;
    outline: none;
    border: none;
    box-shadow: none;
    text-decoration: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    transform: translateY(0);
}

.btn_warning {
    color: #fff;
    background-color: #FF9800;
    border-radius: 10px;
    width: 80px;
    height: 45px;
}

.btn_primary {
    color: #fff;
    background-color: #7210FF;
    /* width: 80px; */
    border-radius: 30px;
    /* transition: .3s linear; */
}

.my_btn.ripple {
    background-position: center;
    transition: background 0.8s;
}

.btn_primary.ripple:hover {
    background: #7210FF radial-gradient(circle, transparent 1%, #7210FF 1%) 50%/16000%;
}

.btn_warning.ripple:hover {
    background: #FF9800 radial-gradient(circle, transparent 1%, #FF9800 1%) 50%/16000%;
}

.my_btn.ripple:active {
    background-color: #ffffff80;
    background-size: 100%;
    transition: background 0s, transform 0.2s;
    transform: translateY(1px);
}

.only-font {
    font-family: 'Secular One', sans-serif;
}
</style>
