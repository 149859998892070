import threedService from '@/services/threed-service';
export const threed_section = {
    namespaced: true,
    state() {
        return {
            section: {},
            is_section_empty: false,
            error_status: false,
            loading_status: true
        }
    },
    getters: {
        getThreedSection: state => state.section,
        getIsSectionEmpty: state => state.is_section_empty,
        getErrorStatus: state => state.error_status,
        getLoadingStatus: state => state.loading_status,
    },
    mutations: {
        SET_SECTION: (state, value) => state.section = value,
        SET_ERROR_STATUS: (state, value) => state.error_status = value,
        SET_IS_SECTION_EMPTY: (state, value) => state.is_section_empty = value
    },
    actions: {
        fetchThreedSection: (context) => {
            threedService.getLatestSection().then(({ data }) => {
                context.commit('SET_SECTION', data.data);
            }).catch(error => {
                console.log(error);
                context.commit('SET_ERROR_STATUS', true);
                context.state.loading_status = false;
            }).finally(() => {
                context.state.loading_status = false;
                context.state.section.length <= 0 ? context.commit('SET_IS_SECTION_EMPTY', true) : context.commit('SET_IS_SECTION_EMPTY', false)
            });
        },
    },
};
