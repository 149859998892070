<template>
    <div class="container">
        <div @click="handleback()" class="btns mt-4 ms-2 d-flex">
            <img src="@/assets/img/back.svg" />
            <h6 class="withdraw ms-2 pt-2">Edit Profile</h6>
        </div>
        <div class="row justify-content-center mt-3">
            <div class="col-3">
                <img class="w-100 rounded-circle" src="https://i.scdn.co/image/ab6761610000e5eb006ff3c0136a71bfb9928d34" />
            </div>
        </div>

        <div class="mb-3 mt-5">
            <h6 class=" change-password mb-1">Name</h6>
            <div class="d-flex gap-2 align-items-center px-2 py-2"
                style="border-radius: 10px;border: 1px solid #5856D6;background-color: #ECECF2;height:50px">
                <svg width="30" height="30" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M22.5 39.375C31.8197 39.375 39.375 31.8197 39.375 22.5C39.375 13.1803 31.8197 5.625 22.5 5.625C13.1803 5.625 5.625 13.1803 5.625 22.5C5.625 31.8197 13.1803 39.375 22.5 39.375ZM22.5 41.25C32.8556 41.25 41.25 32.8556 41.25 22.5C41.25 12.1444 32.8556 3.75 22.5 3.75C12.1444 3.75 3.75 12.1444 3.75 22.5C3.75 32.8556 12.1444 41.25 22.5 41.25Z"
                                fill="#F24E1E" />
                            <path
                                d="M11.25 33.4029C11.25 32.4345 11.9738 31.616 12.9375 31.5092C20.1703 30.7085 24.8625 30.7807 32.0794 31.527C32.4398 31.5648 32.7813 31.7068 33.0623 31.9356C33.3434 32.1643 33.5517 32.47 33.6619 32.8152C33.772 33.1604 33.7793 33.5303 33.6827 33.8795C33.5862 34.2288 33.39 34.5424 33.1181 34.782C24.6009 42.206 19.7334 42.1039 11.85 34.7895C11.4656 34.4332 11.25 33.926 11.25 33.4029Z"
                                fill="#F24E1E" />
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M31.9828 32.4593C24.8231 31.7187 20.1984 31.6493 13.0397 32.4415C12.804 32.469 12.5868 32.5824 12.4296 32.76C12.2723 32.9377 12.1861 33.1671 12.1875 33.4043C12.1875 33.6724 12.2991 33.9265 12.4875 34.1028C16.395 37.7271 19.3556 39.3649 22.2497 39.3753C25.1541 39.3856 28.2741 37.7609 32.5022 34.0765C32.6365 33.9569 32.7332 33.8009 32.7805 33.6275C32.8279 33.454 32.8238 33.2705 32.7687 33.0993C32.7137 32.9281 32.6102 32.7765 32.4706 32.6631C32.3311 32.5497 32.1616 32.4782 31.9828 32.4593ZM12.8344 30.5778C20.1431 29.7687 24.9038 29.8418 32.1769 30.5946C32.719 30.6512 33.2329 30.8647 33.6557 31.2088C34.0784 31.553 34.3916 32.0129 34.5569 32.5323C34.7223 33.0517 34.7326 33.6081 34.5866 34.1333C34.4406 34.6585 34.1447 35.1297 33.735 35.4893C29.4459 39.2281 25.8741 41.2643 22.2441 41.2503C18.6038 41.2371 15.1894 39.1662 11.2134 35.4771C10.9288 35.212 10.7018 34.891 10.5468 34.5342C10.3918 34.1773 10.312 33.7924 10.3125 33.4034C10.3111 32.7044 10.5677 32.0295 11.033 31.5079C11.4984 30.9864 12.1398 30.6558 12.8344 30.5778Z"
                                fill="#F24E1E" />
                            <path
                                d="M30 18.75C30 20.7391 29.2098 22.6468 27.8033 24.0533C26.3968 25.4598 24.4891 26.25 22.5 26.25C20.5109 26.25 18.6032 25.4598 17.1967 24.0533C15.7902 22.6468 15 20.7391 15 18.75C15 16.7609 15.7902 14.8532 17.1967 13.4467C18.6032 12.0402 20.5109 11.25 22.5 11.25C24.4891 11.25 26.3968 12.0402 27.8033 13.4467C29.2098 14.8532 30 16.7609 30 18.75Z"
                                fill="#F24E1E" />
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M22.5 24.375C23.9918 24.375 25.4226 23.7824 26.4775 22.7275C27.5324 21.6726 28.125 20.2418 28.125 18.75C28.125 17.2582 27.5324 15.8274 26.4775 14.7725C25.4226 13.7176 23.9918 13.125 22.5 13.125C21.0082 13.125 19.5774 13.7176 18.5225 14.7725C17.4676 15.8274 16.875 17.2582 16.875 18.75C16.875 20.2418 17.4676 21.6726 18.5225 22.7275C19.5774 23.7824 21.0082 24.375 22.5 24.375ZM22.5 26.25C24.4891 26.25 26.3968 25.4598 27.8033 24.0533C29.2098 22.6468 30 20.7391 30 18.75C30 16.7609 29.2098 14.8532 27.8033 13.4467C26.3968 12.0402 24.4891 11.25 22.5 11.25C20.5109 11.25 18.6032 12.0402 17.1967 13.4467C15.7902 14.8532 15 16.7609 15 18.75C15 20.7391 15.7902 22.6468 17.1967 24.0533C18.6032 25.4598 20.5109 26.25 22.5 26.25Z"
                                fill="#F24E1E" />
                        </svg>


                <input type="text" name="new_password_confirmation" class="form-text-input"  v-model="this.name"
                    style="border: 1px solid transparent">
            </div>
        </div>

        <div class=" mt-4">
            <h6 class=" change-password mb-1">Phone Number (can’t change)</h6>
            <div class="d-flex gap-2 align-items-center px-2 py-2"
                style="border-radius: 10px;border: 1px solid #5856D6;background-color: #ECECF2;height:50px">
                <svg width="30" height="30" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M28.125 18.75V9.375H30V7.5C30 6.46875 30.3675 5.58563 31.1025 4.85063C31.8375 4.11563 32.72 3.74875 33.75 3.75C34.7813 3.75 35.6644 4.1175 36.3994 4.8525C37.1344 5.5875 37.5012 6.47 37.5 7.5V9.375H39.375V18.75H28.125ZM31.875 9.375H35.625V7.5C35.625 6.96875 35.445 6.52313 35.085 6.16313C34.725 5.80313 34.28 5.62375 33.75 5.625C33.2188 5.625 32.7731 5.805 32.4131 6.165C32.0531 6.525 31.8738 6.97 31.875 7.5V9.375ZM37.4063 39.375C33.375 39.375 29.445 38.4844 25.6163 36.7031C21.7875 34.9219 18.3969 32.5469 15.4444 29.5781C12.4919 26.6094 10.1169 23.2106 8.31938 19.3819C6.52188 15.5531 5.62375 11.6238 5.625 7.59375V6.60938C5.625 6.26563 5.65625 5.9375 5.71875 5.625H16.6875L18.4219 15.0469L13.0781 20.4375C14.3906 22.6875 16.0394 24.7969 18.0244 26.7656C20.0094 28.7344 22.1888 30.4375 24.5625 31.875L30 26.4375L39.375 28.3125V39.2813C39.0625 39.3125 38.7344 39.3363 38.3906 39.3525C38.0469 39.3688 37.7188 39.3763 37.4063 39.375Z"
                                    fill="#F24E1E" />
                            </svg>
                <input readonly type="number" name="new_password_confirmation" class="form-text-input" :value="this.userInfo.phone"
                    style="border: 1px solid transparent">
            </div>
        </div>

        <div class="d-flex justify-content-center align-items-center py-5">
            <button class="btn_primary" @click="updateProfile()">Confirm </button>
        </div>

    </div>
</template>
<script>
import userService from '@/services/user.service';
export default {
    name: "EditProfile",
    data() {
        return {
            name:'',
            userInfo: {},
        }
    },
    created() {
        this.getUserInfo();
    },
    methods: {
        handleback() {
            history.back();
        },
        getUserInfo(){
            userService.getUserInfo().then(({ data }) => {
                this.userInfo = data.user;
                this.name = data.user.name;
            });
        },
        updateProfile(){
            userService.updateProfile({
                name: this.name,
            }).then(({data})=>{
                console.log(data);
            });
        }
    }
}
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Secular+One&display=swap');

.line1 {
    width: 89% !important;
    height: 1px;
    display: block;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.609);
}

.labeltext3 {
    font-size: 11px;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
    color: black;
}


.pay-box {
    background: #F4ECFF;
    border-radius: 3px;
}

.loginbtn1 {
    font-family: 'Roboto', sans-serif;
    font-size: 17px;
    font-weight: bold;
    color: white;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 50px;
    padding-right: 50px;
    background-color: #7210FF;
    border: 0;
    display: block;
    margin: auto;
    border-radius: 25px;
}

.congrat1 {
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-style: bold;
    color: #964DFF;
    font-weight: 700;
}

.ready1 {
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    font-style: bold;
    color: #964DFF;
    margin-top: 25px;
    font-weight: 700;
}

.swal2-confirm.my-button-class1 {
    font-family: 'Roboto', sans-serif;
    font-size: 17px;
    font-weight: bold;
    color: white;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #7210FF;
    border: 0;
    width: 160px;
    display: block;
    margin: auto;
    border-radius: 25px;
    margin-bottom: 20px;
    margin-top: 10px;
}
</style>
