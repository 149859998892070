<template>
    <div class="">
        <div class="only-color pt-4 px-3">
            <div class="btns only-color   d-flex justify-content-between">
                <div class="d-flex">
                    <img  @click="handleback()" src="@/assets/img/back.svg" style="width:10%" />
                    <h6 class="withdraw  pt-2 fs-16 mt-1 ms-2">Referral Commsion</h6>
                </div>
                <div @click="handleModel()" class="ref-box">
                    <h6 class="ref-text-1 text-center px-3 pt-2">
                        Enter your Friend
                        Referral Code
                    </h6>
                </div>
            </div>

            <div class="row mt-4 pb-4">
                <div class="col-12">
                    <h6 class="ref-text-3 text-center">
                        Refer your friends, Earn Cash Back
                    </h6>
                </div>
                <div class="col-12 d-flex justify-content-center mt-2">
                    <h6 class="ref-text-3 text-center mt-2">
                        Your Code
                    </h6>
                    <input class="ref-boxing mx-2" />
                    <img class="" src="@/assets/img/mark.svg" />
                </div>
            </div>

        </div>

    </div>
</template>
<script>
import Swal from 'sweetalert2'
export default {
    name: "ReferralComponent",
    methods: {
        handleback() {
            history.back();
        },
        handleModel() {
            Swal.fire({
                icon: 'success',
                html: `
            <div class="congrat1">
              Enter your friend code
            </div>
             <div class="ready1" style="color:#7B7680">
              Your frined will get cash back 
                earning commssion.
                You can also get cash back by referring your friend.
            </div>
            <input type="number" class="model-input-box" placeholder="-------"/>
        `,
                width: "89%",
                customClass: {
                    icon: 'my-icon-class',
                    popup: 'my-popup-class',
                    confirmButton: 'my-button-class1',
                },
                confirmButtonText: 'Okay',
            }).then(() => {
                // Logic to be executed when the OK button is clicked
                this.$router.push({
                    name:"Referralcommsion"
                })
                // Add your additional code here
            });
        }
    }
}
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Secular+One&display=swap');

.line1 {
    width: 89% !important;
    height: 1px;
    display: block;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.609);
}

.ref-text-1 {
    font-family: 'Secular One', sans-serif;
    font-size: 11px;
    font-weight: 400;
    color: white;
}

.ref-box {
    z-index: 10;
    width: 37%;
    height: 50%;
    border-radius: 25px;
    background: linear-gradient(90deg, #FF7C8A 0%, #FE8D4D 100%);
}

.ref-text-3 {
    color: #7210FF;
    font-family: 'Secular One', sans-serif;
    font-size: 15px;
    font-weight: 400;
    z-index: 10;
}

.ref-boxing {
    width: 30%;
    border: 2px solid #7210FF;
    border-radius: 25px;
}

.only-color {
    background: #F4ECFF !important;
}

.my-button-class1 {
    font-family: 'Secular One', sans-serif !important;
}

.model-input-box {
    display: block;
    margin: auto;
    width: 70%;
    padding: 12px;
    margin-top: 30px;
    border: 1px solid #7210FF;
    border-radius: 25px;
}

.model-input-box::placeholder {
    text-align: center;
    font-size: 25px;
    letter-spacing: 10px;
}
</style>
