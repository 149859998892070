<template>
    <button :type="type" class="btn font-poppins btn-light fw-600 rounded-circle fs-14 secondary-btn text-primary w-100">
        <slot></slot>
    </button>
</template>
<script>
export default {
    props: {
        type: {
            type: String,
            default: "button"
        }
    }
}
</script>
<style>
.secondary-btn {
    padding: .6rem 0 !important;
    background-color:#d9d9d990 !important;
}
</style>