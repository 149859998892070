export function makeR() {
    function generatePermutations(str, currentPermutation, permutations) {
        if (str.length === 0) {
            permutations.push(currentPermutation);
        } else {
            for (let i = 0; i < str.length; i++) {
                const remainingCharacters = str.slice(0, i) + str.slice(i + 1);
                const newPermutation = currentPermutation + str[i];
                generatePermutations(remainingCharacters, newPermutation, permutations);
            }
        }
    }
    function generateRNumber(str) {
        const permutations = [];
        generatePermutations(str, '', permutations);
        return permutations;
    }
    return {
        generateRNumber
    };
}
