import axios from 'axios';
import authHeader from "./auth-header";
const API_URL = "https://admin.kmlottery.com/api/";
// const API_URL = "http://127.0.0.1:8000/api/";

class UserService {
    getUserInfo(){
        return axios.get(API_URL+"user",{headers: authHeader()});
    }

    updateProfile(data){
        return axios.get(API_URL+"user/update-profile",data,{headers: authHeader()});
    }

    updatePassword(data){
        return axios.get(API_URL+"user/update-password",data,{headers: authHeader()});
    }

    get2Dnumber(id) {
        return axios.get(API_URL + "twod_section/" + id);
    }

    getSection(id){
        return axios.get(API_URL + "twod/sections/"+id,{headers: authHeader()});
    }

    getSectionList(){
        return axios.get(API_URL+"twod/sections",{ headers: authHeader() });
    }

    getSectionNumbers(section_id){
        return axios.get(API_URL+`twod/sections/${section_id}/numbers`,{headers: authHeader()});
    }
    
    twoDbet(id,data){
        return axios.post(API_URL+`twod/sections/${id}/bet`,data,{ headers: authHeader() });
    }

    getTwodSectionInfo(section_id){
        return axios.get(API_URL+`twod/sections/${section_id}`,{headers: authHeader()});
    }


    getTwodBetlogs(){
        return axios.get(API_URL+'twod/betlogs/',{headers: authHeader()});
    }

    getTwodBetlogsDetail(id){
        return axios.get(API_URL+`twod/betlogs/${id}`,{headers: authHeader()});
    }

    getSlides(){
        return axios.get(API_URL+'slides/list',{headers: authHeader()});
    }

    getTwodCalendar(monthName,year){
        return axios.get(API_URL+`twod/calendar/${monthName}/${year}`,{headers: authHeader()});
    }
}

export default new UserService();