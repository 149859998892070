<template>
    <div class="home">
        <div class="home-banner position-relative  p-4">
            <div class="d-flex justify-content-between h-25">
                <div>
                    <h6 class="header-style">1 Baht = 70 MMK</h6>
                </div>
                <div>
                    <img class="banner-title" src="@/assets/img/logo.png" alt="" />
                </div>
                <router-link :to="{ name: 'notifications' }">
                    <font-awesome-icon class="text-white" icon="fa-solid fa-bell" size="lg"></font-awesome-icon>
                </router-link>
            </div>

            <div v-if="logined" class="mt-2 mb-0 d-flex">
                <div>
                    <img src="@/assets/img/profile.png" class="rounded-circle" />
                </div>
                <div class="ms-2 mt-1">
                    <h6 class="text-white fs-14 fw-bold">{{ this.userInfo.name }}</h6>
                    <h6 class="text-white fs-12">{{ this.userInfo.phone }}</h6>
                </div>
            </div>

            <div v-else class="mt-2 mb-0">
                <div>
                    <h1 class="welcome">Welcome!</h1>
                </div>
                <div class="d-flex justify-content-between">
                    <div>
                        <button @click="handlenextlogin()" class="login-btn">
                            Create account or Login!
                        </button>
                    </div>
                    <div>
                        <img class="mt-1" src="@/assets/img/Vector.svg" alt="">
                    </div>
                </div>
            </div>



            <div class="px-1 mt-3   ">
                <div class="user-actions shadow-lg ">
                    <div>
                        <div class="d-flex justify-content-between">
                            <h4 class="header-bar p-3 pb-0">My Balance</h4>
                            <h4 v-if="logined" class="fw-bold fs-16 pt-3 me-3 text-black">{{ balance }} KS</h4>
                        </div>
                        <div class="line"></div>
                    </div>
                    <div class="row">
                        <div class="d-flex justify-content-around mt-2 pb-3 ">
                            <router-link class="text-decoration-none text-center " :to="{ name: 'deposit-view' }">
                                <SecondaryButton :disabled="false" class="p-5 mb-1 action">
                                    <img src="@/assets/img/Vector1.svg" />
                                </SecondaryButton>
                                <p class="mb-0  btns ">Deposit</p>
                            </router-link>
                            <router-link class="text-decoration-none text-center" :to="{ name: 'withdrawlist' }">
                                <SecondaryButton class="px-3 mb-1 action">
                                    <img src="@/assets/img/Vector2.svg" />
                                </SecondaryButton>
                                <p class="mb-0   btns">Withdraw</p>
                            </router-link>
                            <router-link class="text-decoration-none text-center" :to="{ name: 'transaction-view' }">
                                <SecondaryButton class=" mb-1 action">
                                    <img src="@/assets/img/Vector3.svg" />
                                </SecondaryButton>
                                <p class="mb-0 btns">Transaction</p>
                            </router-link>
                            <router-link class="text-decoration-none text-center" :to="{ name: 'support' }">
                                <SecondaryButton class="px-3 mb-1 action">
                                    <img src="@/assets/img/Vector4.svg" />
                                </SecondaryButton>
                                <p class="mb-0  btns">Support</p>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
            <div class="ad-box mt-2 ">
                <div class="mt-auto ad-description-box">
                    <marquee class="ad-description" behavior="" direction="">
                        <img src="@/assets/img/alret.svg" class="me-2 " style="width:15px;height:15px;object-fit:contain" />
                        {{ homeBannerText }}
                    </marquee>
                </div>
            </div>
            <div class="container mt-2 mb-0">
                <div class="row">
                    <div class="col-6">
                        <img src="@/assets/img/twodbet.png" class="w-100" @click="handlemodel()"/>
<!-- 
                        <router-link :to="{ name: 'twod' }">
                        </router-link> -->
                    </div>

                    <div class="col-6">
                        <router-link to="/threed">
                            <img src="@/assets/img/threedbet.png" style="width:108%" class="mt-1" />
                        </router-link>
                    </div>

                </div>
                <div class="row justify-content-center mt-1">
                    <div class="col-11">
                        <router-link :to="{ name: 'thaidview' }">
                            <img src="@/assets/img/thaidee.png" class="w-100" />
                        </router-link>
                    </div>
                </div>
            </div>

            <div class="mt-4 pb-5">
                <div class="ad-box  d-flex flex-column">
                    <div style="position: relative;">
                        <carousel :items-to-show="1" style="overflow: hidden;">
                            <slide v-for="slide in slides" :key="slide">
                                <img class="rounded-4" :src="slide.image" alt="">
                            </slide>

                            <template #addons>
                                <navigation />
                                <div style="position: absolute;bottom:0;right:0;left:0;margin:auto">
                                    <pagination />
                                </div>
                            </template>
                        </carousel>
                    </div>
                </div>
            </div>

        </div>

        <div v-if="showModal" class="model-2d-box-container">
            <div class="model-2d-box">
                <div class="d-flex justify-content-center">
                    <h6 class="text-center choose mt-4">ထိုးမည့် section ရွေးပါ</h6>
                    <div @click="handlemodel()" style="position:absolute;right:10px;top:17px">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-x-circle"
                            style="color:#7210FF" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path
                                d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                        </svg>
                    </div>
                </div>
                <div class="h-100 pb-3" style="overflow: hidden;">
                    <div class="row mt-3">
                        <div class="d-flex gap-3 flex-wrap">
                            <div v-for="(value,index) in this.twdSections" :key="index" class="w-100"   @click="selectSection(value.id)">
                                <div class="text-center bg-white rounded px-2 py-2" v-if="!value.ended"  style="font-size: 12px;" >{{ value.opening_time }} Section</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import userService from '@/services/user.service';
import { getBalance } from '@/services/Auth-Services';


export default {
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation,
    },
    data() {
        return {
            showModal: false,
            logined: '',
            userInfo: {},
            slides: [],
            balance: null,
            twdSections: [],
            selectedSection: {},
        }
    },
    methods: {
        handlemodel() {
            this.showModal = !this.showModal
        },
        handlenextlogin() {
            this.$router.push({
                name: "login"
            })
        },

        getUserInfo() {
            userService.getUserInfo().then(({ data }) => {
                console.log(data)
                this.userInfo = data.user;
            })
        },

        getSlidesList() {
            userService.getSlides().then(({ data }) => {
                this.slides = data.data
            })
        },
        getBalanceAmount() {
            getBalance().then((response) => {
                this.balance = response.data[0].balance;
                console.log(response)
            }).catch((error) => {
                console.log(error);
            })
        },
        getTwodSectionList(){
            userService.getSectionList().then(({ data }) => {
                this.twdSections = data.data;
            })
        },
        selectSection(id){
            this.selectedSection = id;
            this.$router.push({
                name: "twod",
                params: {id: this.selectedSection}
            })
        }

    },
    computed: {
        homeBannerText() {
            return this.$store.state.index.homeBannerText
        },
        bannerImages() {
            return this.$store.state.index.homeBannerImages;
        }
    },
    mounted() {
    },
    created() {
        if (localStorage.getItem('token')) {
            const token = localStorage.getItem('token');
            this.logined = token;
            console.log(token);
            this.getUserInfo();
            this.getSlidesList();
            this.getBalanceAmount();
            this.getTwodSectionList();
        }
    }
}
</script>
<style scoped>
.home-banner {
    height: 27vh;
    width: 100%;
    background: url('../assets/img/header background.png');
    background-position: center;
    object-fit: cover;
    background-size: 100%;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
}

.banner-title {
    position: relative;
    bottom: 30px;
    right: 22px;
}

.welcome-box {
    margin-top: -30px;
    background-color: #fff;
    box-shadow: 0px 4px 24px 0px #6D7FA333;
    border-radius: 14px;
    text-align: center;
}

.welcome-box .user-info {
    border-top-right-radius: 14px;
    border-top-left-radius: 14px;
}

.ad-box {
    border-radius: 14px;

}

.ad-box img {
    /* height: 200px; */
    width: 100%;
    aspect-ratio: 16/9;
    object-fit: cover;
}

.ad-box .ad-description {
    font-size: 12px;
    font-weight: 400;
    color: #0029FF;

}

.ad-box .ad-description-box {
    background-color: white;
    width: 96%;
    display: block;
    margin: auto;
    padding-top: 5px;
    border-radius: 3px;
}

.user-balance-deposit-label {
    font-weight: 400;
    font-size: 12px;
}

.user-name {
    font-weight: 600;
    font-size: 14px;
}

.user-phone {
    font-weight: 400;
    font-size: 12px;
}

.user-balance {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 0;
}

.user-info-edit {
    background: #00000033;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.welcome-box .welcome-title {
    font-weight: 600;
    font-size: 18px;
}

.games-box .title {
    font-weight: 700;
    font-size: 16px;
}

/* .user-actions .action {
    width: 13vw !important;
    height: 12vw !important;
} */

.user-actions {
    background-color: white;
    border-radius: 10px;
}

.home {
    overflow: scroll;
    /* padding-bottom: 10vh !important; */
}

.carousel {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

.header-style {
    color: white;
    font-weight: 300;
    font-size: 13px;
    letter-spacing: -0.01em;
    font-style: normal;
    font-family: 'Roboto';
    line-height: 15px;
}

.welcome {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    color: white;
    letter-spacing: -0.01em;
}

.login-btn {
    background-color: #F8CF1D;
    width: 162px;
    height: 39px;
    border-radius: 5px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: -0.01em;
    border: 0;
}

.header-bar {
    font-family: 'Roboto';
    font-style: light;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: -1%;
    font-weight: 300;
}

.line {
    width: 90%;
    display: block;
    margin: auto;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.176);
    margin-top: 8px;
}

.btns {
    font-family: 'Roboto';
    color: black;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: -1%;
    margin-top: 2px;
    font-weight: 300;
    font-style: normal;
}


.model-2d-box-container {
    position: absolute;
    width: 100% !important;
    top: 0;
    /* left: 50%; */
    /* transform: translate(-50%,-50%); */
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F4ECFF80;
    /* margin: 15px; */
    /* box-shadow: 1px 1px 13px #888; */
}

.model-2d-box {
    position: relative;
    width: 100%;
    max-height: 300px;
    max-width: 300px;
    background: #F4ECFF;
    border-radius: 10px 10px 10px 10px;
    padding: 10px;
    box-shadow: 1px 1px 13px #888;
}

</style>

