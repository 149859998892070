<template>
    <div class="container">
        <div @click="handleback()" class="btns mt-4 ms-2 d-flex">
            <img src="@/assets/img/back.svg" />
            <h6 class="withdraw ms-2 pt-2">Deposit</h6>
        </div>

        <div class="row">
            <div class="col">
                <div class="line1"></div>
            </div>
        </div>

        <div class="row justify-content-center ms-1 mt-3">
            <div class="col-4 action-one">
                <h6 class="mt-1">Balance</h6>
            </div>
            <div class="col-5 action-two">
                <h6 class="mt-2 ps-4">315,042 Ks</h6>
            </div>
        </div>

        <div class="row justify-content-center mt-4">
            <div class="col-3">
                <img src="@/assets/img/pay4.svg" class="w-75 d-block mx-auto" />
            </div>
        </div>

        <div class="row justify-content-center mt-4">
            <div class="col-7">
                <div class="box-exchange ">
                    <h6 class="exchange">Exchange rate 1 Baht = 86 Ks</h6>
                </div>
            </div>
        </div>

        <div class="row justify-content-center">
            <div class="col-12">
                <div class="p-4 ">
                    <div class="p-4 pt-3 pay-box">
                        <label class="labeltext" for="">Amount</label>
                        <input type="number" class="form-control mt-1 inputbox" />

                        <label class="labeltext mt-3" for="">Bangkok Bank account</label>
                        <input type="number" class="form-control mt-1 inputbox" />

                        <label class="labeltext mt-3" for="">Account Owner Name</label>
                        <input type="number" class="form-control mt-1 inputbox mb-2" />
                    </div>
                </div>
                <div>
                    <button @click="handleComfirm(e)" class="loginbtn1 mt-2">
                        Comfirm
                    </button>
                </div>
            </div>
        </div>

        <div class="row mt-4 pt-2">
            <div class="col mt-3">
                <h6 class="ft ms-3 ">if you have any problem, don't hesitate contact use</h6>
            </div>
            <div class="pt-1">
                <div class="d-flex justify-content-between box mt-2">
                    <div class="d-flex">
                        <img src="@/assets/img/viber.svg" />
                        <h6 class="ph ms-2 mt-2">+95998745612</h6>
                    </div>
                    <div>
                        <h6 class="condition mt-2">24/7 available</h6>
                    </div>
                </div>
            </div>
            <div class="pt-1 mb-5">
                <div class="d-flex justify-content-between box mt-3">
                    <div class="d-flex">
                        <img src="@/assets/img/tele.svg" />
                        <h6 class="ph ms-2 mt-2">+95998745612</h6>
                    </div>
                    <div>
                        <h6 class="condition mt-2">24/7 available</h6>
                    </div>
                </div>
            </div>
        </div>



    </div>
</template>
<script>
import Swal from 'sweetalert2'
export default {
    methods: {
        handleback() {
            history.back();
        },
        handleComfirm() {
            Swal.fire({
                icon: 'success',
                html: `
            <div class="congrat1">
               Request Successful!
            </div>
             <div class="ready1">
               Your payment request is pending. We will check
              your payment and confirm you ASAP.
            </div>
        `,
                width: "89%",
                customClass: {
                    icon: 'my-icon-class',
                    popup: 'my-popup-class',
                    confirmButton: 'my-button-class1',
                },
                confirmButtonText: 'Okay',
            });
        }
    }
}
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Secular+One&display=swap');

.line1 {
    width: 89% !important;
    height: 1px;
    display: block;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.609);
}

.labeltext {
    font-size: 13px;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
}


.pay-box {
    background: #F4ECFF;
    border-radius: 3px;
}

.loginbtn1 {
    font-family: 'Roboto', sans-serif;
    font-size: 17px;
    font-weight: bold;
    color: white;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 50px;
    padding-right: 50px;
    background-color: #7210FF;
    border: 0;
    display: block;
    margin: auto;
    border-radius: 25px;
}

.congrat1 {
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-style: bold;
    color: #964DFF;
    font-weight: 700;
}

.ready1 {
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    font-style: bold;
    color: #964DFF;
    margin-top: 25px;
    font-weight: 700;
}

.swal2-confirm.my-button-class1 {
    font-family: 'Roboto', sans-serif;
    font-size: 17px;
    font-weight: bold;
    color: white;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #7210FF;
    border: 0;
    width: 160px;
    display: block;
    margin: auto;
    border-radius: 25px;
    margin-bottom: 20px;
    margin-top: 10px;
}

.exchange {
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    font-weight: 300;
    color: #EA2839;
    text-align: center;
    padding-top: 7px;
}
.box-exchange{
    border: 1px solid #EA2839;
}
</style>
