
export default function Auth(router) {
    router.beforeEach((to, from, next) => {
        const token = localStorage.getItem('token');

        if (!token && to.name !== 'login' && to.name !== 'register' && to.name !== 'home') {
            next({ name: 'home' });
        } else {
            next();
        }
    });
}
