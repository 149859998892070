import { createStore } from "vuex";
import { auth } from "./auth.module";
import { index } from "./index.module";
import { cash } from "./cash.module";
import { Data } from "./data.module";
import { threed_section } from "./threed/threed_section";
import { threed_bet_log } from "./threed/threed_bet_log";



const store = createStore({
  modules: {
    auth,
    index,
    cash,
    Data,
    threed_section,
    threed_bet_log
  },
  
});

export default store;

