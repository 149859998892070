
import { useQuery } from 'vue-query';
import threedService from "@/services/threed-service";


const fetchBalance = async () => {
  const response = await threedService.fetchBalance();
  return response.data;
};


export function fetchBalanceQuery() {
  return useQuery('balance', fetchBalance);
}

