<template>
    <div class="edit-profile-page">
        <div class="py-3 px-3 d-flex justify-content-between align-items-center">
            <div class="d-flex gap-2 align-items-center">
                <div @click="handleback()" class="">
                    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M11.7812 20.9084L4.63125 13.7584C4.52291 13.65 4.446 13.5327 4.4005 13.4063C4.355 13.2799 4.33261 13.1445 4.33333 13C4.33333 12.8556 4.35608 12.7202 4.40158 12.5938C4.44708 12.4674 4.52364 12.35 4.63125 12.2417L11.7812 5.09169C11.9799 4.89308 12.2283 4.78944 12.5266 4.78078C12.8249 4.77211 13.082 4.87575 13.2979 5.09169C13.5146 5.2903 13.6276 5.53875 13.637 5.83702C13.6464 6.1353 13.5424 6.39241 13.325 6.60836L8.01666 11.9167H20.1229C20.4299 11.9167 20.6873 12.0207 20.8953 12.2287C21.1033 12.4367 21.207 12.6938 21.2062 13C21.2062 13.307 21.1026 13.5644 20.8953 13.7724C20.6881 13.9804 20.4306 14.0841 20.1229 14.0834H8.01666L13.325 19.3917C13.5236 19.5903 13.6276 19.8431 13.637 20.15C13.6464 20.457 13.5424 20.7097 13.325 20.9084C13.1264 21.125 12.8736 21.2334 12.5667 21.2334C12.2597 21.2334 11.9979 21.125 11.7812 20.9084Z"
                            fill="black" />
                    </svg>
                </div>
                <h6 class="fs-18 fw-normal mb-0 only-font">Thailand Lottery</h6>
            </div>
            <div>
                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M13 4.33341C17.7786 4.33341 21.6667 8.2215 21.6667 13.0001C21.6667 17.7787 17.7786 21.6667 13 21.6667C8.22144 21.6667 4.33335 17.7787 4.33335 13.0001C4.33335 8.2215 8.22144 4.33341 13 4.33341ZM13 2.16675C7.01677 2.16675 2.16669 7.01683 2.16669 13.0001C2.16669 18.9833 7.01677 23.8334 13 23.8334C18.9833 23.8334 23.8334 18.9833 23.8334 13.0001C23.8334 7.01683 18.9833 2.16675 13 2.16675ZM14.0834 16.2501H11.9167V18.4167H14.0834V16.2501ZM11.9167 14.0834H14.0834L14.625 7.58342H11.375L11.9167 14.0834Z"
                        fill="black" />
                </svg>
            </div>
        </div>
        <div class="my-3 mx-3 overflow-hidden" style="background-color: #F4ECFF;border-radius: 12px;">
            <div class="py-2 px-2 d-flex align-items-center justify-content-center"
                style="width: 40%;background-color: #7210FF;color: #fff;border-radius: 5px;">
                <h6 class="fs-14 mb-0 only-font">{{ opening_date }}</h6>
            </div>
            <div class="d-flex gap-1 align-items-center py-3">
                <div class="d-flex flex-column align-items-center px-2" style="width:25%;border-right: 1px solid #000;">
                    <h6 class="fw-bold  mb-1 fs-16 only-font">{{ daysRemaining }}</h6>
                    <h6 class="fs-14 mb-1 only-font">Day</h6>
                </div>
                <div class="d-flex flex-column align-items-center px-2" style="width:25%;border-right: 1px solid #000;">
                    <h6 class="fw-bold  mb-1 fs-16 only-font">{{ hoursRemaining }}</h6>
                    <h6 class="fs-14 mb-1 only-font">Hours</h6>
                </div>
                <div class="d-flex flex-column align-items-center px-2" style="width:25%;border-right: 1px solid #000;">
                    <h6 class="fw-bold  mb-1 fs-16 only-font">{{ minutesRemaining }}</h6>
                    <h6 class="fs-14 mb-1 only-font">Minutes</h6>
                </div>
                <div class="d-flex flex-column align-items-center px-2" style="width:25%">
                    <h6 class="fw-bold  mb-1 fs-16 only-font">{{ secondsRemaining }}</h6>
                    <h6 class="fs-14 mb-1 only-font">Seconds</h6>
                </div>
            </div>
        </div>

        <div class="my-4 mx-3">
            <!-- start tickets  -->
            <div v-for="(item, index) in ThaidList" :key="index" class="d-flex pt-2 align-items-start"
                :style="index === 0 ? 'margin-top: -0px' : 'margin-top: -120px'">
                <div class="ticket-img-container position-relative">
                    <img src="@/assets/lottery.jpeg" class="w-100 h-100" />
                    <div class="number-box ms-2">
                        <h6 v-for="(digit, digitIndex) in item.bet_number.split('')" :key="digitIndex" class="p-left">{{
                            digit }}</h6>
                    </div>
                </div>
                <button @click="handledelete(item.id)" class="btn btn-sm">
                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M8.16669 24.5C7.52502 24.5 6.97552 24.2713 6.51819 23.814C6.06085 23.3567 5.83258 22.8076 5.83335 22.1667V7H4.66669V4.66667H10.5V3.5H17.5V4.66667H23.3334V7H22.1667V22.1667C22.1667 22.8083 21.938 23.3578 21.4807 23.8152C21.0234 24.2725 20.4742 24.5008 19.8334 24.5H8.16669ZM19.8334 7H8.16669V22.1667H19.8334V7ZM10.5 19.8333H12.8334V9.33333H10.5V19.8333ZM15.1667 19.8333H17.5V9.33333H15.1667V19.8333Z"
                            fill="#7210FF" />
                    </svg>
                </button>
            </div>
        </div>

        <div class="bg-white px-3 py-3 overflow-hidden bet-box-container"
            style="border: 2px solid #7210FF;border-radius: 20px 20px 0px 0px;">
            <div class="d-flex gap-1 align-items-center">
                <h6 class="fs-16 text-nowrap fw-500 mb-0 only-font " style="color:#7210FF">Fill number :</h6>
                <div class="d-flex gap-1">
                    <input v-for="(value, index) in fill_numbers" :key="index" :ref="`input-${index}`" type="number"
                        :id="`input-${index}`" :name="`fill_number[${index}]`" class="form-text-input"
                        @input="handleInput(index, $event)" v-model="fill_numbers[index]" :maxlength="1" required
                        style="border: 1px solid #7210FF">
                </div>
            </div>

            <div class="d-flex justify-content-between align-items-center gap-2 my-3">
                <h6 class="fs-14 fw-500 mb-0 only-font">Price (1 ticket ={{ price }} Ks)</h6>
                <div>
                    <button @click="handlefill()" class="my_btn btn_primary ripple only-font fw-500">Fill</button>
                </div>
            </div>

            <div class="d-flex justify-content-between align-items-center gap-2 my-3 mb-5">
                <div class="d-flex gap-1 align-items-center" style="width: 35%;">
                    <h6 class="mb-0 fs-16 text-nowrap fw-500 only-font" style="color:#7210FF">Tickets :</h6>
                    <input readonly type="text" class="form-text-input" style="border: 1px solid #7210FF"
                        :placeholder="ThaidList ? ThaidList.length : 0">
                </div>
                <div class="d-flex gap-1 align-items-center" style="width:50%">
                    <h6 class="mb-0 fs-16 text-nowrap fw-500 only-font ">Total :</h6>
                    <input type="number" class="form-text-input" :value="ThaidList.length * price"
                        style="border: 1px solid #7210FF">
                </div>
                <div class="d-flex align-items-center justify-content-end" style="width: 25%;">
                    <button @click="handlenextpage()" class="my_btn btn_warning ripple only-font fw-500"
                        style="color:#7210FF">Bet</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import { fillThaiD, getThaiD, deleteThaiD, ThaiDSectionList } from '../services/Auth-Services'
import Swal from 'sweetalert2'
import userService from '@/services/user.service';
export default {
    data() {
        return {

            price: null,
            user_id: 1,
            ThaidList: null,
            TotalAmount: null,
            opening_date: null,
            daysRemaining: 0,
            hoursRemaining: 0,
            minutesRemaining: 0,
            secondsRemaining: 0,
            readOnly: false,
            fill_numbers: ['', '', '', '', '', ''],
            userBalance : null,
        };
    },
    created() {
        this.handlelist();
        this.thaidSection();
        this.getUserInfo();
    },
    methods: {
        getUserInfo() {
            userService.getUserInfo().then(({ data }) => {
                console.log(data.user.balance)
                this.userBalance = data.user.balance;
            })
        },
        handleback() {
            history.back();
        },
        handleInput(index, event) {
            if (event.target.value.length === 1 && index < Object.keys(this.fill_numbers).length - 1) {
                this.fill_numbers[index] = event.target.value.charAt(0);
                this.$nextTick(() => {
                    const nextInputRef = this.$refs[`input-${index + 1}`];
                    if (nextInputRef) {
                        setTimeout(() => {
                            nextInputRef.focus();
                        }, 0); // Introduce a slight delay with setTimeout
                    }
                });
            } else if (event.target.value.length > 1) {
                // Set the value to the first character if it has more than one
                this.fill_numbers[index] = event.target.value.charAt(0);
            }
        },
        countdownToOpening(openingDate) {
            const ONE_SECOND = 1000; // 1 second in milliseconds

            const interval = setInterval(() => {
                const currentDate = new Date();
                const timeDifference = openingDate - currentDate;

                if (timeDifference <= 0) {
                    console.log("The opening date has arrived!");
                    clearInterval(interval);
                } else {
                    const days = Math.floor(timeDifference / (24 * 60 * 60 * 1000));
                    const hours = Math.floor((timeDifference % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));
                    const minutes = Math.floor((timeDifference % (60 * 60 * 1000)) / (60 * 1000));
                    const seconds = Math.floor((timeDifference % (60 * 1000)) / 1000);
                    this.daysRemaining = days,
                        this.hoursRemaining = hours,
                        this.minutesRemaining = minutes,
                        this.secondsRemaining = seconds,
                        console.log(days);
                }
            }, ONE_SECOND);
        },
        thaidSection() {
            ThaiDSectionList().then((response) => {

                let dateformat = response.data.opening_date;
                let date = new Date(dateformat);
                const options = {
                    weekday: 'short',
                    day: '2-digit',
                    month: 'short',
                    year: 'numeric'
                };
                let formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
                this.opening_date = formattedDate;
                this.countdownToOpening(date);
                this.price = response.data.price;
            }).then((error) => {
                console.log(error)
            })
        },
        handlenextpage() {
         
            this.$router.push({
                name: "comfirmthaid"
            });
        }, handlefill() {
               console.log((this.ThaidList.length + 1) * this.price)
            if (this.userBalance < (this.ThaidList.length + 1) * this.price) {
                 Swal.fire({
                    title: 'Error!',
                    text: 'Your Balance is not Enough!',
                    icon: 'error',
                    confirmButtonText: 'Okey'
                })
            } else {
                 const result = Object.values(this.fill_numbers).join('');
                fillThaiD(result, this.price, this.user_id)
                    .then((response) => {
                        console.log(response);
                        if (response.data.message === 402) {
                            Swal.fire({
                                title: 'Error!',
                                text: 'This number is not exist!',
                                icon: 'error',
                                confirmButtonText: 'Okey'
                            })
                        }
                        if (response.data.message === 404) {
                            Swal.fire({
                                title: 'Error!',
                                text: 'This number is already taken!',
                                icon: 'error',
                                confirmButtonText: 'Okey'
                            })
                        }
                        this.handlelist();
                    }).catch((error) => {
                        console.log(error);
                    })
            }
           
        },
        handlelist() {
            getThaiD().then((response) => {
                this.ThaidList = response.data;
            }).catch((error) => {
                console.log(error);
            })
        },
        handledelete(id) {
            deleteThaiD(id).then((response) => {
                console.log(response);
                this.handlelist();
            }).catch((error) => {
                console.log(error);
            })
        }
    },
}
</script>

<style scoped>
.p-left {
    width: 21px;
}

.number-box {
    display: flex;
    position: absolute;
    z-index: 1000;
    top: 7%;
    left: 46%;
}

.ticket-img-container {
    width: 100%;
    z-index: -100;
    /* height: 300px; */
    overflow: hidden;
}

.profile-img-container {
    width: 111px;
    height: 111px;
}

.form-text-input {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    background-color: #ECECF2;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}


.form-text-input:hover,
.form-text-input:focus {
    outline: none;
}

.bet-box-container {
    position: fixed;
    bottom: 0px;
    left: 0;
    right: 0;
}

@media screen and (max-width: 3000px) {
.bet-box-container {
    position: fixed;
    bottom: 0px;
    left: 33.6%;
    right: 50 !important;
    width: 32.5%;
}
}

.my_btn {
    display: inline-block;
    font-weight: bold;
    outline: none;
    border: none;
    box-shadow: none;
    text-decoration: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    transform: translateY(0);
}

.btn_warning {
    color: #fff;
    background-color: #FF9800;
    border-radius: 10px;
    width: 80px;
    height: 45px;
}

.btn_primary {
    color: #fff;
    background-color: #7210FF;
    width: 80px;
    border-radius: 10px;
    /* transition: .3s linear; */
}

.my_btn.ripple {
    background-position: center;
    transition: background 0.8s;
}

.btn_primary.ripple:hover {
    background: #7210FF radial-gradient(circle, transparent 1%, #7210FF 1%) 50%/16000%;
}

.btn_warning.ripple:hover {
    background: #FF9800 radial-gradient(circle, transparent 1%, #FF9800 1%) 50%/16000%;
}

.my_btn.ripple:active {
    background-color: #ffffff80;
    background-size: 100%;
    transition: background 0s, transform 0.2s;
    transform: translateY(1px);
}

.only-font {
    font-family: 'Secular One', sans-serif;
}

.only-margin {
    margin-top: -96px,
}</style>
