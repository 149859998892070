import axios from 'axios';
import authHeader from "./auth-header";
// const API_KEY = "http://127.0.0.1:8000/api";
const API_KEY = "https://admin.kmlottery.com/api";
// Function to make a login request
export const login = (phone, password, securityCode) => {
    const data = {
        phone: phone,
        password: password,
        security_code: securityCode,
    };

    return axios.post(API_KEY + '/login', data, { headers: authHeader() });
};

// Function to make a registration request
export const register = (name, phone, password, referralCode, securityCode) => {
    const data = {
        name: name,
        phone: phone,
        password: password,
        referral_code: referralCode,
        security_code: securityCode,
    };

    return axios.post(API_KEY + '/register', data, { headers: authHeader() });
};

export const fillThaiD = (bet_number,price,user_id) => {
    const data = {
        bet_number: bet_number,
        price: price,
        user_id: user_id,
    };

    return axios.post(API_KEY + '/thaid', data, { headers: authHeader() });
};

export const getThaiD = () => {
    return axios.get(API_KEY + '/thaid', { headers: authHeader() });
};

export const deleteThaiD = (id) => {
    console.log(id);
    return axios.delete(API_KEY + '/thaid/' + id, { headers: authHeader() });
};

export const ThaiDcomfirm = (item, section) => {
    const data = {
        data: item,
        section_id : section
    }
    return axios.post(API_KEY + '/thaidcomfirm', data, { headers: authHeader() });
};

export const ThaiDDetails = (slip_id) => {
    return axios.get(`${API_KEY}/thaidcomfirm/details?slip_id=${slip_id}`, { headers: authHeader() });
}

export const ThaiDSectionList = () => {
    return axios.get(API_KEY + '/thaidsection', { headers: authHeader() });
}

export const ThaidWinningNumberList = () => {
    return axios.get(API_KEY + '/thaidwinningnumberlist', { headers: authHeader() });
}

export const ThaidWinningNumberListwithId = (id) => {
    return axios.get(`${API_KEY}/thaidwinningnumberlist?id=${id}`, { headers: authHeader() });
}

export const CheckWinningNumber = (id,number) => {
    return axios.get(`${API_KEY}/checkwinningnumber?id=${id}&number=${number}`, { headers: authHeader() });
}

export const getPaymentType = () => {
    return axios.get(API_KEY + '/customer/deposit/getlist' , { headers: authHeader() });
}

export const storeDeposit = (data) => {
    return axios.post(API_KEY + '/customer/deposit',data, { headers: authHeader() });
}

export const storeWithdraw = (data) => {
    return axios.post(API_KEY + '/customer/withdraw', data, { headers: authHeader() });
}

export const getBalance = () => {
    return axios.get(API_KEY + '/customer/balanceAmount', { headers: authHeader() });
}

export const getYear = () => {
    return axios.get(API_KEY + '/sectionthai', { headers: authHeader() });
}

export const sectionFilter = (data) => {
    return axios.post(API_KEY + '/sectionthai/filter',data, { headers: authHeader() });
}

export const monthFilter = (data) => {
    return axios.post(API_KEY + '/thidmonth', data, { headers: authHeader() });
}

export const monthFilterResult = (data) => {
    return axios.post(API_KEY + '/thaidmonth/filter', data, { headers: authHeader() });
}

export const ThaiDcomfirmList = () => {
    return axios.get(API_KEY + '/thaidcomfirm', { headers: authHeader() });
};
export const getHistoryThaiD = () => {
    return axios.get(API_KEY + '/thaidHistory/list', { headers: authHeader() });
}

export const authenticate = (requestConfig) => {
    // Add authentication logic here, such as adding an authorization header
    const token = localStorage.getItem('token');
    if (token) {
        requestConfig.headers.Authorization = `Bearer ${token}`;
    }

    return requestConfig;
};
